import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Grid, Typography, FormHelperText } from '@mui/material';
import adminApiService from '../../core/service/AdminApiService';
import '../Admin/Registration/addstyles.scss';
import '../Admin/Common/common.scss';
import '../Admin/Homescreen/dashboard.scss'
import moment from 'moment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HeaderWithLogout from './Common/Headerdesktop';
import SidebarToggle from './Common/SidebarToggle';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';

let memberData: any;
const MemberDetails: React.FC = () => {
    const location = useLocation()
    const [memberData, setMemberData] = useState<any | null>(null);

    //if (location && location.state) {
        // const rowData = JSON.parse(new URLSearchParams(location.search).get('data') || "{}");
        // memberData=rowData;
      
    //}
    const handlePrint = () => {
        window.print();
    };
    useEffect(() => {
        // Retrieve data from session storage
        const rowDataString = sessionStorage.getItem('rowData');
        
        if (rowDataString) {
          const rowData = JSON.parse(rowDataString);
          // Use the rowData as needed in your component
          console.log('Row Data:', rowData);
          setMemberData(rowData);
          // Optional: Clear session storage if needed
          sessionStorage.removeItem('rowData');
        }
      }, []);
    return (
        <Grid className="dashboardcontainer">
            <Grid container justifyContent="center">
                <Grid item xs={1} className='sidebar non-print-section' >
                    <SidebarToggle />
                </Grid>
                <Grid item xs={11} className='content-container'>
                    <Grid className='conferenceSummaryContainer non-print-section'>
                        <HeaderWithLogout title="Dashboard/Member Details" icon={<DashboardIcon className='dashboard-icon' />} />
                    </Grid>
                    {memberData&&
                    <Grid >
                        <Grid>
                        <Button onClick={handlePrint} className='print_btn non-print-section'>Print</Button>
                        </Grid>
                        <br/>
                        {/* <Button onClick={()=>handleDownload("downloadsection")} className='non-print-section'>Print</Button> */}
                        <Grid className='a4-container print-section' id="downloadsection">
                            <Grid textAlign={'center'}>
                                <Typography className='mainheadingstyle'> KATCON 2024</Typography>
                                <Typography className='subheadingstyle'> Indian Society of Anaesthesiologists</Typography>
                                <Typography className='subheadingstyle'> Thrissur City Branch</Typography>
                            </Grid>
                            <Grid style={{ textAlign: 'left' }}>

                                <Typography>RegNo :{`KATCON${memberData.id}`}</Typography><br />
                                <Typography className='headingstyle'>A.Personal Information</Typography>
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>Name</Grid>
                                    {memberData.firstname && memberData.lastname &&
                                        <Grid item xs={7}>{`${memberData.firstname} ${memberData.lastname}`}</Grid>}  
                                    {memberData.firstname && memberData.middlename &&
                                        <Grid item xs={7}>{`${memberData.firstname} ${memberData.middlename}`}</Grid>}  
                                    {memberData.firstname && !memberData.middlename && (!memberData.lastname || memberData.lastname!='') &&
                                        <Grid item xs={7}>{`${memberData.firstname}`}</Grid>}  
                                    {memberData.prefix && memberData.firstname && memberData.middlename && memberData.lastname &&
                                        <Grid item xs={7}>{`${memberData.prefix}. ${memberData.firstname} ${memberData.middlename} ${memberData.lastname}`}</Grid>}
                                    {memberData.prefix && memberData.firstname && !memberData.middlename && memberData.lastname &&
                                        <Grid item xs={7}>{`${memberData.prefix}. ${memberData.firstname} ${memberData.lastname}`}</Grid>}
                                </Grid>

                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>Gender</Grid>
                                    <Grid item xs={7}>{memberData.gender}</Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>Designation</Grid>
                                    <Grid item xs={7}>{memberData.designation}</Grid><br />
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>Institute</Grid>
                                    <Grid item xs={7}>{memberData.institute}</Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>State / MCI Registration Number</Grid>
                                    <Grid item xs={7}>{memberData.mcino}</Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>Date of Birth</Grid>
                                    <Grid item xs={7}>{memberData.dob}</Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>Name As to Appear on Badge</Grid>
                                    <Grid item xs={7}>{memberData.name}</Grid><br />
                                </Grid>
                                <br/>
                                <Typography className='headingstyle '>B.Contact Information</Typography>
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>Email:</Grid>
                                    <Grid item xs={7}>{memberData.email}</Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>Mobile</Grid>
                                    <Grid item xs={7}>{memberData.phone}</Grid></Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>Address</Grid>
                                    <Grid item xs={7}>{memberData.address}</Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>Country</Grid>
                                    <Grid item xs={7}>{memberData.country}</Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>State</Grid>
                                    <Grid item xs={7}>{memberData.state}</Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>City </Grid>
                                    <Grid item xs={7}>{memberData.city}</Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>Pincode</Grid>
                                    <Grid item xs={7}>{memberData.pincode}</Grid>
                                </Grid><br/>




                                <Typography className='headingstyle '>C.Other Information</Typography>

                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>Category</Grid>
                                    <Grid item xs={7}>{memberData.category}</Grid>
                                </Grid>
                                    {memberData.registration_no &&
                                   <Grid container item xs={12}>
                                   <Grid item xs={5} className='labelstyle'>ISA Regno</Grid>
                                   <Grid item xs={7}>{memberData.registration_no}</Grid>
                               </Grid>
                                }
                            
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>Type</Grid>
                                    <Grid item xs={7}>{memberData.attendees[0].type}</Grid><br />
                                </Grid><Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>Workshop Name</Grid>
                                    <Grid item xs={7}>{memberData.attendees[0].workshop_id}</Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>Amount </Grid>
                                    <Grid item xs={7}>{`Rs. ${memberData.attendees[0].amount}`}</Grid>
                                </Grid>
                              
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className='labelstyle'>Type of Position</Grid>
                                    <Grid item xs={7}>{memberData.attendees[0].interest}</Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid container item xs={5}>
                                        <Grid container item xs={12}>
                                            <Grid item xs={7} className='labelstyle'>E-Posters</Grid>
                                            <Grid item xs={5}>{memberData.attendees[0].poster}</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Grid container item xs={12}>
                                            <Grid item xs={6} className='labelstyle'>Abstract</Grid>
                                            <Grid item xs={6}>{memberData.attendees[0].abstract}</Grid><br />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid container item xs={5}>
                                        <Grid container item xs={12}>
                                            <Grid item xs={7} className='labelstyle'>Diet Preference</Grid>

                                            <Grid item xs={5}>{memberData.attendees[0].food_type}</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Grid container item xs={12}>
                                            <Grid item xs={6} className='labelstyle'>T-shirtsize</Grid>
                                            <Grid item xs={6}>{memberData.tshirt_size}</Grid><br />
                                        </Grid>
                                    </Grid>
                                </Grid>



                                <Grid container item xs={12}>
                                    <Grid container item xs={5}>
                                        <Grid container item xs={12}>
                                            <Grid item xs={7} className='labelstyle'>Practice</Grid>
                                            <Grid item xs={5}>{memberData.attendees[0].type_of_practice}</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Grid container item xs={12}>
                                            <Grid item xs={6} className='labelstyle'>Participation</Grid>
                                            <Grid item xs={6}>{memberData.attendees[0].activity}</Grid><br />
                                        </Grid>
                                    </Grid>
                                </Grid>
                          
                                <br />
                                <br />
                                { memberData.attendees[0].payment_status==="PAID"&&
                                <Grid>
                                    {`Received amount of `}
                                    <Typography component="strong" fontWeight="bold">
                                        {`Rs. ${memberData.attendees[0].amount}`}
                                    </Typography>
                                    {` for KATCON 2024`}
                                </Grid>}
                                <Grid textAlign={'right'}>------------------------------</Grid>
                                <Grid textAlign={'right'}>Authority Signature</Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    }
                </Grid>
            </Grid></Grid>

    );
}
export default MemberDetails


