import { Download } from '@mui/icons-material';
import apiClient from '../../API/API-client';

class ServerDateAPIService{
    private static instance: ServerDateAPIService;

    private constructor() {}
  
    static getInstance(): ServerDateAPIService {
      if (!ServerDateAPIService.instance) {
        ServerDateAPIService.instance = new ServerDateAPIService();
      }
      return ServerDateAPIService.instance;
    }

      async ServerDateAPI() {

        try{ 
            const response = await apiClient.get('getdate', {});
            return response.data;
          } catch (error) {
            console.error('Error getting date:', error);
            throw error;
          }
        }
      
     
    
  }
  
    const serverDateAPIService = ServerDateAPIService.getInstance();
    
    export default serverDateAPIService;