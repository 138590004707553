import React, { useEffect, useState } from 'react';
import moment from 'moment';

const CountdownTimer = ({ targetDate }: any) => {
  const calculateTimeLeft = () => {
    const now = moment().utcOffset(0, true);
    const target = moment(targetDate).utcOffset(0, true);

    const duration = moment.duration(target.diff(now));

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  // The useEffect hook in React calls the setTimerLeft function every second.
  // After each interval, it resets the interval using clearInterval.
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const hasEventStarted = moment(targetDate).isBefore(moment());

  return (
    <div className='count'>
      {hasEventStarted ? (
        <div className='event-started'></div>
      ) : (
        <>
          <div className='days'>{timeLeft.days.toString().padStart(2, '0')}<br /><span className='dy'> days</span></div>
          <div className='days'>{timeLeft.hours.toString().padStart(2, '0')}<br /><span className='dy'> hours</span></div>
          <div className='days'>{timeLeft.minutes.toString().padStart(2, '0')}<br /><span className='dy'> minutes</span></div>
          <div className='days'>{timeLeft.seconds.toString().padStart(2, '0')}<br /><span className='dy'> seconds</span></div>
        </>
      )}
    </div>
  );
};

export default CountdownTimer;
