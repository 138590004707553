import { Grid, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
  return (
<Grid className='footer'>
  <Grid container className='footer_content'>
<Grid item xs={12} md={4} sm={4}>
        <Typography className='footer_header'>About Us</Typography>
        <Typography className='footer_content'>ISA </Typography>
        <Typography className='footer_content'>Thrissur</Typography>
        <Typography className='footer_content'>Kerala,India</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography className= "footer_header">Contact</Typography>
        <Typography className='footer_content'>Email: isathrissurcitybranch@gmail.com</Typography>
        <Typography className='footer_content'>Phone: +91 9188008001</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography className='footer_header'>Links</Typography>
        <Typography className='footer_content'><a href='/terms' className='footerlink'>Terms and Conditions</a></Typography>
        <Typography className='footer_content'><a href='/privacypolicy' className='footerlink'>Privacy Policy</a></Typography>
        <Typography className='footer_content'><a href='/contactus' className='footerlink'>Contact Us</a></Typography>
        {/* <Typography>Instagram</Typography> */}
      </Grid>
      </Grid>
      <Grid className='footer_content'>  <Typography className="footer_content"color="white">
          &copy; 2023 ISA Kerala State Chapter. All rights reserved |
          Powered by <a href="https://www.nivid.co/" className='footerlink'>Nivid Solutions PVT. Ltd</a>
        </Typography></Grid>
</Grid>
  )
}

export default Footer