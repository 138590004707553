/*
  Home Screen Organization Details Section

  Developer: Athul P P
*/
import { Grid, Typography } from "@mui/material";
import React from "react";

const HomeContainer3 = () => {
  return (
    <Grid className="home_container2">
      <Grid xs={12} md={12} sm={12} className="center_align">
        <Typography className="font_style_header">
          ORGANIZING COMMITTEE
        </Typography>
        <Grid container xs={12} md={12} sm={12} className="home_workshop_ct">
          <Grid item xs={12} md={4} sm={4} className="align_fontSt_org">
            <Typography className="members_name">
              ➤ Dr. Shamsad Beegum
            </Typography>
            <Typography className="font_style1">
              Organising Chairman
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sm={4} className="align_fontSt_org">
            <Typography className="members_name">
              ➤ Dr. Baburaj C.
            </Typography>
            <Typography className="font_style1">
              Vice Chairman
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sm={4} className="align_fontSt_org">
            <Typography className="members_name">
              ➤ Dr. Arun Krishna
            </Typography>
            <Typography className="font_style1">
              Organizing Secretary
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sm={4} className="align_fontSt_org">
            <Typography className="members_name">
              ➤ Mr. Vijeesh A.V.
            </Typography>
            <Typography className="font_style1">
              Joint Organising Secretary
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sm={4} className="align_fontSt_org">
            <Typography className="members_name">
              ➤ Dr. Joseph Thomas
            </Typography>
            <Typography className="font_style1">
              Treasurer
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sm={4} className="align_fontSt_org">
            <Typography className="members_name">
              ➤ Dr. Ashish Karthik
            </Typography>
            <Typography className="font_style1">
              Scientific Committee Chairman
            </Typography>
          </Grid>
          {/* New Contact Person Section */}
          <Grid xs={12} md={12} sm={12} className="center_align">
            <Typography className="font_style_header">
              Contact Person
            </Typography>
            <Grid >
            <Typography className="members_name">
              Dr. Arun Krishna
              </Typography>
              <Typography className="font_style1">
              9605070077 
              </Typography>
              <Typography className="members_name">
              Mr. Vijeesh A.V.
              </Typography>
              <Typography className="font_style1">
              9447833261
              </Typography>
              </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};



export default HomeContainer3;
