import apiClient from '../../API/API-client';

class AdminAPIService {
  private static instance: AdminAPIService;

  private constructor() { }

  static getInstance(): AdminAPIService {
    if (!AdminAPIService.instance) {
      AdminAPIService.instance = new AdminAPIService();
    }
    return AdminAPIService.instance;
  }

  async memberList(searchdata: any, paginationModel: any) {
    let memberData;
    try {
      if (searchdata) {
        memberData = {
          company_id: 1,
          // start:paginationModel?.page,
          // limit:paginationModel?.pageSize,
          // "pagination":1,
          ...(searchdata?.memberemail && searchdata?.memberemail != '' && { email: searchdata?.memberemail }),
          ...(searchdata?.phone && { phone: searchdata?.phone }),
          ...(searchdata?.membername && { firstname: searchdata?.membername }),
          ...(searchdata?.payment_status && searchdata?.payment_status != 'Select' && { payment_status: searchdata?.payment_status }),
          ...(searchdata?.conference_name && { conference_id: searchdata?.conference_name!="Select"?searchdata?.conference_name:"2" }),
          ...(searchdata?.member_regno && { person_id: searchdata?.member_regno }),
          ...(searchdata?.workshop_details && { workshop_id: searchdata?.workshop_details }),
          ...(searchdata?.type && { type: searchdata?.type }),
          ...(searchdata?.interest && { interest: searchdata?.interest }),
          ...(searchdata?.amount && searchdata?.amount != 'Select' && {amount: searchdata?.amount}),
          ...(searchdata?.tshirt_size && { tshirt_size: searchdata?.tshirt_size }),
        }
      } else {
        memberData = {
          "company_id": 1,
          "conference_id":2
        }
      }
      const response = await apiClient.post('admin/memberlistall', memberData);
      return response.data;
    } catch (error) {
      console.error('Error listing member:', error);
      throw error;
    }
  }

  async attendeesList(data: any, paginationModel: any) {
    let memberData;
    try {

      memberData = {

        "company_id": 1,
        "conference_id": data.conference_id,
        ...(data?.type && { type: data?.type }),
        ...(data?.date && { date: data?.date }),

      }
      const response = await apiClient.post('admin/checkinlist', memberData);
      return response.data;
    } catch (error) {
      console.error('Error listing attendees:', error);
      throw error;
    }
  }

  async conferenceList() {
    try {
      let dataRequest = {

        "company_id": 1


      }

      const response = await apiClient.post('conferencelist', dataRequest);
      return response.data;
    } catch (error) {
      console.error('Error listing conference:', error);
      throw error;
    }
  }
  async workshopList() {
    try {
      let dataRequest = {

        "company_id": 1,

      }

      const response = await apiClient.post('admin/workshoplist', dataRequest);
      return response.data;
    } catch (error) {
      console.error('Error listing conference:', error);
      throw error;
    }
  }
  async workshopSearchList(data: any) {
    try {
      let dataRequest = {

        "company_id": 1,
        ...(data?.conference_id && { conference_id: data?.conference_id }),

      }

      const response = await apiClient.post('admin/workshoplist', dataRequest);
      return response.data;
    } catch (error) {
      console.error('Error listing Workshoplist:', error);
      throw error;
    }
  }

  async memberDelete(Attendee_ID: any, Person_ID: any) {
    try {
      let memberData = {
        "attendee_id": Attendee_ID,
        "person_id": Person_ID

      }

      const response = await apiClient.put('admin/deletemember/' + Attendee_ID, memberData);
      return response.data;
    } catch (error) {
      console.error('Error deleting member:', error);
      throw error;
    }
  }
  async EditPaymentstatus(rowData: any, editedData: any) {

    try {
      let memberData = {
        "id": rowData?.attendees[0]?.id,
        "status": rowData?.attendees[0]?.status,
        "payment_status": editedData,
        "amount": rowData?.attendees[0]?.amount


      }

      const response = await apiClient.put('attendee/update/' + rowData?.attendees[0]?.id, memberData);
      return response.data;
    } catch (error) {
      console.error('Error updating payment:', error);
      throw error;
    }
  }


  async SendPaymentReminder(rowData: any) {

    try {
      let memberData = {
        "id": rowData?.attendees[0]?.id,
      }

      const response = await apiClient.post('admin/payreminder', memberData);
      return response.data;
    } catch (error) {
      console.error('Error updating payment:', error);
      throw error;
    }
  }

  async EditWorkshopstatus(rowData: any, editedData: any, currentDateTime: any) {

    try {
      let memberData = {
        "id": rowData?.id,
        "status": editedData,
        "modified_date": currentDateTime


      }

      const response = await apiClient.put('admin/workshopupdate/' + rowData?.id, memberData);
      return response.data;
    } catch (error) {
      console.error('Error updating payment:', error);
      throw error;
    }
  }

}

const adminApiService = AdminAPIService.getInstance();

export default adminApiService;