// HeaderWithLogout.tsx (or .jsx)
import React from 'react';
import { Typography, Grid } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import '../../Admin/Homescreen/dashboard.scss';
import Header from '../../Admin/Common/Header';
import { Link ,useLocation} from 'react-router-dom';
// Define the properties for Headerfields
interface HeaderWithLogoutProps {
    title: string; // Title for the header
    icon: React.ReactElement; // Accept a React element as the icon
}

const HeaderWithLogout: React.FC<HeaderWithLogoutProps> = ({ title, icon }) => {
    const handleSignout = () => {
        // Clear the authentication information in sessionStorage
        sessionStorage.removeItem('isAuthenticated');
        // Redirect to the login page or perform any other necessary action
        window.location.href = '/isaadmin';
      };
    return (
        <Grid container className='header-with-logout-container'>
             {/* Include the Header component */}
            <Header />
             {/* Grid item for the title and icon */}
            <Grid item>
                <div className='rowview'>
                    {icon}
                    <Typography className='dashboard_title'>
                        {title}
                    </Typography>
                </div>
            </Grid>
            <Grid item>
                 {/* Link to trigger logout, includes Logout icon and text */}
            <Link to="/isaadmin" className='linkbutton' onClick={handleSignout}>
                <div className='rowview1'>
                    {/* Logout icon */}
                    <ExitToAppIcon className='logout-icon' />
                    {/* Logout text */}
                    <Typography className='logout-text'>
                        Logout
                    </Typography>
                </div></Link>
            </Grid>
        </Grid>
    );
};

export default HeaderWithLogout;
