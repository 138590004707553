import apiClient from '../../API/API-client';

class FileuploadApiService {
  private static instance: FileuploadApiService;

  private constructor() {}

  static getInstance(): FileuploadApiService {
    if (!FileuploadApiService.instance) {
      FileuploadApiService.instance = new FileuploadApiService();
    }
    return FileuploadApiService.instance;
  }

  async Fileupload(selectedImage:any) {
    try {
      const formData = new FormData();
      formData.append('file', selectedImage);
      
      const response = await apiClient.post('admin/fileupload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data' // Set content type to multipart/form-data
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  }
  
 
  
}

const fileuploadApiService = FileuploadApiService.getInstance();

export default fileuploadApiService;
