import apiClient from "../../API/API-client";


class PaymentApiService {
 

    private static instance: PaymentApiService;
    private constructor() {

    }

    static getInstance(): PaymentApiService {
        if (!PaymentApiService.instance) {
            PaymentApiService.instance = new PaymentApiService();
        }
        return PaymentApiService.instance;
    }
    /**
     * verify payment signature
     * create signature using order id,payment id and key secret
     * @param req 
     * @returns 
     */
    static async verifyPaymentSignature(data: {amountData:any, orderCreationId: any; razorpayPaymentId: any; razorpayOrderId: any; razorpaySignature: any; }) {
  
        try {
            const response = await apiClient.post('/paymentsuccess', data);
            return response.data;
        } catch (error) {
            console.error('Error razorpay success signature validation', error);
            throw error;
        }
    }
}

const paymentApiService = PaymentApiService.getInstance();


export default PaymentApiService;