import apiClient from '../../API/API-client';

class GalleryuploadApiService {
  private static instance: GalleryuploadApiService ;

  private constructor() {}

  static getInstance(): GalleryuploadApiService  {
    if (!GalleryuploadApiService .instance) {
        GalleryuploadApiService .instance = new GalleryuploadApiService ();
    }
    return GalleryuploadApiService .instance;
  }

  async Galleryupload(selectedFile_id:any,) {
    try {
        
   const gallerydata=
      {
    
        "file_id":selectedFile_id,
        "date":"2023-21-26",
        "company_id":1
    }
      
        const response = await apiClient.post('admin/addgalary', gallerydata);
        return response.data;

    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  }

  async GallerylistDataList() {
        
    try {
        let gallerylistData = {
            "company_id": 1,
        }
         
        const response = await apiClient.post('admin/listgalary', gallerylistData);
        return response.data;
    } catch (error) {
        console.error('Error contacting server:', error);
        throw error;
    }
}

async GallerydeleteDataList(Ids:any) {
 
        
  try {
    
       
      const resp = await apiClient.delete('admin/deletegalary/' +Ids);
      return resp.data;
  } catch (error) {
      console.error('Error contacting server:', error);
      throw error;
  }
}
  
 
  
}

const galleryuploadApiService  = GalleryuploadApiService .getInstance();

export default galleryuploadApiService ;
