import apiClient from '../../API/API-client';

class AddNewsApiService {
    private static instance: AddNewsApiService;

    private constructor() { }

    static getInstance(): AddNewsApiService {
        if (!AddNewsApiService.instance) {
            AddNewsApiService.instance = new AddNewsApiService();
        }
        return AddNewsApiService.instance;
    }
    async addnewsDataList(data: any,selectedImageid:any) {
        
        try {
            let addnewsData = {
                "title":data.title,
                "subtitle":data.SubHeading,
                "description":data.description,
                "image":selectedImageid,
                "date":data.date,
                "company_id":1,
                "tag1": data.tag1,
                "tag2": data.tag2,
                "frame": data.frame,
            }
             
            const response = await apiClient.post('admin/addnews', addnewsData);
            return response.data;
        } catch (error) {
            console.error('Error contacting server:', error);
            throw error;
        }
    }
}

const addnewsAPIService = AddNewsApiService.getInstance();

export default addnewsAPIService;
