/*
 header of the  Page   
  Developer: Athul P P
*/
import React from "react";
import { AppBar, Grid, Toolbar, Typography } from "@mui/material";
import Logo from "../../Assets/images/logo-blue.png";
import '../Homescreen/components/home.scss';
import DrawerAppBar from "./PrimaryAppBar";

const Header = () => {
  return (
    // <Grid container className="header">

    // <Grid container xs={12} sm={12}>
    // <Grid item xs={2} sm={4}>
    //     <img className="header_logo" src={Logo}></img>
    //   </Grid>
    //   <Grid item xs={1} sm={1}></Grid>
    //   <Grid item xs={9} sm={7}>
    //     <Grid xs={12}>
    //       <Typography className="font-family">
    //         Indian Society of Anaesthesiologists
    //       </Typography>
    //     </Grid>
    //     <Grid xs={12}>
  
    //       <Typography className="font-family blue_font">Kerala State Chapter</Typography>
    //     </Grid>

    //   </Grid>
    // </Grid>

    
    // </Grid>

    <AppBar position="static" className="header">
    <Toolbar>
      {/* <Grid container spacing={1} alignItems="center">
        <Grid item xs={2} sm={4}>
          <img className="header_logo" src={Logo} alt="Logo" />
        </Grid>
        <Grid item xs={1} sm={1}></Grid>
        <Grid item xs={9} sm={7}>
          <Grid container direction="column">
            <Typography variant="h6" className="font-family">
              Indian Society of Anaesthesiologists
            </Typography>
            <Typography variant="subtitle1" className="font-family blue_font">
              Kerala State Chapter
            </Typography>
          </Grid>
        </Grid>
      </Grid> */}
      <DrawerAppBar/>
    </Toolbar>
  </AppBar>
  );
};

export default Header;
