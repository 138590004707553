
/*
  Venue distance information from main transport hubs (airport and railway) and town.

  This section provides details about the distance of the venue from key transportation
  hubs and the town.

  Developer: Athul P P
*/

import { Grid, Typography } from "@mui/material";
import React from "react";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import DirectionsRailwayIcon from "@mui/icons-material/DirectionsRailway";
import LocationCityIcon from "@mui/icons-material/LocationCity";
const Distance_Venu = () => {
  return (
    <Grid className="venue_distance">
      <Grid container>
        <Grid item xs={6} md={4} sm={4} className="venue_items">
          <FlightTakeoffIcon />
          <Typography className="header_font">Distance from Airport</Typography>
          <Typography className="small_font">43.7 KM</Typography>
        </Grid>
        <Grid item xs={6} md={4} sm={4} className="venue_items">
          <DirectionsRailwayIcon />
          <Typography className="header_font">
            Distance from Railway Station
          </Typography>
          <Typography className="small_font">6.2 KM</Typography>
        </Grid>
        <Grid item xs={12} md={4} sm={4} className="venue_items">
          <LocationCityIcon />
          <Typography className="header_font">Distance from town</Typography>
          <Typography className="small_font">5.0 KM</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Distance_Venu;
