import { Download } from '@mui/icons-material';
import apiClient from '../../API/API-client';

class DownloadAPIService{
    private static instance: DownloadAPIService;

    private constructor() {}
  
    static getInstance(): DownloadAPIService {
      if (!DownloadAPIService.instance) {
        DownloadAPIService.instance = new DownloadAPIService();
      }
      return DownloadAPIService.instance;
    }

    
      async Download(searchdata:any) {
        let downloadData;
        try{
          if(searchdata){
            downloadData={
             company_id:1,
           ...(searchdata?.memberemail&& { email:searchdata?.memberemail }),
           ...( searchdata?.membername&& { firstname: searchdata?.membername }),
           ...( searchdata?.payment_status && searchdata?.payment_status!='Select' && { payment_status: searchdata?.payment_status }),
           ...( searchdata?.conference_name&& { conference_id: 2 }),
           ...(searchdata?.member_regno&& { person_id:searchdata?.member_regno }),
           ...( searchdata?.workshop_details&& { workshop_id: searchdata?.workshop_details }),
           ...(searchdata?.type&& { type:searchdata?.type}),
           ...(searchdata?.interest&& { interest:searchdata?.interest}),
           ...(searchdata?.tshirt_size&& { tshirt_size:searchdata?.tshirt_size}),
           }
         }else{
           downloadData=   {
                   "company_id": 1  ,
                   "conference_id":2
               }
             }
            const response = await apiClient.post('admin/memberlistall', downloadData);
            return response.data;
          } catch (error) {
            console.error('Error listing conference:', error);
            throw error;
          }
        }
      
     
    
  }
  
    const downloadApiService = DownloadAPIService.getInstance();
    
    export default downloadApiService;