import apiClient from '../../API/API-client';

interface ContactData {
  name: string;
  email: string;
  message: string;
}

class ContactusAPIService {
    private static instance: ContactusAPIService;

    private constructor() { }

    static getInstance(): ContactusAPIService {
        if (!ContactusAPIService.instance) {
            ContactusAPIService.instance = new ContactusAPIService();
        }
        return ContactusAPIService.instance;
    }
    async contactDataList(data:any) {
        try {
            let contactData = {

                "name":data.name,
                "email":data.email,
                "message":data.message
             
            
            }
        
                const response = await apiClient.post('contactus', contactData);
                return response.data;
            } catch (error) {
                console.error('Error contacting server:', error);
                throw error;
            }
        }
    }

const contactusAPIService = ContactusAPIService.getInstance();

export default contactusAPIService;
