import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Grid,Button } from "@mui/material";
import "../../Admin/Registration/addstyles.scss";
import "../../Admin/Common/common.scss";
import "../../Admin/Homescreen/dashboard.scss";
import newsListApiService from "../../../core/service/NewsListApiService";
import { useNavigate } from 'react-router-dom';
import volunteerAPIService from "../../../core/service/VolunteerAddApiService";


// Define the component
const AddvolunteerDataGrid: React.FC = () => {
  const navigate = useNavigate();

  const [news, setNewsData] = useState<any>([]);
  const [editedRowId, setEditedRowId] = useState<any | null>(null);
  const [editedStatus, setEditedStatus] = useState<any>('');


  const columns: GridColDef[] = [
    {
      field: "serialnumber",
      headerName: "ID",
     flex:1,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "name",
      headerName: "Volunteer Name",
      width: 400,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "volunteer_type",
      headerName: "Volunteer Type",
      width: 250,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
   
    {
      field: "phone",
      headerName: "Phone Number",
      width: 300,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
     
  ];
  const volunteerList = async () => {
    try {
      
      const apiData = await volunteerAPIService.VolunteerListDataList();
      
      if (apiData.data) {
        const dataWithSerialNumbers = apiData?.data?.map((row: any, index: any) => ({
          ...row,
          serialnumber: index + 1,
        }));
  
        setNewsData(dataWithSerialNumbers);
      }
    

      // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error during data fetch:", error);
      
    }
    
  };
 
  useEffect(() => {
    volunteerList();
  }, []);
  const getRowId = (row: any) => {
    if (row.id) {
      return row.id;
    }
    // Return a fallback value if the property is not available
    return '';
  };
  const getRowStyleParams = (params: any) =>
    params.indexRelativeToCurrentPage % 2 === 0 ? 'bg_grey_TableCell ' : 'bg_white_TableCell';
  return (
    <Grid >
              <DataGrid
                rows={news ? news : []}
                columns={columns}
                getRowId={getRowId}
                autoHeight
                getRowClassName={getRowStyleParams}
                localeText={{ noRowsLabel: "No data available" }}
                disableColumnMenu={true} />
    </Grid>
  );
};

export default AddvolunteerDataGrid;
