import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Grid, Typography, FormHelperText } from '@mui/material';
import adminApiService from '../../core/service/AdminApiService';
import '../Admin/Registration/addstyles.scss';
import '../Admin/Common/common.scss';
import '../Admin/Homescreen/dashboard.scss'
import moment from 'moment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HeaderWithLogout from './Common/Headerdesktop';
import SidebarToggle from './Common/SidebarToggle';
import { useForm, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import { InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { ContactlessOutlined } from '@mui/icons-material';

interface FormData {
  conference_id: string;
  type: string
  date: any
}

let flag: any;
const AttendeesList: React.FC = () => {

  const [members, setAttendeesData] = useState<any>([]);
  const [count, setCount] = useState<any>([])
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  const [conferenceId, setConferenceId] = useState<any>([]);
  const [resetDateFields, setResetDateFields] = useState(false);
  const [conference, setConferenceData] = useState<any>([]);
  const { control, handleSubmit, reset, setValue, getValues } = useForm<FormData>();
  const [selectedDate, setSelectedDate] = useState<any>();
  const datePickerRef = useRef<any>(null);
  const [typeSelected, setTypeSelected] = useState();

  useEffect(() => {

    conferenceList();

  }, []);

  const conferenceList = async () => {
    try {
      const apiData = await adminApiService.conferenceList();
      if (apiData) {

        setConferenceData(apiData.data);
      }
    } catch (error) {
      console.error(error, "error is getting here");
    }
  };

  // Define the handleConferenceSelect function
  const handleConferenceSelect = (selectedId: any) => {
    // Handle the selected conference ID here
    console.log("Selected Conference ID:", selectedId);
    setConferenceId(selectedId);
    // Additional logic, like setting state, can be added here
  };
  const fetchAttendeesList = async (data: any) => {

    const resp = await adminApiService.attendeesList(data, paginationModel);
    if (resp.data) {
      const dataWithSerialNumbers = resp?.data?.checkinList?.map((row: any, index: any) => ({
        ...row,
        serialNumber: index + 1,
      }));
      flag = 1;
      setAttendeesData(dataWithSerialNumbers);
      setTypeSelected(dataWithSerialNumbers[0]?.type);
      setCount(resp?.data?.Count)
    }

  }
  const commonColumns: GridColDef[] = [
    {
      field: 'serialNumber',
      headerName: 'ID',
      flex: 1,
      headerClassName: 'headergridcell_blue1',
      cellClassName: 'gridcolumn-cells',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: 'id', headerName: 'RegNO', width: 120, headerClassName: 'headergridcell_blue1 black_headertext',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",

      sortable: false,
      renderCell: (params) => {

        const reg_No = conferenceId === 1
          ? `ISACON${params.row?.person.id}`
          : `KATCON${params.row?.person.id}`;
        if (reg_No) {
          return reg_No

        }
        else {
          return null
        }
      },

    },

    {
      field: 'namefield', headerName: 'Name', width: 230, headerClassName: 'headergridcell_blue1',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",

      sortable: false,
      renderCell: (params) => {
        const firstname = params.row?.person?.firstname;
        const middlename = params.row?.person?.middlename;
        const lastname = params.row?.person?.lastname;
        let fullName: any;
        if (firstname && !lastname && !middlename) {
          fullName = `${firstname}`;
        }
        else if (firstname && lastname && !middlename) {
          fullName = `${firstname}  ${lastname}`;
        }
        else if (firstname && lastname && middlename) {
          fullName = `${firstname}  ${middlename} ${lastname}`;
        }
        if (fullName !== null) {
          return fullName
        }
        else {
          return null
        }

      },
    },
    {
      field: 'email', headerName: 'Email', width: 250, headerClassName: 'headergridcell_blue1',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        const email = params.row?.person.email;
        if (email) {
          return email
        }
        else {
          return null
        }
      },
    },
    {
      field: 'phone', headerName: 'Phone Number', width: 170, headerClassName: 'headergridcell_blue1',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        const phoneno = params.row?.person?.phone;
        if (phoneno) {
          return phoneno
        }
        else {
          return null
        }
      },
    },
    {
      field: 'type', headerName: 'Type', width: 150, headerClassName: 'headergridcell_blue1',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        let typevalue: any
        const type = params?.row?.type;
        if (type == "conf") {
          typevalue = "CONFERENCE"
        } else if (type == "food") {
          typevalue = "FOOD"
        } else {
          typevalue = "CERTIFICATE"
        }
        if (typevalue) {
          return typevalue
        }
        else {
          return null
        }
      },
    },

    {
      field: 'date', headerName: 'Date', width: 120, headerClassName: 'headergridcell_blue1 black_headertext',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        if (params?.row?.date) {
          const originalDate = params?.row?.date;
          const formattedDate = moment(originalDate).format("YYYY-MM-DD");
          return formattedDate
        }
        else {
          return null
        }
      },

    },
  ];
  const columns: any = [
    ...commonColumns,
    ...(typeSelected === 'conf' || typeSelected === 'food'
      ? [{
        field: 'time',
        headerName: 'Time',
        width: 150,
        headerClassName: 'headergridcell_blue1 black_headertext',
        cellClassName: 'gridcolumn-cells',
        headerAlign: 'center', // Correct type here
        sortable: false,
      }]
      : [
        {
          field: 'certificate_issued',
          headerName: 'Certificate Issued',
          width: 150,
          headerClassName: 'headergridcell_blue1 black_headertext',
          cellClassName: 'gridcolumn-cells',
          sortable: false,
          align: 'center',
          renderCell: (params: any) => {
            const phoneno = params.row?.certificate_issued;
            if (phoneno) {
              return "✅";
            }
            else {
              return null
            }
          },
        }
      ]
    )
  ];
  const onSubmit = (data: any) => {
    if (selectedDate) {
      const dateObject = new Date(selectedDate);
      const formattedDate = `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')}`;
      setValue('date', formattedDate);
    } else {
      setValue('date', "");
    }

    const formdata = getValues()
    fetchAttendeesList(formdata)

  }

  const getRowId = (row: any) => {
    if (row.id) {
      return row.id;
    }
    return '';
  };

  const formatToDate = (dateString: string | null) => {
    return dayjs(dateString, 'MM/DD/YYYY')?.format('YYYY-MM-DD');
  };



  const formatToMMDDYYYY = (date: Date | null) => {
    return date ? dayjs(date) : null;
  };

  const resetFormField = () => {
    reset({
      conference_id: "", // Reset conference_id field if needed
      type: "", // Reset type field if needed

    });
    setSelectedDate(""); // Reset date field to null
  };

  const handleDateChange = (date: any) => {

    setSelectedDate(date);

  };

  const getRowStyleParams = (params: any) =>
    params.indexRelativeToCurrentPage % 2 === 0 ? 'bg_grey_TableCell ' : 'bg_white_TableCell';

  return (
    <Grid className="dashboardcontainer">
      <Grid container justifyContent="center">
        <Grid item xs={1} className='sidebar'>
          <SidebarToggle />
        </Grid>
        <Grid item xs={11} className='content-container'>
          <Grid className='conferenceSummaryContainer'>
            <HeaderWithLogout title="Dashboard/Attendees Listing" icon={<DashboardIcon className='dashboard-icon' />} /></Grid>
          <Grid xs={12} className='dash_paper2'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} className='searchformpad'>
                  <FormControl fullWidth variant="outlined" className="form-control">
                    <InputLabel htmlFor="conference_name">Conference Name</InputLabel>
                    <Controller
                      name="conference_id"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Select the conference' }}
                      render={({ field, fieldState }) => (
                        <>
                          <Select {...field} label="Conference Name" onChange={(event) => {
                            const selectedId = event.target.value;
                            field.onChange(selectedId);  // Update the form field value
                            handleConferenceSelect(selectedId);  // Call your custom function
                          }}
                          >
                            {conference?.map((item: any) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText error={!!fieldState.error}>{fieldState.error?.message}</FormHelperText>
                        </>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className='searchformpad'>
                  <FormControl fullWidth variant="outlined" className="form-control">
                    <InputLabel htmlFor="another_conference" >
                      Type
                    </InputLabel>
                    <Controller
                      name="type"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <Select {...field} label="Type">
                            <MenuItem value="">SELECT</MenuItem>
                            <MenuItem value="conf">CONFERENCE</MenuItem>
                            <MenuItem value="food">FOOD</MenuItem>
                            <MenuItem value="CERTIFICATE">CERTIFICATE</MenuItem>
                          </Select>
                        </>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className='searchformpad'>
                  <Controller
                    name="date"
                    control={control}
                    defaultValue={""}
                    render={({ field, fieldState }) => (
                      <Grid>
                        <Grid className="inputContainer1">

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid id="date">
                              <FormControl fullWidth>
                                <DatePicker
                                  value={selectedDate ? formatToMMDDYYYY(selectedDate.toDate()) : null}
                                  onChange={handleDateChange} // Handle date change
                                  //className="customSelectdate"
                                  autoFocus={fieldState.error ? true : false}


                                />

                              </FormControl>
                            </Grid>
                          </LocalizationProvider>
                        </Grid>

                      </Grid>
                    )}
                  />

                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Button type="submit" variant="contained" color="primary">
                    Search
                  </Button>&nbsp;


                  <Button type="button" onClick={resetFormField} variant="contained" color="primary">
                    Reset
                  </Button>&nbsp;
                </Grid>
              </Grid>
            </form>
          </Grid>
          {members?.length > 0 &&
            <Grid>
              <Grid xs={3} md={3} sm={12} className='dash_paper2'>

                <Card>
                  <CardContent>

                    <Typography variant="h6" component="div">
                      {conferenceId === 1 ? (
                        <>Total Attendees: {count[0] ? count[0].personCount : 0}</>
                      ) : (
                        <>Total Attendees: {count[1] ? count[1].personCount : 0}</>
                      )}
                    </Typography>
                  </CardContent>
                </Card>

              </Grid>

              <Grid className='searchformpad'>
                <Typography className='dashboard_title'>Attendees List</Typography>
                {/* </Grid> */}
                <Grid className='dashmainContent1'>
                  <DataGrid
                    rows={members ? members : []}
                    columns={columns}
                    getRowId={getRowId}
                    getRowClassName={getRowStyleParams}
                    autoHeight
                    getRowHeight={() => "auto"}
                    localeText={{ noRowsLabel: "No data available" }}
                    disableColumnMenu={true}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                  />
                </Grid>

              </Grid>
            </Grid>
          }
          {flag == 1 && members?.length == 0 &&
            <Grid className='nodata_message'>Sorry! No Data Found</Grid>}
        </Grid>
      </Grid></Grid>

  );
}

export default AttendeesList;
