import { Button, Grid, Typography } from "@mui/material";
import React from "react";
// import Carousel from "./Slider";
import venu1 from '../../../Assets/images/venue1.jpg';
import Carousel from "./Venu_carousel";
import PlaceIcon from '@mui/icons-material/Place';
import { countries } from "./data";
const Contact_us = () => {
  return (
    <Grid className="home_contact">
      <Grid container className="home_contact">
        <Grid item xs={12} md={12} sm={12}>
          <Typography className="contact_header">About Venue</Typography>
          <Typography className="contact_content">Alumini Hall, Govt. Medical College</Typography>
          <Typography className="contact_content">
           Thrissur, Kerala 680014
          </Typography>
          <Button className="contact_button">
            <a href="https://google.com/maps/search/govt+medical+college+thrissur/@10.6141333,76.1923883,16z/data=!3m1!4b1?entry=ttu" target="_blank">
              <Grid className="align_row">
              <PlaceIcon className="icon_size linkwhite"/>
             <Typography className="contact_content linkwhite" >View Location</Typography> 
              </Grid>

            </a>{" "}
          </Button>
        </Grid>
       
        <Grid item xs={12} md={12} sm={12}>
            
          {/* <Carousel /> */}
          {/* <img src={venu1} style={{width:"50%",height:"100%"}}></img> */}
          <Carousel images={countries}/>
        </Grid>
        {/* <Grid item xs={12} md={6} sm={6}>
     
        </Grid> */}
       
      <Grid>
      
        {/* <div class="row text-center" style="background: #222632; color: white; padding: 40px">
            <div class="col-md-4" style="border-right: 1px solid #3e4454">
                <i class="fa fa-plane" aria-hidden="true" style="color: #dfa965; font-size: 1.5rem"></i>
                <h6 class="mt-2"><strong>Distance from Airport</strong></h6>
                <p class="mt-3">7.0 km</p>
            </div>
            <div class="col-md-4" style="border-right: 1px solid #3e4454">
                <i class="fa fa-train" aria-hidden="true" style="color: #dfa965; font-size: 1.5rem"></i>
                <h6 class="mt-2"><strong>Distance from Railway Station</strong></h6>
                <p class="mt-3">4.1 km</p>
            </div>
            <div class="col-md-4">
                <i class="fa fa-building" aria-hidden="true" style="color: #dfa965; font-size: 1.5rem"></i>
                <h6 class="mt-2"><strong>Distance from City Centre</strong></h6>
                <p class="mt-3">0 km</p>
            </div>
        </div> */}
      </Grid>
      </Grid>
    </Grid>
  );
};

export default Contact_us;
