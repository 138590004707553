import React, { useState, useRef, useEffect } from 'react';
import { Grid, Button, Typography, Container, Box } from '@mui/material';
import "../Registration/addstyles.scss"
import '../../Admin/Registration/addstyles.scss';
import '../../Admin/Common/common.scss';
import '../../Admin/Homescreen/dashboard.scss'
import DashboardIcon from '@mui/icons-material/Dashboard';
import HeaderWithLogout from '../Common/Headerdesktop';
import fileuploadApiService from '../../../core/service/FileuploadApiService';
import SidebarToggle from '../Common/SidebarToggle';
import galleryuploadApiService from '../../../core/service/GalleryuploadApiService';
import config from '../../../config.json';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from "framer-motion";

// Styled Dialog with custom styles
const BootstrapDialog = styled(Dialog)(({ theme}) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


const Addgalleryadmin: React.FC = () => {
  const [listfileid, setlistfileid] = useState<any>([]);
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [selectedFile, setSelectedFile] = useState<any>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const baseURL = config.prodapi.url;
  const [open, setOpen] = React.useState(false);
  
  const handleClick = (fileId: any) => {
    setSelectedImage(fileId.file_id); // Assuming fileId.file_id holds the image ID
    setOpen(true);
  };
  

 // Close dialog handler
 const handleClose = () => {
  setOpen(false);
};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiData = await galleryuploadApiService.GallerylistDataList();
        setlistfileid(apiData.data);
      } catch (error) {
        console.error("Error fetching fileIds", error);
      }
    };

    fetchData();
  }, []);

  const handleDeleteImage = async (Ids: any) => {

    try {
      const resp = await galleryuploadApiService.GallerydeleteDataList(Ids);

      window.location.reload(); // Reloads the current page

    } catch (error) {
      console.error("error uploading news data", error)
    }
  };

  const handleuploadimage = async (data: any) => {
    try {
      if (selectedFile) {
        const imageresponse = await fileuploadApiService.Fileupload(selectedFile);
        // Make an API call to upload the image using your service

        try {
          const response = await galleryuploadApiService.Galleryupload(imageresponse.data.id);
          // Handle the response as needed (e.g., display success message)

          const apiData = await galleryuploadApiService.GallerylistDataList();
          setlistfileid(apiData.data);
          const fileIds = apiData.data.map((item: any) => item.file_id);
        } catch (error) {
          console.error("error uploading news data", error)
        }
        // Clear the selected file after successful upload
        setSelectedFile(null);
        window.location.reload(); // Reloads the current page
      } else {
        console.error('No file selected');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }

  };


  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // If the file input ref is available, click it to open the file dialog
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  return (
    <Grid className="dashboard-container">
      <Grid container justifyContent="center">
        <Grid item xs={1} className='sidebar'>
          <SidebarToggle />
        </Grid>

        <Grid item xs={11} className='content-container'>
          <Grid className='conferenceSummaryContainer'>
            <HeaderWithLogout title="Dashboard/Add Gallery" icon={<DashboardIcon className='dashboard-icon' />} /></Grid>
          <Container className='dash_paper1'>
          <Typography variant="h5" align='left' className="gallery_typography">
                  Add Gallery
                </Typography>
            <Grid container spacing={2} item xs={12}>
              <Grid>
                <Box
                  border={4}
                  borderRadius={2}
                  borderColor="#CBD0DC"
                  style={{ borderStyle: 'dashed', padding: '16px', marginTop: '16px', backgroundColor: 'rgba(232, 239, 246, 0.35)' }}
                  width={260}
                  height={240}
                >
                  <Typography variant="body1">
                    <CloudUploadOutlinedIcon className='imageupload_icon' /><br></br>
                    <Grid item xs={12} textAlign="center">
                      <input
                        hidden
                        id="fileInput"
                        type="file"
                        onChange={handleFileSelect}
                        ref={fileInputRef}
                      />
                   
                      {selectedFile ? (
                        <div>
                          <span>{selectedFile.name}</span><br/><br/>
                          <span className='formats_image'>JPG,JPEG, PNG, PDG, and MP4 formats</span><br/>
                          <Button variant="contained" onClick={handleuploadimage} color="primary" className='addimagebtn'>
                            Add Image
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <Typography variant="body1" align="center" className="typography_file">
                        Choose a file here
                      </Typography><br/>
                      <span className='formats_image'>JPG,JPEG, PNG formats</span>
                      <br></br><br></br>
                          <button onClick={handleButtonClick} className='Browse_btn'>Browse Image</button>
                        </div>
                      )}
                    </Grid>
                  </Typography>
                </Box>
              </Grid>
              {listfileid.map((fileId: any) => (
                <Grid item key={fileId} onClick={() => handleClick(fileId)}> 
                  <img src={`${baseURL}admin/download/${fileId.file_id}`} alt={`Image ${fileId}`} style={{ width: '270px', height: '280px', alignItems: 'center' }} /><br></br><br></br>
                  <Button className='Delete_btn' onClick={() => handleDeleteImage(fileId.id)}>Delete
                  </Button>
                </Grid>
              ))}
              {selectedImage !== null && (
  <BootstrapDialog
    aria-labelledby="customized-dialog-title"
    open={open}
  >
    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title"></DialogTitle>
    <IconButton
      aria-label="close"
      onClick={handleClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
    <DialogContent>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <img
  src={`${baseURL}admin/download/${[selectedImage]}`}
  alt={`Image ${selectedImage}`}
  style={{ maxWidth: "100%" }}
/>

      </motion.div>
    </DialogContent>
  </BootstrapDialog>
)}
            </Grid>
          </Container>

        </Grid></Grid></Grid>
  );
};

export default Addgalleryadmin;