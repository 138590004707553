import React,{useEffect,useRef} from 'react';
import { Controller} from 'react-hook-form';
import { Grid, Select,InputLabel,FormHelperText, TextField } from '@mui/material';
import './EditMember.scss';
interface FieldProps {
  control: any;
  defaultValue: any;
  name: string;
 // Make icon optional by adding '?'
  type?: 'text' | 'select'; // Define the type of field ('text' or 'select')
  children?: React.ReactNode; // Children for select field
  selectWidth?: string; // Custom width for select field
  // label: string
  rules: any;
  
}

// Inside FormField component
const EditMemberTextField = ({ control, name, defaultValue, rules}: FieldProps) => {
  const selectRef = useRef(null);

 
  
 
    return (
        <>
            {/* <Grid item xs={12}><label className='label-memberview'>{label}</label></Grid> */}
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field,fieldState}) => ( 
        <div >
        <Grid className="inputContainer">
            <>
              <input
                {...field}
                type="text"
                id={name}
                className="input-memberview"
            required
              />
            
            </>
    
          </Grid><br/>
         
          <FormHelperText error={!!fieldState.error} className='error-msg'>
                {fieldState.error?.message}
              </FormHelperText>
        
        </div>
      )}
            />
            </>
  );
};

export default EditMemberTextField;
