/*
  This is the main component for the home page
  where all the necessary components are added.
  
  Developer: Athul P P
*/
import React, { useEffect, useRef } from "react";
import Header from "../Common/Header";
import Home_carousel from "./components/Home_carousel";
import HomeContainer1 from "./components/HomeContainer1";
import HomeContainer2 from "./components/HomeContainer2";
import HomeContainer3 from "./components/HomeContainer3";
import Contact_us from "./components/Contact_us";
import Footer from "../Common/Footer";
import Distance_Venu from "./components/Distance_Venu";
import Terms_and_Condition from "./components/Terms_and_Condition";
import Bank_Details from "./components/Bank_Details";
import Conference_fee_Table from "./components/Conference_fee_Table";
import Workshop_fee_table from "./components/Workshop_fee_table";
import GalleryImageSlider from "./components/galleryslider";
import { Typography } from "@mui/material";
import Team from "./components/team";
import { useLocation } from 'react-router-dom';
import AdPopup from "./components/Ad_popup";
const HomePage: React.FC = () => {
 
  const location = useLocation();
  const targetElementRef = useRef(null);
  const [showPopup,setShowPopup]=React.useState(true);
  useEffect(() => {
    const targetId = location.hash.substring(1); // Remove the '#' from the hash
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
    showAdPopUp();

  }, [location]);

   const showAdPopUp=()=>{
    const currentDate = new Date();
    const endDate = new Date('2024-01-16'); // Assuming 'YYYY-MM-DD' format
  
    // Hide the popup after the specified end date
    if (currentDate > endDate) {
      console.log(currentDate);
      console.log(endDate);
      setShowPopup(false);
    }
    else{
      setShowPopup(true);
    }
   
  
    }
  return (
    <div>
      {/* <Header /> */}
      {/* {showPopup && (
      <AdPopup /> 
      )} */}
      <div className="home" id ="home" ref={targetElementRef}>
      <HomeContainer1 />
      </div>
      <HomeContainer2 />
      <HomeContainer3 />
      <Terms_and_Condition/><br/><br/>
      {/* <Conference_fee_Table/><br/><br/> */}
      {/* <Workshop_fee_table/> */}
      {/* <Bank_Details/> */}
      
      <Contact_us />
      <div  className ="gallery" id="gallery" ref={targetElementRef}>
      <GalleryImageSlider/>
      </div>
      <div  className ="ourteam" id="ourteam" ref={targetElementRef}>
      <Team/>
      </div>
      <Distance_Venu />

      {/* <Footer /> */}
    </div>
  );
};

export default HomePage;
