import { idText } from 'typescript';
import apiClient from '../../API/API-client';


class EditMemberAPIService {
    private static instance: EditMemberAPIService;

    private constructor() { }

    static getInstance(): EditMemberAPIService {
        if (!EditMemberAPIService.instance) {
            EditMemberAPIService.instance = new EditMemberAPIService();
        }
        return EditMemberAPIService.instance;
    }
    async EditMemberList(data: any, id: any,company_id:any,city:any) {
        //  console.log('data>>>>',data)
        
        try {
            let EditMemberData = {
                
                "id":id,
              //  "isa_member"  : data.registration_no?1:0,
               // "prefix": data.prefix,
                "firstname": data.firstname,
                "middlename": data.middlename,
                "lastname": data.lastname,
                "name":data.firstname,
                "email": data.email,
                // "address": data.address,
                // "city": data.city.value??city,
                // "state": data.state,
                // "country": data.country,
                // "pincode": data.pincode,
                "age": data.dob,
            //     "registration_no":data.registration_no,
            //     "food_type":data.food_type,
                "company_id":company_id,
            //    "conference_id":data.conference_id,
            //     "workshop_id": data.workshop_id,
                "institute": data.institute,
            //     "designation": data.designation,
            //     "mcino": data.mcino,
            //   "type_of_practice": data.type_of_practice,
            //     "interest":data.interest,
            //    "activity": data.activity,    
            //     "type": data.type,
                "phone": data.phone,
                 "gender": data.gender,
                 "category":data.category?data.category:"NON_ISA MEMBERS",
                "amount": data.amount,
                // "tshirt_size": data.tshirt_size,
                // "poster":data.poster,
                // "abstract": data.abstract,
                "payment_status":data.payment_status,
            }
           
            
            const response = await apiClient.put('member/update/' + id, EditMemberData);
            // console.log('API Response:', response);
                return response.data;
            } catch (error) {
                // console.error('Error contacting server:', error);
                throw error;
            }
        }
    }

const editMemberAPIService= EditMemberAPIService.getInstance();

export default editMemberAPIService;
