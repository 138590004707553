import { Download } from '@mui/icons-material';
import apiClient from '../../API/API-client';

class DashboardAPIService{
    private static instance: DashboardAPIService;

    private constructor() {}
  
    static getInstance(): DashboardAPIService {
      if (!DashboardAPIService.instance) {
        DashboardAPIService.instance = new DashboardAPIService();
      }
      return DashboardAPIService.instance;
    }

    
      async DashboardListApi(selectedConference:any) {

        try{
          let dataRequest={};
          if(selectedConference && selectedConference!="Select" && selectedConference!=""){
            dataRequest=   {
              "conference_id": selectedConference   
            }
          }
            
            
            const response = await apiClient.post('admin/dashboard', dataRequest);
            return response.data;
          } catch (error) {
            console.error('Error listing conference:', error);
            throw error;
          }
        }
      
     
    
  }
  
    const dashboardApiService = DashboardAPIService.getInstance();
    
    export default dashboardApiService;