import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { Grid, Typography } from "@mui/material";
import '../../Homescreen/components/home.scss'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import galleryuploadApiService from '../../../core/service/GalleryuploadApiService';
import config from '../../../config.json';

// Styled Dialog with custom styles
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const GalleryImageSlider = () => {
    const [width, setWidth] = useState<any>(0);
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState<number | null>(null);
    const carousel = useRef<any>();
    const controls = useAnimation();
    const currentX = useRef(0);

    const baseURL = config.prodapi.url;

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    // Close dialog handler
    const handleClose = () => {
      setOpen(false);
    };
    
    //Image View Api Integration
    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiData = await galleryuploadApiService.GallerylistDataList();
                // apiData.data contains an array of image file IDs
                setImages(apiData.data.map((fileId: any) => `${baseURL}admin/download/${fileId.file_id}`));
            } catch (error) {
                console.error("Error fetching fileIds", error);
            }
        };

        fetchData();
    }, []);

    // Calculate width 
    useEffect(() => {
        setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
        
    }, []);

    // Handle automatic scrolling
    useEffect(() => {
        controls.start({ x: 0 }); // Initial position

        // Calculate the scroll distance for one image
        const singleImageScrollDistance = carousel.current.offsetWidth / images.length;

        // Calculate the total width to cover all images
        const totalWidth = carousel.current.scrollWidth - carousel.current.offsetWidth;

        // Function to handle automatic scrolling
        const handleAutoScroll = () => {
            const nextX = currentX.current - singleImageScrollDistance;

            // Animate scrolling
            controls.start({
                x: nextX,
                transition: {
                    type: "spring",
                    stiffness: 80,
                    damping: 12,
                    duration: 2,
                    delay: 1,
                },
            });

            currentX.current = nextX;

            if (nextX < -totalWidth) {
                // If the entire carousel has been covered, reset to the first position
                controls.start({ x: 0, transition: { duration: 0 } });
                currentX.current = 0;
            }
        };

        // Set up automatic scrolling interval
        const scrollInterval = setInterval(() => {
            handleAutoScroll();
        }, 3000); 

        // Clear interval on component unmount
        return () => clearInterval(scrollInterval);
    }, [controls, carousel, images.length, width]);
  
    // Handle click on individual image
    const handleClick = (index: number) => {
        setSelectedImage(index);
        setOpen(true)
    };

    // Handle manual movement of the carousel
    const handleMove = (direction: "prev" | "next") => {
        const step = direction === "prev" ? 1 : -1;
        const singleImageScrollDistance = carousel.current.offsetWidth / images.length;
        const nextX = currentX.current + step * singleImageScrollDistance;
    
        // Animate scrolling if nextX is less than 0
        if (nextX < 0) {
            controls.start({
                x: nextX,
                transition: {
                    type: "spring",
                    stiffness: 80,
                    damping: 12,
                    duration: 0.5,
                },
            });
    
            currentX.current = nextX;
        } else {
            // If nextX is greater than or equal to 0, reset to the first position
            controls.start({
                x: 0,
                transition: { duration: 0.5 },
            });
    
            currentX.current = 0;
        }
    };
    
    return (
        
        <Grid className="home_container2" >
            <Grid xs={12} md={12} sm={12} className="center_align">
                <Typography className="font_style_header">
                    OUR GALLERY
                </Typography>
                <div className="galleryslider">
                    <motion.div ref={carousel} className="carousel">
                        <motion.div
                            drag="x"
                            dragConstraints={{ right: 0, left: -width }}
                            animate={controls}
                            className="innercarousel"
                        > 
                        {/* Map through images and render each in the carousel */}
                        {images.map((image: string, index: number) => (
                <motion.div className="item" key={index} onClick={() => handleClick(index)}>
                    <img
                        className="itemimg"
                        src={image}
                        alt={`Image ${index}`}
                        style={{ maxWidth: "100%",objectFit:"fill" }}
                    />
                </motion.div>
            ))}
                        </motion.div>
                    </motion.div>

                    {/* Navigation arrows */}
                    <div className="arrow-buttons">
            <IconButton onClick={() => handleMove("prev")}>
              <ArrowBackIosNewSharpIcon />
            </IconButton>
            <IconButton  onClick={() => handleMove("next")} >
              <ArrowForwardIosSharpIcon />
            </IconButton>
          </div>
                </div>
                 {/* Display enlarged image in a dialog */}
                {selectedImage !== null && (
                    <BootstrapDialog
                   // onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                  >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                      
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <DialogContent  >
                    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
    >
        <img
            src={images[selectedImage]}
            alt={`Image ${selectedImage}`}
            style={{ maxWidth: "100%" }}
        />
       
    </motion.div>
                    </DialogContent>
                   
                  </BootstrapDialog>
                )}
            </Grid>
        </Grid>
       
    );
};

export default GalleryImageSlider;
