import React, { useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid, Button, Typography, Container, TextareaAutosize, IconButton } from '@mui/material';
import "../Registration/addstyles.scss"
import '../../Admin/Common/common.scss';
import '../../Admin/Homescreen/dashboard.scss'
import DashboardIcon from '@mui/icons-material/Dashboard';
import HeaderWithLogout from '../Common/Headerdesktop';
import SidebarToggle from '../Common/SidebarToggle';
import "../Registration/addstyles.scss"
import CustomTextField from '../Common/CustomTextField';
import addnewsAPIService from '../../../core/service/AddNewsApiService';
import fileuploadApiService from '../../../core/service/FileuploadApiService';
import { useNavigate } from 'react-router-dom';
import NewsDate from './NewsDatepicker';
import CustomTextFieldNews from './NewsTextfield';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles


const AddNews: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);  // State to track sidebar visibility
  const [selectedFile, setSelectedFile] = useState<any>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate()

  const currentdate = new Date();

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  // Initialize react-hook-form
  const { handleSubmit, control, setValue, register,formState: { errors } } = useForm();

  const onSubmit = async (data: any) => {
   
    if (!data.image) {
      alert('Please select an image.');
    }
    else {
      uploadNews(data)
      navigate("/isaadmin/newslist");
      window.location.reload();
    }

  };
  const uploadNews = async (data: any) => {
    try {
      const imageresponse = await fileuploadApiService.Fileupload(data.image);
      if (imageresponse.data) {
        try {
          const response = await addnewsAPIService.addnewsDataList(data, imageresponse.data.id)
        } catch (error) {
          console.error("error uploading news data", error)
        }
      }

    } catch (error) {
      console.error("error uploadind image", error)
    }

  }

  const handleFileSelect = (event: any) => {
    const file = event.target.files[0];
    if (!file) {
      // User canceled the file upload, do nothing
      return;
    }
    const allowedExtensions = ["jpg", "jpeg", "png", "gif"];
    const fileExtension = file?.name?.split(".").pop()?.toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      alert("Please select an image file in JPG, JPEG, PNG, or GIF format.");
      return;
    }

    setValue("image", file);
    setSelectedFile(file);
  };



  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // If the file input ref is available, click it to open the file dialog
    if (fileInputRef.current) {
      fileInputRef.current.click();

    }
  }
  return (
    <Grid className="dashboard-container">
      <Grid container justifyContent="center">
        <Grid item xs={1} className='sidebar'>
          <SidebarToggle />
        </Grid>

        <Grid item xs={11} className='content-container'>
          <Grid className='conferenceSummaryContainer'>
            <HeaderWithLogout title="Dashboard/Add News" icon={<DashboardIcon className='dashboard-icon' />} /></Grid>
          <Grid xs={12}  className='dash_paper1'>
            <Grid item xs={12} sm={12}  >
              <Grid>
                <IconButton onClick={toggleSidebar} className='menu-icon'> {/* Add IconButton with onClick handler */}
                  {/* <MenuIcon className='menuiconstyle'/> */}
                </IconButton></Grid>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} >
                    <Typography variant="h5" className="form-title">
                      ADD NEWS FORM
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="title"
                      label='Title'
                      control={control}
                      type={'text'}
                      defaultValue=""
                      variant="outlined"
                      
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      type={'text'}
                      name="SubHeading"
                      control={control}
                      defaultValue=""
                      label='Sub Title'
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography className='confadd_description'>
                      News *:
                    </Typography>
                    <Controller
            name="description"
            control={control}
            defaultValue=""
            rules={{ required: "This field is required" }}
            render={({ field }) => (
              <ReactQuill
                {...field}
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ size: ['small', false, 'large', 'huge'] }],
                    [{ list: 'bullet' }], // Add bullets
                    [{ color: [] }],
                    ['link'] // Add text color
                  ],
                }}
                placeholder="Enter News..."
                className="quilltext" // Add your custom class for styling
                onBlur={() => field.onBlur()} // Make sure onBlur is handled
              />
            )}
          /> <br/><br/>
           {errors.description && typeof errors.description.message === 'string' && (
  <span role="alert" className="error-message">
    {errors.description.message}
  </span>
)}
                  </Grid>
               
                  <Grid item xs={6}>
                    <CustomTextField
                      type={'text'}
                      name="tag1"
                      control={control}
                      defaultValue=""
                      label='Tag 1'
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <CustomTextFieldNews
                      type={'text'}
                      name="tag2"
                      control={control}
                      defaultValue=""
                      label='Tag 2'
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomTextFieldNews
                      type={'text'}
                      name="frame"
                      control={control}
                      defaultValue=""
                      label='Youtube Frame'
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={2}>
                  <Typography className='confadd_description'>
                      Upload Date*:
                    </Typography></Grid>
                    <Grid item xs={6}>
                    <NewsDate
                      id={""}
                      name="date"
                      control={control}
                      defaultValue={currentdate}
                      rules={{ required: "This field is required" }}
                    />
                  </Grid>

                  <Grid item xs={12} container alignItems="center">
                    <Grid item xs={12} sm={2}>
                      <Typography className='confadd_description'>
                      Upload File*:
                    </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <input
                        hidden
                        id="fileInput"
                        type="file"

                        {...register('image')}
                        onChange={handleFileSelect}
                        ref={fileInputRef}
                      />
                      <button onClick={handleButtonClick}>Choose your Image</button>

                      {selectedFile ? (
                        <span>{selectedFile.name}</span>
                      ) : (
                        <span>No file choosen</span>
                      )}


                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" fullWidth className="form-button">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid></Grid></Grid>
  );
};

export default AddNews;