/*
  Home Screen Top Section
  
  This section includes a Register button.
  Event descriptions are mentioned here.

  Developer: Athul P P
*/
import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import CountdownTimer from "./CountTimer";
import { useNavigate } from "react-router-dom";

const HomeContainer = () => {
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    // Navigate to another page upon button click
    navigate("/registration"); // Replace '/your-target-route' with the actual route you want to navigate to
  };
  const handleDownload = (name: any) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = '/Assets/6cme.pdf' 
    downloadLink.download = name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

  return (
    <Grid className="home_container">
      <Grid className="home_content">
        <div className="scrolling-text">
         
        <Typography className="scrolltext" onClick={() => handleDownload('6cme.pdf')} style={{ cursor: 'pointer' }} >
        Due to overwhelming request from students, we are pleased to annouce a reduced rate for students.  Kindly upload your College ID with photo OR bonafide certificate with Photo from your HOD to avail the special rate </Typography>
        </div> 
        <Typography className="home_heading1">KATCON 2024</Typography>
        <Typography className="home_heading2">
          Indian Society of Anaesthesiologists
        </Typography>
        <Typography className="home_heading2">Thrissur City Branch</Typography>
        <div className="program_details">
          <a href="" className="program">
            Conference: August 25th , 2024
          </a>
          {/* <a href="" className="program">
            Workshop: 9th February, 2024
          </a> */}
          <br />
          <a target="_blank" className="program">
            Venue: Govt. Medical College,Thrissur
          </a>
        </div>
        <Grid>
        <Button
          id="reg_btn"
          className="home_reg_btn pulse-button"
       
        >
          <Typography className="home_reg_text" id="reg_text_btn">Register</Typography>
        </Button></Grid>
        <Grid >
        <Typography className="scrolltext">
          Registration will close on 24 August at 2:00 PM.<br/> No Spot Registration will be available. <br />
          Please make sure to register before the deadline. </Typography>
        </Grid>
        <CountdownTimer targetDate={"2024-02-08T23:59:59"} />       
      </Grid>
    </Grid>
  );
};

export default HomeContainer;
