/*
  Home Screen Event Description Section
  
  This section includes Event detailed Info

  Developer: Athul P P
*/
import { Alert, Grid, Typography } from "@mui/material";
import React from "react";

const HomeContainer2 = () => {
  return (
    <Grid>
    
    <Grid className="home_container2">
   
      <Grid container style={{marginTop:"30px"}}>
        <Grid item xs={12} sm={12} md={12} className="workshop_last">
          <Typography className="font_style">
          Dear Colleagues,
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="home_container_para">
          <Typography className="font_style">Welcome to KATCON 2024!</Typography><br/>
          <Typography className="font_style">
          {/* We welcome you to the upcoming KATCON2024(2nd Edition) , held on the  25th August 2024 at Govt. Medical College, Thrissur , organised by Indian Society of Anaesthesiologists, Kerala State Chapter & hosted by ISA Thrissur City Branch . <Typography/> */}
          The Indian Society of Anaesthesiologists (ISA), Thrissur, in association with the Department of Anaesthesia, Government Medical College, Thrissur, is proud to present the 2nd edition of KATCON 2024, South India's premier conference and training program for OT and Anesthesia technicians.
           <br/><Typography className="font_style">
           We invite all anesthesia and OT technicians to join us on August 25th, 2024, at the Government Medical College, Thrissur, for a day of learning, growth, and networking. This event is designed to enhance your skills, update your knowledge, and improve patient care.<br/>
           We also extend a special invitation to all anesthesiologists to encourage and send their technicians to KATCON 2024. Your support in empowering your team will have a direct impact on the quality of patient care.
           Join us at KATCON 2024 and be a part of shaping the future of anesthesia and OT care in India. Register now and take the first step towards excellence in patient care! <br/><br/>Kindly do register and gain what we expect from you
          {/* <br/><br/>Venue:  Alumini Hall, Government Medical College, Thrissur
          <br/>Date:  25th August 2024 */}
</Typography><br/>
{/* <Typography className="font_style"> We also invite free paper and E-posters from both practicing anaesthesiologists and postgraduates. Kindly send your abstracts to <b>abstract@isathrissur.com</b> and posters to <b>poster@isathrissur.com</b>.  The last date of submission of abstracts is 26th January 2024.There is a postgraduate quiz with attractive prizes for the winners.(Teams should consist of 2 members).</Typography><br/> 

The 2nd Dr.Raghavan Memorial Gold Medal for Best thesis from KUHS will be awarded.
Interested candidates may sent a brief abstract of your completed thesis(Accepted by the University) to the above email. */}

</Typography>
<Typography className="font_style"> With warm regards</Typography>
<Typography className="font_style"> KATCON Organising Team</Typography>
        </Grid>
      </Grid>
      {/* <Grid container className="center_align">
        <Grid xs={12} md={12} sm={12}>
          <Typography className="font_style_header">WORKSHOP ON</Typography>
        </Grid>
        <Grid container xs={12} md={12} sm={12} className="home_workshop_ct">
          <Grid item xs={12} md={4} sm={6} className="align_fontStart">
            <Typography className="font_style">
              ➤ Simulation based learning
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sm={6} className="align_fontStart">
            <Typography className="font_style">➤ Usg guided RA </Typography>
          </Grid>
          <Grid item xs={12} md={4} sm={6} className="align_fontStart">
            <Typography className="font_style">
              ➤ Critical care in a nutshell
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} sm={12} className="align_fontStart">
            <Typography className="font_style">➤ Ventilation</Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={12} sm={12} className="workshop_last">
          <Typography className="font_style">
            Case discussions covering most scenarios conducted by experts from
            across the country, poster and podium presentations, PG quiz and
            much more...
          </Typography>
        </Grid>
      </Grid> */}
    </Grid>
    </Grid>
  );
};

export default HomeContainer2;
