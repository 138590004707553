import React,{useEffect,useRef} from 'react';
import { Controller} from 'react-hook-form';
import { Grid, Select,InputLabel,FormHelperText } from '@mui/material';
import './Registrationstyles.scss'; // Import the SCSS file

interface FieldProps {
  control: any;
  defaultValue: any;
  name: string;
  icon?: React.ReactNode; // Make icon optional by adding '?'
  type?: 'text' | 'select'; // Define the type of field ('text' or 'select')
  children?: React.ReactNode; // Children for select field
  selectWidth?: string; // Custom width for select field
  label:string
  rules:any
  id:any;
  
}

// Inside FormField component
const FormField = ({ control, name, defaultValue, icon, type = 'text', children, label, rules,id}: FieldProps) => {
  const selectRef = useRef(null);

 
  
 
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) => ( 
        <div >
        <Grid className="inputContainer">
          {icon && <Grid className="iconWrapper">{icon}</Grid>}
          {type === 'text' ? (
            <>
              <input
                {...field}
                type="text"
                id={id}
                className="inputWithIcon"
                placeholder={label}
                required
              />
            
            </>
          ) : (
            <>
              <select
                {...field}
                className="customSelect"
                id={id}
                name={label}
                required
                autoFocus={fieldState.error?true:false}
              >
                 <option value="">Select</option>
                {children}
              </select>
            
            </>
          )}
        </Grid>
        <FormHelperText error={!!fieldState.error}>
                {fieldState.error?.message}
              </FormHelperText>
        </div>
      )}
    />
  );
};

export default FormField;
