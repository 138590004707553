// Import necessary dependencies from React, react-hook-form, and Material-UI
import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
// Define the properties for CustomTextField
interface CustomTextFieldnewsProps {
  name: string;
  control: any; 
  defaultValue:any;
  variant:any;
  label:string;// replace 'any' with the actual type for control
  type:any;
}
// Define the CustomTextField component
const CustomTextFieldNews: React.FC<CustomTextFieldnewsProps> = ({ name, control, defaultValue,variant,label,type }) => {
  return (
     // Use the Controller component from react-hook-form
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
           // Render a Material-UI TextField with specified properties
        <TextField {...field} type={type} label={label} variant={variant} InputLabelProps={{ shrink: true }} fullWidth className='conferenceadd-textfield'/>
      )}
    />
  );
};

export default CustomTextFieldNews;
