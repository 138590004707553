import apiClient from '../../API/API-client';
/**
 * @author Neethu
 * Api Service registration create and update api
 */
class RegistrationApiService {
  private static instance: RegistrationApiService;

  private constructor() { }

  static getInstance(): RegistrationApiService {
    if (!RegistrationApiService.instance) {
      RegistrationApiService.instance = new RegistrationApiService();
    }
    return RegistrationApiService.instance;
  }
  /**
 * Calling create api
 * @param memberData 
 * @param isISANumber 
 * @param totalfee 
 * @param WorkshopName 
 * @param CategoryName 
 * @param type 
 * @param role 
 * @param selectedFileId (optional)
 * @returns 
 */
async registerMember(memberData: any, isISANumber: any, totalfee: any, WorkshopName: any, CategoryName: any, type: any, role: any, selectedFileId: any = null) {
  try {
    memberData = {
      "isa_member": isISANumber ? 1 : 0,
      "prefix": memberData.prefix ? memberData.prefix : "",
      "firstname": memberData.firstName,
      "middlename": memberData.middleName,
      "lastname": memberData.lastName ? memberData.lastName : "",
      "name": memberData.badge ? memberData.badge : memberData.firstName + " " + memberData.lastName,
      "email": memberData.email,
      "address": memberData.address,
      "city": memberData && memberData.city && memberData.city.value,
      "state": memberData.state,
      "country": memberData.country,
      "pincode": memberData.pincode,
      "age": memberData.dob,
      "registration_no": isISANumber ? memberData.isanumber : "",
      "food_type": memberData.dietPreference,
      "company_id": 1,
      "conference_id": 2,
      "workshop_id": WorkshopName ? WorkshopName : "NO",
      "institute": memberData.institute,
      "designation": memberData.designation,
      "mcino": memberData.regnumber,
      "aadharno": memberData.aadharNumber,
      "type_of_practice": memberData.typeofpractice,
      "interest": role,
      "activity": memberData.performanceoption ? memberData.performanceoption : "NO",
      "role": "USER",
      "type": type == 1 ? "conference+workshop " : "conference",
      "phone": memberData.mobile,
      "gender": memberData.gender,
      "category": CategoryName,
      "amount": totalfee,
      "tshirt_size": memberData.tshirt_size,
      "poster": "no",
      "abstract": "no",
      "document_id": selectedFileId
    };

    
    const response = await apiClient.post('member/register', memberData);
    return response.data;
  } catch (error) {
    console.error('Error registering member:', error);
    throw error;
  }
}


  /**
   * Calling Update API
   * @param paymentData 
   * @param totalfee 
   * @returns 
   */
  async updatePaymentRegistration(paymentData: any, totalfee: any, payid: any) {
    try {

      let paymentreq = {
        "id": paymentData.attendee_id,
        "amount": Number.parseFloat(totalfee),
        "status": "REGISTERED",
        "payment_status": "PAID",
        "pay_ref": payid,
        "pay_mode": "Success"
      }

      const response = await apiClient.put('attendee/update/' + paymentData.attendee_id, paymentreq);
      return response.data;
    } catch (error) {
      console.error('Error registering member:', error);
      throw error;
    }
  }
  /**
 * Calling Update error API
 * @param paymentData 
 * @param totalfee 
 * @returns 
 */
  async updatePaymentError(paymentData: any, payerror: any) {
    try {
     
      if (paymentData.payment_status != 'PAID') {
        let paymentreq = {
          "id": paymentData.attendee_id,
          "status": "REGISTERED",
          "payment_status": "PENDING",
          "pay_mode": payerror
        }
        const response = await apiClient.put('attendee/update/' + paymentData.attendee_id, paymentreq);
        return response.data;
      }
    } catch (error) {
      console.error('Error registering member:', error);
      throw error;
    }
  }
}
const registerApiService = RegistrationApiService.getInstance();

export default registerApiService;