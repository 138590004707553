import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import HomePage from "./Components/Homescreen/Homepage";
import ConferenceRegForm from "./Components/Registration/conference-reg";
import SuccessPage from "./Components/SuccessPage/SuccessPage";
import AmountDetails from "./Components/Registration/AmountDetails";
import RegistrationDone from "./Components/SuccessPage/RegistrationDone";
import AdminHomePage from "./Components/Admin/Homescreen/Homepage";
import MemberList from "./Components/Admin/Memberlist";
import LoginPage from "./Components/Admin/Login/LoginPage";

import ContactUs from "./Components/ContactUs/ContactUs";
import Header from "./Components/Common/Header";
import Footer from "./Components/Common/Footer";
import Dashboard from "./Components/Admin/Homescreen/Dashboard";
import AttendeesList from "./Components/Admin/Attendeeslist";
import AddNews from "./Components/Admin/AddNews/AddNews";
import AddGallery from "./Components/Admin/AddGallery/AddGallery";
import AuthRoute from "./Components/AuthRoute";
import PrivacyPolicy from "./Components/PrivacyPolicy.tsx/PrivacyPolicy";
import Addvolunteer from "./Components/Admin/Registration/Addvolunteer";
import TermsandCondition from "./Components/TermsandCondition/TermsandConditions";
import WorkshopList from "./Components/Admin/WorkshopList";
import WorkshopForm from "./Components/Admin/Registration/Workshopadd";
import PaymentError from "./Components/SuccessPage/PaymentError";
import MemberDetails from "./Components/Admin/MemberDetails";
import RetryPayment from "./Components/Registration/RetryPayment";
import RegistrationForm from "./Components/Admin/Registration/conference-add";
import AdminRegistrationForm from "./Components/Admin/AdminRegistration";
import AdminAmountDetails from "./Components/Admin/AdminAmountDetails";
import AdminRegistrationDone from "./Components/Admin/AdminRegistrationDone";
import EditMember from "./Components/Admin/EditMember/EditMember";
import NewsList from "./Components/Admin/AddNews/NewsList";
import UpdateNews from "./Components/Admin/AddNews/UpdateNews";
import SampleAbstract from "./Components/Registration/SampleAbstract";
import NewsViewPage from "./Components/Homescreen/components/newsViewPage";
import WorkshopEdit from "./Components/Admin/Registration/WorkshopUpdate";
import EditMemberKATCON from "./Components/Admin/EditMember/EditMemberKATCON";


const HeaderRoutes: React.FC = () => {
  const location = useLocation();

  // Check if the current location is the login page
  const isLoginPage =
    location.pathname === "/login" ||
    location.pathname === "/" ||
    location.pathname == "/logout";

  // location.pathname == "/home";
  const isAdminPage = location.pathname.includes("/isaadmin");
  const isTermsPage = location.pathname.includes("/TermsofService");
  const isPrivacyPage = location.pathname.includes("/Privacy");
  //console.log(isTermsPage);
  return (
    <>
      {!isAdminPage && !isTermsPage && !isPrivacyPage &&<Header/>}
      {/* <div style={{ paddingTop: "100px" }}> */}
      <Routes>
        <Route path="/registration" element={<ConferenceRegForm />} />
        <Route path="/successpage" element={<SuccessPage />} />
        <Route path="/amountdetails" element={<AmountDetails />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/done" element={<RegistrationDone />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsandCondition/>} />
        <Route path="/paymentfailure" element={<PaymentError/>} />
        <Route path="/payment" element={<RetryPayment/>} />
        <Route path="/sampleabstract" element={<SampleAbstract/>} />
        <Route path="/newsitem" element={<NewsViewPage/>} />

        {/* add admin route here */}
        <Route path="/isaadmin" element={<LoginPage />} />
        {/* <Route path="/isaadmin/dashboard" element={<AdminHomePage />} /> */}
        <Route path="/isaadmin/memberlist" element={<AuthRoute element={<MemberList/>}/>} />
       
        <Route path="/isaadmin/dashboard1" element={<AuthRoute element={<Dashboard/>}/>} />
        <Route path="/isaadmin/attendeeslist"  element={<AuthRoute element={<AttendeesList/>}/>} />
        <Route path="/isaadmin/addnews" element={<AuthRoute element={<AddNews/>}/>}/>
        <Route path="/isaadmin/addgallery" element={<AuthRoute element={<AddGallery/>}/>}/>
        <Route path="/isaadmin/workshoplist" element={<AuthRoute element={<WorkshopList/>}/>}/>
        <Route path="/isaadmin/workshopadd" element={<AuthRoute element={<WorkshopForm/>}/>}/>
        <Route path="/isaadmin/addvolunteer" element={<AuthRoute element={<Addvolunteer/>}/>}/>
        <Route path="/isaadmin/memberdetails" element={<MemberDetails/>}/>
        <Route path="/isaadmin/adminregistration" element={<AuthRoute element={<AdminRegistrationForm/>}/>}/>
        <Route path="/adminamountdetails" element={<AuthRoute element={<AdminAmountDetails/>}/>}/>
        <Route path="/admindone" element={<AuthRoute element={<AdminRegistrationDone/>}/>}/>
        <Route path="/isaadmin/editdetails" element={<AuthRoute element={<EditMember />} />}/>
        <Route path="/isaadmin/editdetails_katcon" element={<AuthRoute element={<EditMemberKATCON />} />}/>
       
        <Route path="/isaadmin/newslist" element={<AuthRoute element={<NewsList/>}/>}/>
        <Route path="/isaadmin/updatenews" element={<AuthRoute element={<UpdateNews/>}/>}/>
        <Route path="/isaadmin/workshopedit" element={<AuthRoute element={<WorkshopEdit/>}/>}/>
      
      </Routes>
      {/* </div> */}
     
      {!isAdminPage && !isTermsPage && !isPrivacyPage &&<Footer/>}
    </>
  );
};

const AppRouter: React.FC = () => {
  return (
    <Router>
      <HeaderRoutes />
    </Router>
  );
};

export default AppRouter;