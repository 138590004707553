import { Download } from '@mui/icons-material';
import apiClient from '../../API/API-client';

class NewsListAPIService{
    private static instance: NewsListAPIService;

    private constructor() {}
  
    static getInstance(): NewsListAPIService {
      if (!NewsListAPIService.instance) {
        NewsListAPIService.instance = new NewsListAPIService();
      }
      return NewsListAPIService.instance;
    }

    
      async NewsListAPI() {

        try{
            let dataRequest=   {
      
                "company_id": 1  
               
                
            }
            
            const response = await apiClient.post('admin/listnews', dataRequest);
            return response.data;
          } catch (error) {
            console.error('Error listing news:', error);
            throw error;
          }
        }



        async NewsListTagAPI(newsData:any) {

          try{
              let dataRequest=   {
        
                  "company_id": newsData.company_id,
                  "tag":newsData.tag1
                  // ...(newsData?.tag1&& { tag1:newsData?.tag1  }),
                  // ...(newsData?.tag2&& { tag2:newsData?.tag2  }),
                 
                  
              }
              const response = await apiClient.post('admin/listnews', dataRequest);
              return response.data;
            } catch (error) {
              console.error('Error listing news:', error);
              throw error;
            }
          }
      
     
    
  }
  
    const newsListAPIService = NewsListAPIService.getInstance();
    
    export default newsListAPIService;