// Import React and necessary components and styles
import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Grid, Drawer, Container, Typography } from '@mui/material';
import SidebarDesktop from './Common/Sidebar';
import adminApiService from '../../core/service/AdminApiService';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import '../Admin/Registration/addstyles.scss';
import '../Admin/Common/common.scss';
import '../Admin/Homescreen/dashboard.scss'
import moment from 'moment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import SearchForm from './Common/Searchform';
import { SubmitHandler } from 'react-hook-form';

import downloadApiService from '../../core/service/DownloadApiService';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import HeaderWithLogout from './Common/Headerdesktop';
import SidebarToggle from './Common/SidebarToggle';
import PrintIcon from '@mui/icons-material/Print';
import MailIcon from '@mui/icons-material/Mail';
// Define interfaces for form data and member structure
interface FormData {
  membername: string;
  memberemail: string;
  member_regno: any;
  conference_name: string;
}
interface Member {
  id:number;
  serialNumber: number;
  prefix: string;
  firstname: string;
  middlename: string;
  lastname: string;
  designation: string;
  institute: string;
  mcino: string;
  dob: string;
  name: string;
  gender: string;
  email: string;
  phone: number;
  address: string;
  country: string;
  state: string;
  city: string;
  pincode: string;
  attendees: Attendee[];
  
  tshirt_size: string;
  category: string;
  registration_no: string;



  

  // Define attendee properties here
}
interface Attendee {
  type_of_practice: string;
  food_type: string;
  activity: string;
  workshop_id: string;
  interest: string;
  poster: string;
  abstract: string;

  // You can adjust the type of typeofPractice based on your actual data type
}
const MemberList: React.FC = () => {
  const handleSearch: SubmitHandler<FormData> = (data: any) => {
    // Handle the form data in the MemberList component

    fetchmemberData(data)
    // You can perform additional logic based on the form data
  };
  // State variables
  const [isSidebarOpen, setSidebarOpen] = useState(false);  // State to track sidebar visibility
  const [members, setmemberData] = useState<any>([]);
  const [shouldExport, setshouldExport] = useState(false);
  const [downloadmembers, setdownloadmemberData] = useState<any>([]);
  const [editedPaymentStatus, setEditedPaymentStatus] = useState<any>('');
  const [editedRowId, setEditedRowId] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  // React Router hook for navigation
  const navigate = useNavigate();

  // Handle the edit action
  const handleEdit = (rowId: any, initialPaymentStatus: any) => {
    setEditedPaymentStatus(initialPaymentStatus);
    setEditedRowId(rowId);
  };
  // Handle the save action
  const handleSave = async (rowsData: any, editedData: any) => {
    const Name = `${rowsData?.firstname} ${rowsData?.lastname}`;
    const confirmed = window.confirm("Do you want to change the payment_status of " + Name + "?");
    if (confirmed) {
      const resp = await adminApiService.EditPaymentstatus(rowsData, editedData);
      if (resp.status == "success") {
        // After updating, clear the edited state
        setEditedPaymentStatus('');
        setEditedRowId(null);
        fetchmemberData("");
      }
      else {
        alert(resp.message);
      }
      // Optionally, you can fetch the updated data again
    }
  };
  // useEffect to handle the export when downloadmembers state is updated
  useEffect(() => {


    // You can perform any asynchronous operations here, such as data fetching
    fetchmemberData("");


  }, []);
  //download api integration
  const downloadmemberData = async (searchdata: any) => {

    // Call the registerMember method and pass the member data
    const resp = await downloadApiService.Download(searchdata);


    if (resp.data) {
      const dataWithSerialNumbers = resp?.data?.map((row: any, index: any) => ({
        ...row,
        serialNumber: index + 1,
      }));

      setdownloadmemberData(dataWithSerialNumbers);
    }

  }
  //memberlist api call
  const fetchmemberData = async (searchdata: any) => {
    try {
      setLoading(true);
      // Call the registerMember method and pass the member data
      const resp = await adminApiService.memberList(searchdata, paginationModel);



      if (resp.data) {
        const dataWithSerialNumbers = resp?.data?.map((row: any, index: any) => ({
          ...row,
          serialNumber: index + 1,
        }));

        setmemberData(dataWithSerialNumbers);
      }
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error during data fetch:", error);
      setLoading(false); // Set loading to false in case of an error
    }

  }

  const sendMailReminder = async (rowData: any) => {


    const email = rowData?.email
    const confirmed = window.confirm("Do you want to send  the payment reminder email to " + email + "?");
    if (confirmed) {

      try {
        const resp = await adminApiService.SendPaymentReminder(rowData);
        if (resp.status == "success") {
          fetchmemberData("");
        }
      } catch (error) {
        console.error("Error during data fetch:", error);

      }

    }

  }





  const columns: GridColDef[] = [
    {
      field: 'serialNumber',
      headerName: 'Sl No',
      width: 70,
      headerClassName: 'headergridcell_blue1',
      cellClassName: 'gridcolumn-cells',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
     {
      field: 'member_id', headerName: 'ID', width: 80, headerClassName: 'headergridcell_blue1',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",

      sortable: false,
      renderCell: (params) => {
        const id = `${params.row?.id}`; // Combine name and name2 fields
          return id;
        
      }
    },
    // {
    //   field: 'id', headerName: 'RegNO', width: 110, headerClassName: 'headergridcell_blue1 black_headertext',
    //   cellClassName: "gridcolumn-cells",
    //   headerAlign: "center",

    //   sortable: false,
    //   renderCell: (params) => {
        
    //     let reg_No;
    //     if(params.row?.attendees && params.row.attendees[0].conference_id==2){
    //       reg_No = `${'KATCON'}${params.row?.id}`; // Combine name and name2 fields
    //     }
    //     else
    //     {
    //       reg_No = `${'ISACON'}${params.row?.id}`; 
    //     }
    //     if (reg_No) {
    //       return reg_No

    //     }
    //     else {
    //       return null
    //     }
    //   },

    // },
    // {
    //   field: 'registration_no', headerName: 'ISA Reg-NO', width: 100, headerClassName: 'headergridcell_blue1',
    //   cellClassName: "gridcolumn-cells",
    //   headerAlign: "center",

    //   sortable: false,
    // },
    {
      field: 'namefield', headerName: 'Name', width: 160, headerClassName: 'headergridcell_blue1',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",

      sortable: false,
      renderCell: (params) => {
        const fullName = `${params.row?.firstname} ${params.row?.lastname}`; // Combine name and name2 fields

        if (fullName) {
          return fullName

        }
        else {
          return null
        }
      },
    },
    {
      field: 'email', headerName: 'Email', width: 200, headerClassName: 'headergridcell_blue1',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",

      sortable: false,
    },
    {
      field: 'phone', headerName: 'Contact', width: 90, headerClassName: 'headergridcell_blue1',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",

      sortable: false,
    },
    {
      field: 'category', headerName: 'Type', width: 100, headerClassName: 'headergridcell_blue1',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",

      sortable: false,
    },
    // {
    //   field: 'type', headerName: 'Category', width: 90, headerClassName: 'headergridcell_blue1',
    //   cellClassName: "gridcolumn-cells",
    //   headerAlign: "center",

    //   sortable: false,
    //   renderCell: (params) => {
    //     if (params?.row?.attendees[0]) {
    //       return (
    //         <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', textAlign: 'center' }}>
    //           {params?.row?.attendees[0].type}
    //         </div>
    //       )

    //     }
    //     else {
    //       return null
    //     }

    //   },
    // },
    {
      field: 'date', headerName: 'Date', width: 110, headerClassName: 'headergridcell_blue1 black_headertext',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",

      sortable: false,
      renderCell: (params) => {
        //console.log(params.row.attendees[0]);
        if (params?.row?.date) {
          const originalDate = params?.row?.attendees[0].created_on;
          const formattedDate = moment(originalDate).format("YYYY-MM-DD hh:mm");
          return formattedDate

        }
        else {
          return null
        }

      },

    },
    {
      field: 'amount', headerName: 'Amount', width: 80, headerClassName: 'headergridcell_blue1',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",

      sortable: false,
      renderCell: (params) => {
        if (params?.row?.attendees[0]) {
          return params?.row?.attendees[0]?.amount

        }
        else {
          return null
        }
      }

    },
    {
      field: 'paymentstatus',
      headerName: 'Payment-Status',
      width: 150,
      headerClassName: 'headergridcell_blue1',
      cellClassName: 'gridcolumn-cells',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        const rowData = params.row;
        const rowId = params.row.id;
        const initialPaymentStatus = params?.row?.attendees[0]?.payment_status;

        if (params?.row?.attendees[0]?.payment_status) {
          // Check if the cell is being edited
          if (editedRowId === rowId) {
            // Render an input field for editing payment status
            return (
              <Grid container xs={12} sm={12} className='header-with-logout-container'>
                <Grid item xs={7}>
                  <select
                    value={editedPaymentStatus}
                    className="payment-status-select"
                    onChange={(e) => setEditedPaymentStatus(e.target.value)}
                  >
                    <option value="PAID">PAID</option>
                    <option value="PENDING">PENDING</option>
                  </select>
                </Grid>
                <Grid item xs={4}>
                  <button className='button_stylesave' onClick={() => handleSave(rowData, editedPaymentStatus)}>Save</button>
                </Grid>
              </Grid>

            );
          }

          else {
            // Render the payment status text with the edit button
            return (
              <Grid container xs={12} sm={12} className='header-with-logout-container'>
                <Grid item xs={8} >
                  <Typography className={params?.row?.attendees[0]?.payment_status === 'PENDING' ? 'redTextGrid' : 'greenTextGrid'}> {params.row.attendees[0].payment_status}</Typography></Grid>
                <Grid item xs={4}>
                  <button className='button_style2' onClick={() => handleEdit(rowId, initialPaymentStatus)}><EditIcon className='viewiconstyle' /></button>
                </Grid></Grid>
            );
          }
        }

      },
    },
    {
      field: 'edit',
      headerName: 'Edit/Delete',
      width: 230, headerClassName: 'headergridcell_blue1',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params: any) => {
        //handle view function
        const handleView = (rowData: any) => {
         
          sessionStorage.setItem('rowData', JSON.stringify(rowData));
          // Open a new tab
          const url = new URL('/isaadmin/memberdetails', window.location.origin);
          window.open(url.toString(), '_blank');
         // navigate('/isaadmin/memberdetails', { state: rowData })
        }
        const handleEdit = (rowData: any) => {
         
         // navigate('/isaadmin/editdetails', { state: rowData })
         sessionStorage.setItem('editrowData', JSON.stringify(rowData));
         // Open a new tab
         let url ;
         if(rowData && rowData.attendees && rowData.attendees[0].conference_id!=2)
         url= new URL('/isaadmin/editdetails', window.location.origin);
         else
         url= new URL('/isaadmin/editdetails_katcon', window.location.origin);

         window.open(url.toString(), '_blank');
        }
        // const handlePrint = (rowData: any) => {
        //   navigate('/isaadmin/memberdetails', { state: rowData })
        // }
        const handleMailReminder = (rowData: any) => {
          sendMailReminder(rowData)

        }
        //handle delete function
        const handleDelete = async () => {
          let Attendee_ID = params.row.attendees[0].id;

          let Person_ID = params.row.id;
          const Name = `${params.row?.firstname} ${params.row?.lastname}`;
          const confirmed = window.confirm("Do you want to delete the record of " + Name + "?");
          if (confirmed) {
            const resp = await adminApiService.memberDelete(Attendee_ID, Person_ID);

            if (resp.status == "success") {
              window.location.reload();
            }

          }
        }
        return (
          <Grid container spacing={1} alignContent={'flex-start'} style={{ padding: '8px', marginLeft: '8px' }}>

            <Grid item>
              <button className='button_style_view' onClick={() => handleView(params.row)}><VisibilityIcon className='viewiconstyle' /></button>
            </Grid>

            <Grid item>
              <button className='button_style' onClick={() => handleEdit(params.row)}><EditIcon className='viewiconstyle' /></button>
            </Grid>
            <Grid item >
              <button className='button_style1' onClick={() => handleDelete()}><DeleteIcon className='viewiconstyle' /></button>
            </Grid>
            {params?.row?.attendees[0]?.payment_status === "PENDING" &&
              <Grid item>
                <button className='button_style' onClick={() => handleMailReminder(params.row)}><MailIcon className='viewiconstyle' /></button>
              </Grid>
            }
          </Grid>
        )
      }
    },
  ];


  const handleDownloadClick = async (formData: any) => {



    try {
      // Set a flag to indicate that export is in progress
      setshouldExport(true);

      // Trigger the fetch of member data
      await downloadmemberData(formData);
    } catch (error) {
      console.error("Error during data fetch:", error);
    }
  };

  // useEffect to handle the export when downloadmembers state is updated
  useEffect(() => {
    // Define file-related details
    if (shouldExport && downloadmembers.length > 0) {
      const currentDate = new Date();
      const dateOnly = currentDate.toISOString().split('T')[0];
      const fileName = `ConferenceMemberDetails_${dateOnly}`;

      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';

      // Create a new worksheet with rearranged columns
      const ws = XLSX.utils.json_to_sheet(downloadmembers.map((member: Member) => ({
        'Serial Number': member.serialNumber,
        'ID':member.id,
        'Prefix': member.prefix,
        ' First Name': member.firstname,
        'Middle Name': member.middlename,
        'Last Name': member.lastname,
        'Designation': member.designation,
        'Institute': member.institute,
        'State/MCI Registration No': member.mcino,
        'DOB': member.dob,
        'Name on Badge': member.name,
        'Gender': member.gender,

        'Email': member.email,
        'Registration No': member.registration_no,

        'Phone Number': member.phone,
        'Address': member.address,
        'Country': member.country,
        'State': member.state,
        'City': member.city,
        'Pin Code': member.pincode,
        'Type of Practice': member.attendees[0]?.type_of_practice,
        'Type of Position': member.attendees[0].interest,
        'Poster': member.attendees[0]?.poster,
        'Abstract': member.attendees[0]?.abstract,
        'Diet Preference': member.attendees[0]?.food_type,
        'Cultural Activity': member.attendees[0]?.activity,
        'T-shirt size': member.tshirt_size,
        'Category': member.category,
        'Workshop': member.attendees[0]?.workshop_id,





      })));



      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      // Save the file using FileSaver.js
      FileSaver.saveAs(data, fileName + fileExtension);

      // Reset the shouldExport flag
      setshouldExport(false);
    }
  }, [downloadmembers, shouldExport]);


  // Define DataGrid row id retrieval function

  const getRowId = (row: any) => {
    if (row.id) {
      return row.id;
    }
    // Return a fallback value if the property is not available
    return '';
  };
  // Define DataGrid row style function
  const getRowStyleParams = (params: any) =>
    params.indexRelativeToCurrentPage % 2 === 0 ? 'bg_grey_TableCell ' : 'bg_white_TableCell';
  const getRowHeight = () => 40;
  return (
    <Grid className="dashboard-container">
      <Grid container justifyContent="center">
        <Grid item xs={1} className='sidebar'>
          <SidebarToggle />
        </Grid>

        <Grid item xs={11} className='content-container'>
          <Grid className='conferenceSummaryContainer'>
            <HeaderWithLogout title="Dashboard/Member Listing" icon={<DashboardIcon className='dashboard-icon' />} /></Grid>
          <Grid xs={12} className='dash_paper1'>
            <SearchForm onSubmit={handleSearch}
              handleDownloadClick={handleDownloadClick} />

          </Grid>
          <Grid className='searchformpad'>
            <Typography className='dashboard_title'>Registered Members</Typography>
            {/* </Grid> */}
            <Grid className='dashmainContent1'>
              {loading ? (
                <div className='loadingtext'>Loading...</div>
              ) : (
                <DataGrid
                  rows={members ? members : []}
                  columns={columns}
                  getRowId={getRowId}
                  getRowClassName={getRowStyleParams}
                  autoHeight
                  getRowHeight={() => "auto"}
                  localeText={{ noRowsLabel: "No data available" }}
                  disableColumnMenu={true}
                  initialState={{
                    ...members.initialState,
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[10, 25, 50, 75, 100]}
                />
              )}
            </Grid>
          </Grid>



        </Grid>
      </Grid></Grid>

  );
}

export default MemberList;
