import {
    Grid, Typography, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Paper,
    Button
} from "@mui/material";
import React from "react";
import RegHeader from "./registration_header";
import { useLocation } from 'react-router-dom';
import Footer from "../Common/Footer";
import { useNavigate } from "react-router-dom";
import registerApiService from "../../core/service/RegistrationAPIService";
import RazorpayService from "../../core/service/RazorpayService";

let amountdata: any
const AmountDetails: React.FC = () => {
    const [loading, setLoading] = React.useState(false);
    const [paymentfailure, setPaymentfailure] = React.useState(false);
    const location = useLocation();
    if (location && location.state) {
        amountdata = location.state
    }
    const navigate = useNavigate();
    const handlePaymentFailure = async (details:any,errorCode:any) => {
        
      //  console.log('Payment failure! Navigating to failure page...');
        setPaymentfailure(true);
        const response = await registerApiService.updatePaymentError(details.resp.data,errorCode);
           
        // Navigate to the failure page
        // navigate('/paymentfailure', { state: amountdata.totalfee });
    };
    const backToHome = () => {
        navigate('/');
    }
    const handleCheckoutClick = async () => {
        setLoading(true);
        const responseFromRazorpay = await RazorpayService.displayRazorpay(amountdata, amountdata.totalfee, handlePaymentFailure); //totalfee
        // Navigate to another page upon button click
        
        if (responseFromRazorpay.razorpay_payment_id) {
            
            const response = await registerApiService.updatePaymentRegistration(amountdata.resp.data, amountdata.totalfee,responseFromRazorpay.razorpay_payment_id);
            setLoading(false);
            if (response.status == "success") {
                navigate('/done', { state: {
                    totalFee: amountdata.totalfee,
                    razorpayDetails: {
                      paymentId: responseFromRazorpay.razorpay_payment_id     
                    },
                  }, });
            } else {

                navigate('/paymentfailure', { state: amountdata.totalfee });
                alert("Your Payment is not completed..Please contact admin@isathrissur.com")
            }
        }
        else {
            navigate('/paymentfailure', { state: amountdata.totalfee });
            alert("Your Payment is not completed..Please contact admin@isathrissur.com")
        }
    };

    return (
        <Grid>
            <RegHeader />
            <Grid className="containercheckout">
                <Grid>
                    <h1>CONFERENCE REGISTRATION FORM</h1>
                </Grid>
                <Grid>
                    <h1 className="h1">Details of cost</h1>
                    <Grid spacing={3} className="amountcontainer">
                        <Grid item xs={12} sm={6} md={4}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper} className="amount-table">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Particulars</TableCell>

                                                <TableCell >Amount in INR</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* {amountdata?.CategoryName ?
                                                <TableRow>
                                                    <TableCell>{amountdata?.CategoryName}</TableCell>
                                                    <TableCell>Rs. {amountdata?.selectedAmount}</TableCell>
                                                </TableRow>
                                                : <div></div>} */}
                                            {/* {amountdata?.WorkshopName ?
                                                <TableRow>
                                                    <TableCell>Workshop ({amountdata?.WorkshopName})</TableCell>
                                                    <TableCell>Rs. {amountdata?.selectedWorkshopAmount}</TableCell>
                                                </TableRow>
                                                : <div></div>} */}
                                            {amountdata?.totalfee ?
                                                <TableRow>
                                                    <TableCell>Total Amount</TableCell>
                                                    <TableCell>Rs. {amountdata?.totalfee}</TableCell>
                                                </TableRow>
                                                : <div></div>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid>
                    <br /><br />
                    <h1 className="h1">Bank Account Details</h1>
                    <Grid spacing={3} className="bankdetailscontainer">
                        <Grid item xs={12} sm={6} md={4}>

                            <Typography className="textstyle">Account Name: ISA Thrissur City Branch</Typography>
                            <Typography className="textstyle">IFSC:HDFC0004164</Typography>
                            <Typography className="textstyle">Account No:50200043742992</Typography>

                        </Grid>
                    </Grid>
                </Grid> */}
                <br /><br />
                <Grid  xs={12}  className="payment_button_container"  >
                    <Grid item xs={12} sm={6} md={4} spacing={1} padding={1} >
                        <Button onClick={handleCheckoutClick} className="big-checkout-button" id="checkoutbutton">
                            {paymentfailure == false ? "CHECKOUT " : "Try Again..."}
                        </Button>
                    </Grid>
                    {paymentfailure && (
                        <Grid item xs={12} sm={6} md={4} spacing={1} padding={1}>
                            <Button onClick={backToHome} className="big-failure-button" id="checkoutbutton">
                                Back to Home
                            </Button>
                        </Grid>
                    )}

                </Grid>
            </Grid>
            <br /><br />
        </Grid>

    )
}
export default AmountDetails