import React from 'react';
import { Grid, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import '../../Admin/Common/common.scss';
// Define the properties for CircularProgressBar
interface CircularProgressBarProps {
    value: number; // Value for the progress (0 to 100)
    label: string; // Label for the progress
    classname:any;  
    amount:number;// Classname for additional styling

  }
  
  const CircularProgressBar: React.FC<CircularProgressBarProps> = ({ value, label,classname,amount }) => {
    // Check if the screen width is small
    const isSmallScreen = useMediaQuery('(max-width:600px)');
  
  return (
    <Grid container direction="column" alignItems="center" spacing={isSmallScreen ? 1 : 2}  xs={12} sm={6} md={3} >
       {/* Grid item for the progress circular bar */}
      <Grid item className='progressContainer' >
        
        <CircularProgress
          variant="determinate"
          value={value}
          size={isSmallScreen ? 60 : 130} // Adjust the size based on screen width
          thickness={isSmallScreen ? 3 : 5} 
          className={classname}
        />
         <CircularProgress
          variant="determinate"
          value={100}
          
          size={isSmallScreen ? 60 : 130} // Adjust the size based on screen width
          thickness={isSmallScreen ? 3 : 5} 
          className={`customCircularProgress`}
        />
      </Grid>
       {/* Grid item for the progress number */}
      <Grid item>
      <Typography variant={isSmallScreen ? 'h6' : 'h4'} className={`progressNumber`}>
          {/* {`${Math.round(amount)}`} */}
          {`${amount.toLocaleString()}`}
        </Typography>
      </Grid>
      {/* Grid item for the progress label */}
      <Grid item>
        <Typography className='dash_title1' variant={isSmallScreen ? 'h6' : 'h4'} >{label}</Typography>
      </Grid>
    </Grid>
  );
};


export default CircularProgressBar;
