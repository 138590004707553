import apiClient from '../../API/API-client';

class VolunteerAddApiService {
    private static instance: VolunteerAddApiService;

    private constructor() { }

    static getInstance(): VolunteerAddApiService {
        if (!VolunteerAddApiService.instance) {
            VolunteerAddApiService.instance = new VolunteerAddApiService();
        }
        return VolunteerAddApiService.instance;
    }
    async volunteerDataList(data: any) {
        
        try {
            let volunteerData = {
                "name": data.name,
                "email": data.email,
                "password": data.password,
                "role": "VOLUNTEER",
                "company_id": 1,
                "volunteer_type": data.volunteertype,
                "phone" : data.phone
            }
             
            const response = await apiClient.post('addvolunteer', volunteerData);
            return response.data;
        } catch (error) {
            console.error('Error contacting server:', error);
            throw error;
        }
    }

    async VolunteerListDataList() {
        
        try {
            let volunteerListData = {
                "company_id": 1,
            }
             
            const response = await apiClient.post('listvolunteer', volunteerListData);
            return response.data;
        } catch (error) {
            console.error('Error contacting server:', error);
            throw error;
        }
    }
}

const volunteerAPIService = VolunteerAddApiService.getInstance();

export default volunteerAPIService;
