import React from 'react';
import { DataGrid, GridColDef} from '@mui/x-data-grid';


interface UpcomingeventsProps {
  rows: any[]; // replace 'any[]' with the actual type for your data
}
//defining columns of datagrid
const columns: GridColDef[] = [
  { field: 'name', headerName: 'Conference Name', flex: 1, headerClassName: 'headergridcell_blue1', cellClassName: "gridcolumn-cells", headerAlign: "center", align: "center", sortable: false },
  { field: 'topic', headerName: 'Topic', flex: 1, headerClassName: 'headergridcell_blue1', cellClassName: "gridcolumn-cells", headerAlign: "center", align: "center", sortable: false },
  { field: 'venue', headerName: 'Venue', flex: 1, headerClassName: 'headergridcell_blue1', cellClassName: "gridcolumn-cells", headerAlign: "center", align: "center", sortable: false },
  { field: 'date', headerName: 'StartDate to EndDate', flex: 1, headerClassName: 'headergridcell_blue1', cellClassName: "gridcolumn-cells", headerAlign: "center", align: "center", sortable: false,
  renderCell: (params) => {
    const Startdate = params?.row?.start_date; // Combine name and name2 fields
    const Enddate=params?.row?.end_date;
    const date=`${Startdate} ${'to'} ${Enddate}`;
    if (date) {
      return date;

    }
    else {
      return null
    }
  }, },
  { field: 'no_of_days', headerName: 'No of Days', width:100, headerClassName: 'headergridcell_blue1', cellClassName: "gridcolumn-cells", headerAlign: "center", align: "center", sortable: false },
];
//passing params to other pages through props
const UpcomingDataGrid: React.FC<UpcomingeventsProps> = ({ rows }) => {
  const getRowId = (row: any) => row?.id;
  const getRowHeight = () => 50; // Set the desired row height here
  //style for even and odd rows
  const getRowStyleParams = (params: any) =>
  params.indexRelativeToCurrentPage  % 2 === 0 ? 'bg_grey_TableCell ' : 'bg_white_TableCell';
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      getRowClassName={getRowStyleParams}
      getRowId={getRowId}
      autoHeight
      getRowHeight={getRowHeight}
      localeText={{ noRowsLabel: "No data available" }}
      hideFooter
      hideFooterSelectedRowCount
      disableColumnMenu={true}
      
    />
  );
};

export default UpcomingDataGrid;
