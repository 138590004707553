/*
  Event Venue Carousel Section

  This section displays venue location images in a slider.

  Developer: Athul P P
*/

import React, { useEffect, useState } from "react";
import "../../../Assets/css/carousel.css";

interface Image {
  image: string;
}

interface CarouselProps {
  images: Image[];
}

function Carousel({ images }: CarouselProps): JSX.Element {
  const [current, setCurrent] = useState<number>(0);
  const [autoPlay, setAutoPlay] = useState<boolean>(true);
  let timeOut: number | null = null;

  /*
  useEffect to handle automatic slide transition.
  If autoPlay is true, a timeout is set to trigger the slideRight function
  after 2500 milliseconds. The timeout is cleared on component unmount or when
  autoPlay or current slide index changes.
*/

  useEffect(() => {
    if (autoPlay) {
      timeOut = window.setTimeout(() => {
        slideRight();
      }, 2500);
    }

    return () => {
      if (timeOut !== null) {
        clearTimeout(timeOut);
      }
    };
  }, [autoPlay, current]);

  // to slide images to next index forward
  const slideRight = () => {
    setCurrent((prevCurrent) =>
      prevCurrent === images.length - 1 ? 0 : prevCurrent + 1
    );
  };
  // to slide images to backward
  const slideLeft = () => {
    setCurrent((prevCurrent) =>
      prevCurrent === 0 ? images.length - 1 : prevCurrent - 1
    );
  };

  return (
    <div
      className="carousel"
      onMouseEnter={() => {
        setAutoPlay(false);
        if (timeOut !== null) {
          clearTimeout(timeOut);
        }
      }}
      onMouseLeave={() => {
        setAutoPlay(true);
      }}
    >
      <div className="carousel_wrapper">
        {images.map((image, index) => (
          <div
            key={index}
            className={
              index === current
                ? "carousel_card carousel_card-active"
                : "carousel_card"
            }
          >
            <img className="card_image" src={image.image} alt="" />
            <div className="card_overlay"></div>
          </div>
        ))}
        <div className="carousel_arrow_left" onClick={slideLeft}>
          &lsaquo;
        </div>
        <div className="carousel_arrow_right" onClick={slideRight}>
          &rsaquo;
        </div>
        <div className="carousel_pagination">
          {images.map((_, index) => (
            <div
              key={index}
              className={
                index === current
                  ? "pagination_dot pagination_dot-active"
                  : "pagination_dot"
              }
              onClick={() => setCurrent(index)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Carousel;
