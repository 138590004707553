import React, { useState, useRef } from "react";
import { Grid, FormControlLabel, Radio, Button, FormHelperText, Alert, Typography } from '@mui/material';
import RegHeader from "./registration_header";
import CategoryTable from "./conference_reg_fee";
import WorkshopTable from "./conference_workshop_fee";
import { useForm, Controller } from 'react-hook-form';
import FormField from "./FormField";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonalDetails from "./PersonalDetails";
import ContactDetails from "./ContactDetails";
import OtherDetails from "./OtherDetails";
import '../Registration/registrationstyle.scss'
import registerApiService from "../../core/service/RegistrationAPIService";
import { useNavigate } from "react-router-dom";
import Footer from '../Common/Footer';
import fileuploadApiService from '../../core/service/FileuploadApiService';
import { color } from "framer-motion";
import apiClient from '../../API/API-client';
import CircularProgress from '@mui/material/CircularProgress';

/** Conference Registration Page  */
const ConferenceRegForm: React.FC = () => {
  let totalfee: any
  const [isStudent, setisStudent] = useState(true);
  const [isUploadClicked, setIsUploadClicked] = useState(false);

  const [imageSrc, setImageSrc] = useState('');
  const [message, setMessage] = useState("");
  const [totalamount, settotalAmount] = useState<any>();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState<any>();
  const [selectedFileId, setSelectedFileId] = useState<any>();
  const handleMembershipChange = (event: React.ChangeEvent<any>) => {
    setisStudent(event.target.value === 'yes');
  };

  const [selectedWorkshopAmount, setSelectedWorksopAmount] = useState<any>();
  const [workshopSelection, setworkshopSelection] = useState<any>(null);
  const [WorkshopName, setWorkshopName] = useState<any>(null);
  const [CategoryName, setCategory] = useState<any>("NON_ISA MEMBERS");
  const [role, setRole] = useState<any>(null);
  const [selectionMessage, setSelectionMessage] = useState<any>(null);
  const [imgSelectionMessage, setImgSelectionMessage] = useState<any>(null);
  const [imgLoading, setImgLoading] = React.useState(false);

  const [selectedAmount, setSelectedAmount] = useState<any>(750);
  const [loading, setLoading] = React.useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleConferenceAmountChange = (amount: number, category: any) => {
    // Handle the selected amount as needed in the parent component
    setSelectedAmount(amount);
    setCategory(category)
  };

  const handleWorkshopAmountChange = (amount: number, workshopSelection: any, workshopname: any) => {

    setSelectedWorksopAmount(amount);
    setworkshopSelection(workshopSelection)
    setWorkshopName(workshopname)

  };
  const handleRoleSelected = (role: any) => {
    // Handle the selected role in the parent component
    setRole(role)
  };
  const { control, handleSubmit } = useForm();
  /**
   * Handling form action here to save registration data to database
   * @param data 
   */
  const onSubmit = async (data: any) => {

    let totalfee: number;
    let isaNumber: any;
    let type: number;

    if (loading == false) {
      if (isStudent) {
        isaNumber = data.isanumber;

      }
      if (workshopSelection === "no" || workshopSelection === null) {
        totalfee = parseFloat(selectedAmount) || 0;
        type = 0;
      } else {
        totalfee = (parseFloat(selectedAmount) || 0) + (parseFloat(selectedWorkshopAmount) || 0);
        type = 1;
      }


      if (!CategoryName) {
        setMessage("Please Select your category");
        const element = document.getElementById("categoryselection");
        element?.scrollIntoView();
      } else if ((workshopSelection === "yes") && (selectedWorkshopAmount == 0)) {
        setSelectionMessage("Please select your workshop category");
        const element = document.getElementById("workshopselection");
        element?.scrollIntoView();
      } else if (isStudent && !selectedFile) {
        setImgSelectionMessage("*Please upload a file");
        const element = document.getElementById("fileupload");
        element?.scrollIntoView();
      } else if (isStudent && !isUploadClicked) {
        setImgSelectionMessage("Please click the upload button.");
        const element = document.getElementById("fileupload");
        element?.scrollIntoView();
        return; // Stop form submission
      } else {
        setLoading(true);
        CreateMember(data, isaNumber, totalfee, WorkshopName, CategoryName, type, role, selectedFileId);
      }
    }
  };
  /**
   * call registration api 
   * @param memberData 
   * @param isaNumber 
   * @param totalfee 
   * @param WorkshopName 
   * @param CategoryName 
   * @param type 
   * @param role 
   */


  const CreateMember = async (memberData: any, isaNumber: any, totalfee: any, WorkshopName: any, CategoryName: any, type: any, role: any, selectedFileId: any) => {
    console.log("Create member ", selectedFileId);
    const resp = await registerApiService.registerMember(memberData, isaNumber, totalfee, WorkshopName, CategoryName, type, role, selectedFileId);
    if (resp.status == "success") {
      setLoading(false);
      navigate('/amountdetails', {
        state: {
          resp,
          totalfee,
          WorkshopName,
          selectedWorkshopAmount,
          CategoryName,
          selectedAmount,
          type,
        },
      });
    } else {
      setLoading(false);
      alert(resp.message);
    }
  };
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const fileType = file.type;
      const validTypes = ['image/jpeg', 'image/png'];

      if (validTypes.includes(fileType)) {
        setSelectedFile(file);
      } else {
        alert('Please select a valid image file (.jpg, .jpeg, .png)');
        setSelectedFile(null);
      }
    }
  };

  const handleuploadimage = async () => {
    try {
      setImgLoading(true); 
      console.log("handleuploadimage ");
      if (selectedFile) {
        const imageresponse = await fileuploadApiService.Fileupload(selectedFile);
        console.log(imageresponse);
        setSelectedFileId(imageresponse.data.id);
        setIsUploadClicked(true);
        var document_id = imageresponse.data.id;
        if (document_id) {
          setImgSelectionMessage("Uploaded Successfully");
          fetchImage(document_id);
        }
      } else {
        setImgSelectionMessage("Please Choose a File to Upload");
        console.error('No file selected');
      }
    } catch (error) {
      setImgSelectionMessage("Sorry !..Uploading failed");
      console.error('Error uploading image:', error);
    }finally{
      setImgLoading(false)
    }
  };


  const fetchImage = async (docId: any) => {
    try {
      const response = await apiClient.get(`admin/download/${docId}`, {
        responseType: 'blob' // Ensure the response is treated as a Blob
      });

      // Create a URL from the Blob
      const imageUrl = URL.createObjectURL(response.data);
      setImageSrc(imageUrl);
      console.log("Image URL: ", imageUrl);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };


  return (
    <Grid>
      <Grid>
        <RegHeader />
        <Grid className="paper3">
          <Grid>
            {/* <div >
              <Alert severity="info" className="font_style">We invite free papers and E-posters from both practising anaesthesiologists and postgraduates. Kindly send your abstracts to <b>abstract@isathrissur.com </b>and  posters to <b>poster@isathrissur.com.</b>  The last date for submission of abstracts is on or before 26th January 2024. There is the customary postgraduate quiz with attractive prizes for the winners. (Teams should consist of 2 members).</Alert>
            </div> */}
            <h1>CONFERENCE REGISTRATION FORM</h1>
            <p>Please book for your conference by filling the form below</p>
            <Grid container xs={12} className="paper3">
              <Grid item xs={12} sm={6} md={4} className="h5">
                <h1 className="h5">Are you a Student?</h1>
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Yes"
                  name="yes"
                  value="yes"
                  checked={isStudent}
                  onChange={handleMembershipChange}
                  id="yes"

                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="No"
                  name="no"
                  value="no"
                  checked={!isStudent}
                  onChange={handleMembershipChange}
                  id="no"
                />
                {/* {isStudent && (
                  <FormField
                    icon={<AccountBalanceIcon />}
                    control={control}
                    name="isanumber"
                    defaultValue=""
                    id="isanumber"
                    rules={{ required: 'This field is required' }}
                    label="Enter your ISA Number"
                  />
                )} */}

              </Grid>
            </Grid>
          </Grid></Grid>
        <Grid className="paper1">
          <Grid >
            <Grid item xs={12} >
              <PersonalDetails control={control} isStudent={isStudent} />
            </Grid></Grid>
          { /*  <br /><br /> <Grid>
            <Grid item xs={12}>
              <ContactDetails control={control} />
            </Grid>
            </Grid> */}
          <Grid >
          </Grid></Grid>
        {/* <div className="categoryselection" id="categoryselection"></div> */}
        <Grid className="paper2">
          {message ? <div className="categoryselectionmessage">
            {message}
          </div> : <></>}
          <CategoryTable isaMember={!isStudent} onAmountChange={handleConferenceAmountChange} /><br /><br />
          {selectionMessage ? <div className="categoryselectionmessage">
            {selectionMessage}
          </div> : <></>}
        </Grid>
        {imgSelectionMessage ? <div className="imgSelectionmessage">
            {imgSelectionMessage}
          </div> : <></>}
        <Grid container item xs={10}  >
          {isStudent && (<>
            <Grid className="paper1">
              <Grid >
                <h1 className="h4">C. Upload Documents</h1>
              </Grid>
              <Grid container className="paper">
                <Grid container id="fileupload" className="fileupload" >
                  <input type="file" accept=".jpg,.jpeg,.png" onChange={handleFileSelect} ref={fileInputRef} />
                  <Button onClick={handleuploadimage} type="button" className="filesubmit" disabled={imgLoading}>
                    {imgLoading ? <CircularProgress size={24}/> : 'Upload'}</Button>
                </Grid>
              </Grid>{!selectedFile && <Typography className="document-info-text">
                * Upload your College ID with photo OR bonafide certificate with Photo
              </Typography>}
              {imageSrc && <Grid item xs={12} sm={12}>
                <img className='id-document-display' src={imageSrc} alt="Document" />
              </Grid>}
            </Grid>
          </>
          )}</Grid>
        <Grid container justifyContent="center">
          <Grid item>
            <Button onClick={handleSubmit(onSubmit)} disabled id="registerbutton" className="big-vertical-button pulse-button">
              {loading ? 'Processing...' : 'Registration Closed'}
            </Button>
          </Grid>
        </Grid><br /><br />
      </Grid>
    </Grid>
  );
}

export default ConferenceRegForm;