import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Button, Typography, Container, IconButton, InputLabel, Select, MenuItem, FormControl, FormHelperText, TextField } from '@mui/material';
import "../Registration/addstyles.scss";
import '../../Admin/Registration/addstyles.scss';
import '../../Admin/Common/common.scss';
import '../../Admin/Homescreen/dashboard.scss';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HeaderWithLogout from '../Common/Headerdesktop';
import SidebarToggle from '../Common/SidebarToggle';
import "../Registration/addstyles.scss";
import CustomTextField from '../Common/CustomTextField';
import { Controller } from 'react-hook-form';
import adminApiService from '../../../core/service/AdminApiService';
import { useLocation, useNavigate } from 'react-router-dom';
import WorkshopDatepicker from './WorkshopDatePicker';
import moment from 'moment';
import workshopEditAPIService from '../../../core/service/WorkshopEditApiService';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';

interface Item {
  id: any;
  name: any;
}

let workshopData: any
let formattedEndTime: any
let formattedStartTime: any
const WorkshopEdit: React.FC = () => {
  const [gridData, setGridData] = useState<any[]>([]);
  const [startvalue, setStartvalue] = React.useState<Dayjs | null>(dayjs(formattedStartTime));
  const [endvalue, setEndvalue] = React.useState<Dayjs | null>(dayjs(formattedEndTime));
  const [isSidebarOpen, setSidebarOpen] = useState(false);  // State to track sidebar visibility
  const [conference, setConferenceData] = useState<Item[]>([]);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const { handleSubmit, control } = useForm();
  useEffect(() => {
    conferenceList();
  }, [gridData]);

  const navigate = useNavigate()
  const location = useLocation()
  if (location && location.state) {
    workshopData = location.state;
    convertTimeRangeTo24HourFormat(workshopData.time)
  }

  
  function convertTimeRangeTo24HourFormat(timeRange: any) {
    // Split the time range into start and end times
    const [start, end] = timeRange.split(' to ');
    formattedStartTime = moment(start, 'h:mm A')
    formattedEndTime = moment(end, 'h:mm A')
  }
  const conferenceList = async () => {
    try {
      const apiData = await adminApiService.conferenceList();

      if (apiData) {

        setConferenceData(apiData.data);

      }
    } catch (error) {
      console.error(error, "error is getting here");
    }
  };

  const onSubmit = async (data: any) => {
    
    // Function to convert 24-hour time to 12-hour time with 'am/pm'
    const convertTo12HourFormat = (timeString: string) => {
      const [hours, minutes] = timeString.split(":");
      let hour = parseInt(hours, 10);

      if (hour >= 12) {
        if (hour > 12) {
          hour -= 12;
        }
      }

      return `${hour}:${minutes}`;
    };

    const fromTimeValue = data?.fromtime?.format("HH:mm A"); // Format to 24-hour time with 'AM/PM'
    const toTimeValue = data?.totime?.format("HH:mm A"); // Format to 24-hour time with 'AM/PM'

    // Convert 24-hour format times to 12-hour format with 'am/pm'
    const formattedFromTime = convertTo12HourFormat(fromTimeValue);
    const formattedToTime = convertTo12HourFormat(toTimeValue);
  
    // Concatenate the 'from' and 'to' times with 'am/pm' to create a time range string
    const timeRange = `${formattedFromTime} to ${formattedToTime}`;
   
  
    // Update the data object with the time range
    const updatedData = {
      ...data,
      time: timeRange,
    };
  
    
        try {
          const response = await workshopEditAPIService.WorkshopEditApiService(updatedData,workshopData.id);
          if(response.status.data){
            navigate("/isaadmin/workshoplist")
          }
        } catch (error) {
          // Handle error - Show error message, update state accordingly, etc.
          console.error("Error while updating workshop data:", error);
        }
      };
    


  return (
    <Grid className="dashboard-container">
      <Grid container justifyContent="center">
        <Grid item xs={1} className='sidebar'>
          <SidebarToggle />
        </Grid>

        <Grid item xs={11} className='content-container'>
          <Grid className='conferenceSummaryContainer'>
            <HeaderWithLogout title="Dashboard/Edit Workshop" icon={<DashboardIcon className='dashboard-icon' />} /></Grid>
          <Container className='dash_paper1'>
            <Grid item xs={12} sm={12}  >
              <Grid>
                <IconButton onClick={toggleSidebar} className='menu-icon'> {/* Add IconButton with onClick handler */}
                  {/* <MenuIcon className='menuiconstyle'/> */}
                </IconButton></Grid>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} >
                  <Grid item xs={12}>
                    <Typography variant="h5" className="form-title">
                      Workshop Edit
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name={"Workshop_name"}
                      control={control}
                      defaultValue={workshopData.topic}
                      render={({ field }) => (
                        // Render a Material-UI TextField with specified properties
                        <TextField {...field}
                          type={"text"}
                          label={"Workshop Name"}
                          // variant={variant} 
                          InputLabelProps={{ shrink: true }}
                          fullWidth required
                          className='conferenceadd-textfield'
                          disabled />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      name="workshop_venue"
                      label='Venue'
                      control={control}
                      type={'text'}
                      defaultValue={workshopData.venue}
                      variant="outlined"

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      name="member_rate"
                      label='Member Rate'
                      control={control}
                      type={'number'}
                      defaultValue={workshopData.mem_amount}
                      variant="outlined"

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      name="non_member_rate"
                      label='Non Member Rate'
                      control={control}
                      type={'number'}
                      defaultValue={workshopData.non_mem_amount}
                      variant="outlined"

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <WorkshopDatepicker
                      name="start_date"
                      id="start_date"
                      label='Start Date'
                      control={control}
                      defaultValue={workshopData.start_date}
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <WorkshopDatepicker
                      name="end_date"
                      id="End_date"
                      label='End Date'
                      control={control}
                      defaultValue={workshopData.end_date}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <WorkshopDatepicker
                      name="last_date_of_registration"
                      id="End_date"
                      label='Last date of Registration'
                      control={control}
                      defaultValue={workshopData.last_date_of_registration}
                      rules={{ required: 'This field is required' }}
                    />

                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="fromtime"
                      control={control}
                      defaultValue={formattedStartTime} // Convert to Date object
                      rules={{ required: 'This field is required' }}
                      render={({ field ,fieldState}) => (
                        <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <FormControl fullWidth>
                            <TimePicker
                              label="From Time"
                              {...field}
                              value={startvalue}
                              onChange={(newValue) => {
                                setStartvalue(newValue);
                                field.onChange(newValue); // Ensure that the form state is updated
                              }}
                            />
                          </FormControl>
                        </LocalizationProvider>
                        <FormHelperText error={!!fieldState.error}>{fieldState.error?.message}</FormHelperText>
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="totime"
                      control={control}
                      defaultValue={formattedEndTime} // Convert to Date object
                      rules={{ required: 'This field is required' }}
                      render={({ field ,fieldState}) => (
                        <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <FormControl fullWidth>
                          <TimePicker
                              label="To Time"
                              {...field}
                              value={endvalue}
                              onChange={(newValue) => {
                                setEndvalue(newValue);
                                field.onChange(newValue); // Ensure that the form state is updated
                              }}
                            /></FormControl>
                        </LocalizationProvider>
                        <FormHelperText error={!!fieldState.error}>{fieldState.error?.message}</FormHelperText>
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      name="max_number_of_attendees"
                      label='Total seats'
                      control={control}
                      type={'number'}
                      defaultValue={workshopData.max_no_of_attendee}
                      variant="outlined"

                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>

                    <Controller
                      name={"available_seats"}
                      control={control}
                      defaultValue={workshopData.available_seat}
                      render={({ field }) => (
                        // Render a Material-UI TextField with specified properties
                        <TextField {...field}
                          type={"number"}
                          label={"Available seats"}
                          // variant={variant} 
                          InputLabelProps={{ shrink: true }}
                          fullWidth required
                          className='conferenceadd-textfield'
                          disabled />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      name="number_of_days"
                      label='Number of days'
                      control={control}
                      type={'number'}
                      defaultValue={workshopData.no_of_days}
                      variant="outlined"

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <FormControl fullWidth variant="outlined" className="form-control">
                      <InputLabel htmlFor="Conference Name">Conference Name</InputLabel>
                      <Controller
                        name="conference_id"
                        control={control}
                        defaultValue={workshopData.conference_id}
                        rules={{ required: 'Select the conference' }}
                        render={({ field, fieldState }) => (
                          <>
                            <Select {...field} label="Conference Name">
                              {conference?.map((item: any) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText error={!!fieldState.error}>{fieldState.error?.message}</FormHelperText>
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                      UPDATE
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WorkshopEdit;