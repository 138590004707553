
import React, { useEffect } from "react";
import './Registrationstyles.scss';
import { Container, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import imgEposter from '../../Assets/images/e-poster/eposterformat.png';
import imgReseach from '../../Assets/images/e-poster/RESEARCHEPOSTERFORMAT.png';
import posterPPT from '../../Assets/images/e-poster/FINALISACONKERALAPPT.jpg';
import abstractImg from '../../Assets/images/e-poster/abstract1.jpeg';
import abstractRuleImg from '../../Assets/images/e-poster/abstract2.jpeg';
/**
 * @author Neethu
 * This page is for showing sample abstract and e-poster details
 */

const SampleAbstract: React.FC = () => {
    const handleDownload = (name: any) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = '/Assets/e-poster/' + name; // Replace with the actual path to your PPT file
        downloadLink.download = name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <Grid>
            <Grid className="paper-guidelines">
                <Grid>
                    <Container>
                        <Typography variant="h4" gutterBottom>
                            Guidelines for E-Poster Preparation and Presentation
                        </Typography>
                        <Typography variant="body1" paragraph>
                           For Paper Presentation / e-brochure, you must be an ISA Member
                        </Typography>
                        <Typography variant="body1" paragraph>
                            All e-Posters will be displayed digitally.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            <b>Language:</b> e-Posters must be typed and presented in English.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            <b>Content:</b>
                        </Typography>
                        <List dense style={{ padding: '0px' }}>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircle />
                                </ListItemIcon>
                                <ListItemText primary="Title" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircle />
                                </ListItemIcon>
                                <ListItemText primary="Introduction" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircle />
                                </ListItemIcon>
                                <ListItemText primary="Case Report" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircle />
                                </ListItemIcon>
                                <ListItemText primary="Discussion" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircle />
                                </ListItemIcon>
                                <ListItemText primary="Conclusion" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircle />
                                </ListItemIcon>
                                <ListItemText primary="References (Two references which are most relevant to the presentation)
" />
                            </ListItem>
                        </List>

                        <Typography variant="body1" paragraph padding={0}>
                            e-Posters are to be designed on a single slide MS PowerPoint or Templates. The e-Poster should be static.
                            Please do not embed any dynamic visual elements.
                            <br />
                            The first author should be available in person during the presentation to judges on a specific date and time,
                            which will be informed by mail to the concerned person.
                            <br />
                            Please check and be present at your allocated time slot for the e-Poster judging. All posters should be
                            self-explanatory. The presenter will be provided an overall time of 5 minutes for presentation and 2 minutes for
                            discussion. The e-Posters submitted will be deemed as final. No further changes to the e-Poster will be allowed.
                            <br /><b>
                                Note: All rights are reserved to the scientific committee to reproduce the e-Poster/ Best Poster Standee
                                presentation / Free Paper materials in the future for educational purposes or printing or publishing with due
                                credentials to the author.</b>
                        </Typography>
                        <Typography variant="body1" paragraph padding={0}>
                            1. Stipulated time for e poster presentation is 5 min + 2 min (discussion).
                        </Typography>
                        <Typography variant="body1" paragraph padding={0}>
                            2. The format upon which the poster has to be prepared is given below:
                        </Typography>
                        <Typography variant="body1" paragraph padding={0}>
                            3. You can customize your eposter and can add your creativity in designs.
                        </Typography>
                        <Typography variant="body1" paragraph padding={0}>
                            4. You can submit  your abstracts to <b>abstract@isathrissur.com</b> and posters to <b> poster@isathrissur.com </b>.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            If you have any questions regarding the ePoster system and the upload procedure, please contact our Support Team.
                            <br /><b>Dr. Sajan Sebastain: +91 9188008001<br /> Dr. Sudha : +91 9656764004</b>
                        </Typography>

                        <Grid container spacing={2}>

                            <Grid item xs={6}>

                                <img src={imgEposter} id='imgposter1' alt="Image 1" style={{ width: '100%', height: '100%' }} onClick={() => handleDownload("eposterformat.png")} />
                            </Grid>
                            <Grid item xs={6}>
                                <img src={imgReseach} id='imgposter2'  alt="Image 2" style={{ width: '100%', height: '100%' }} onClick={() => handleDownload("RESEARCHEPOSTERFORMAT.png")} />
                            </Grid>
                            <Grid item xs={4}>
                                <Paper>
                                    <img
                                        src={posterPPT}
                                        alt="ppt image"
                                        id="midkonppt"
                                        style={{ width: '100%', height: '300px' }}
                                        onClick={() => handleDownload("ISAMIDKONKERALA2024.ppt")}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper>
                                    <img src={abstractImg} alt="ppt"
                                     id="abstract1"
                                     style={{ width: '100%', height: 'auto' }} 
                                     onClick={()=>handleDownload('abstract1.jpeg')} />
                                </Paper>
                               
                            </Grid>
                            <Grid item xs={4}>
                            <Paper>
                                    <img src={abstractRuleImg} alt="ppt"
                                     id="abstract2"
                                     style={{ width: '100%', height: '300px' }} 
                                     onClick={()=>handleDownload('abstract2.jpeg')} />
                            </Paper>
                            </Grid>
                        </Grid>
                        <br />
                    </Container>

                </Grid>
            </Grid>
        </Grid>
    )

}


export default SampleAbstract;