import {
    Grid, Typography, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Paper,
    Button
} from "@mui/material";
import React,{useState} from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import registerApiService from "../../core/service/RegistrationAPIService";


let amountdata: any
const AdminAmountDetails: React.FC = () => {
    const [loading, setLoading] = React.useState(false);
    const [paymentfailure, setPaymentfailure] = React.useState(false);
    const location = useLocation();
    if (location && location.state) {
        amountdata = location.state
    }
    const navigate = useNavigate();
    
    const handleCheckoutClick = async () => {
        setLoading(true);
            
            const response = await registerApiService.updatePaymentRegistration(amountdata.resp.data, amountdata.totalfee,null);
            setLoading(false);
            if (response.status == "success") {
                navigate('/admindone', { state: amountdata.totalfee });
            } else {
                navigate('/paymentfailure', { state: amountdata.totalfee });
                alert("Your Payment is not completed..Please contact admin@isathrissur.com")
            }
        }
    return (
        <Grid>
            <Grid className="containercheckout">
                <Grid className="adminamountdetails">
                    <h1 className="h1">Details of cost</h1>
                    <Grid spacing={3} className="amountcontainer">
                        <Grid item xs={12} sm={6} md={4}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper} className="amount-table">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Particulars</TableCell>

                                                <TableCell >Amount in INR</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {amountdata?.CategoryName ?
                                                <TableRow>
                                                    <TableCell>{amountdata?.CategoryName}</TableCell>
                                                    <TableCell>Rs. {amountdata?.selectedAmount}</TableCell>
                                                </TableRow>
                                                : <div></div>}
                                            {amountdata?.WorkshopName ?
                                                <TableRow>
                                                    <TableCell>Workshop ({amountdata?.WorkshopName})</TableCell>
                                                    <TableCell>Rs. {amountdata?.selectedWorkshopAmount}</TableCell>
                                                </TableRow>
                                                : <div></div>}
                                            {amountdata?.totalfee ?
                                                <TableRow>
                                                    <TableCell>Total Amount</TableCell>
                                                    <TableCell>Rs. {amountdata?.totalfee}</TableCell>
                                                </TableRow>
                                                : <div></div>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br /><br />
                <Grid container  justifyContent="center"  >
                    <Grid item xs={12} sm={6} md={4} >
                        <Button onClick={handleCheckoutClick} className="big-checkout-button" id="checkoutbutton">
                            PAID
                        </Button>
                    </Grid>

                </Grid>

            </Grid>
            <br /><br />
        </Grid>

    )
}
export default AdminAmountDetails