import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Button, Typography, IconButton, InputLabel, Select, MenuItem, FormControl, FormHelperText } from '@mui/material';
import "../Registration/addstyles.scss";
import '../../Admin/Registration/addstyles.scss';
import '../../Admin/Common/common.scss';
import '../../Admin/Homescreen/dashboard.scss';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HeaderWithLogout from '../Common/Headerdesktop';
import SidebarToggle from '../Common/SidebarToggle';
import "../Registration/addstyles.scss";
import CustomTextField from '../Common/CustomTextField';
import WorkshopDataGrid from './Workshopdatagrid';
import workshopAddAPIService from '../../../core/service/WorshopAddApiService';
import Formdatepicker from './FormDatepicker';
import { Controller } from 'react-hook-form';
import adminApiService from '../../../core/service/AdminApiService';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


interface Item {
  id: any;
  name: any;
}

const WorkshopForm: React.FC = () => {
  const [gridData, setGridData] = useState<any[]>([]);
  const [isSidebarOpen, setSidebarOpen] = useState(false);  // State to track sidebar visibility
  const [conference, setConferenceData] = useState<Item[]>([]);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const { handleSubmit, control } = useForm();
  useEffect(() => {
    conferenceList();
  }, [gridData]);

  const conferenceList = async () => {
    try {
      const apiData = await adminApiService.conferenceList();

      if (apiData) {

        setConferenceData(apiData.data);

      }
    } catch (error) {
      console.error(error, "error is getting here");
    }
  };

  const onSubmit = async (data: any) => {

     // Function to convert 24-hour time to 12-hour time with 'am/pm'
     const convertTo12HourFormat = (timeString: string) => {
      const [hours, minutes] = timeString.split(":");
      
      let hour = parseInt(hours, 10);

      if (hour >= 12) {
       
        if (hour > 12) {
          hour -= 12;
        }
      }

      return `${hour}:${minutes}`;
    };

    const fromTimeValue = data.fromtime.format("HH:mm A"); // Format to 24-hour time with 'AM/PM'
    const toTimeValue = data.totime.format("HH:mm A"); // Format to 24-hour time with 'AM/PM'

    // Convert 24-hour format times to 12-hour format with 'am/pm'
    const formattedFromTime = convertTo12HourFormat(fromTimeValue);
    const formattedToTime = convertTo12HourFormat(toTimeValue);
  
    // Concatenate the 'from' and 'to' times with 'am/pm' to create a time range string
    const timeRange = `${formattedFromTime} to ${formattedToTime}`;
  
    // Update the data object with the time range
    const updatedData = {
      ...data,
      time: timeRange,
    };
  
    try {
      const response = await workshopAddAPIService.workshopAddApiService(updatedData);
      // Handle success - Update state, show success message, etc.
      // Update grid data only if API call was successful
      setGridData((prevData) => [
        ...prevData,
        {
          Workshop_name: updatedData.Workshop_name,
          workshop_venue: updatedData.workshop_venue,
          member_rate: updatedData.member_rate,
          non_member_rate: updatedData.non_member_rate,
          number_of_attendees: updatedData.number_of_attendees,
          start_date: updatedData.start_date,
          end_date: updatedData.end_date,
          last_date_of_registration: updatedData.last_date_of_registration,
          number_of_days: updatedData.number_of_days,
          time: updatedData.time, // Use updatedData.time as it contains the concatenated timeRange
          conference_id: updatedData.conference_name,
        },
      ]);
  
      // Additional code for handling successful submission
    } catch (error) {
      // Handle error - Show error message, update state accordingly, etc.
      console.error("Error while saving data:", error);
    }
  };
  

  return (
    <Grid className="dashboard-container">
      <Grid container justifyContent="center">
        <Grid item xs={1} className='sidebar'>
          <SidebarToggle />
        </Grid>

        <Grid item xs={11} className='content-container'>
          <Grid className='conferenceSummaryContainer'>
            <HeaderWithLogout title="Dashboard/Add Workshop" icon={<DashboardIcon className='dashboard-icon' />} /></Grid>
          <Grid xs={12} className='dash_paper1'>
            <Grid item xs={12} sm={12}  >
              <Grid>
                <IconButton onClick={toggleSidebar} className='menu-icon'> {/* Add IconButton with onClick handler */}
                  {/* <MenuIcon className='menuiconstyle'/> */}
                </IconButton></Grid>
              <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container spacing={2} >
                  <Grid item xs={12}>
                    <Typography variant="h5" className="form-title">
                      Workshop Add
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      name="Workshop_name"
                      label='Workshop Name'
                      control={control}
                      type={'text'}
                      defaultValue=""
                      variant="outlined"

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      name="workshop_venue"
                      label='Venue'
                      control={control}
                      type={'text'}
                      defaultValue=""
                      variant="outlined"

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      name="member_rate"
                      label='Member Rate'
                      control={control}
                      type={'number'}
                      defaultValue=""
                      variant="outlined"

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      name="non_member_rate"
                      label='Non Member Rate'
                      control={control}
                      type={'number'}
                      defaultValue=""
                      variant="outlined"

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Formdatepicker
                      name="start_date"
                      id="start_date"
                      label='Start Date'
                      control={control}
                      defaultValue=""
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Formdatepicker
                      name="end_date"
                      id='End Date'
                      label='End Date'
                      control={control}
                      defaultValue=""
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Formdatepicker
                      name="last_date_of_registration"
                      id="last_date_of_registration"
                      label='Last date of Registration'
                      control={control}
                      defaultValue=""
                      rules={{ required: 'This field is required' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="fromtime"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'This field is required' }}
                      render={({ field ,fieldState}) => (
                        <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <FormControl fullWidth>
                            <TimePicker
                              {...field}
                              label="From Time"
                            /></FormControl>
                            
                        </LocalizationProvider>
                        <FormHelperText error={!!fieldState.error}>{fieldState.error?.message}</FormHelperText></>
                      )}
                      
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="totime"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'This field is required' }}
                      render={({ field ,fieldState}) => (
                        <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <FormControl fullWidth>
                            <TimePicker
                              {...field}
                              label="To Time"

                            /></FormControl>
                        </LocalizationProvider>
                        <FormHelperText error={!!fieldState.error}>{fieldState.error?.message}</FormHelperText>
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      name="number_of_attendees"
                      label='Total seats'
                      control={control}
                      type={'number'}
                      defaultValue=""
                      variant="outlined"

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      name="number_of_days"
                      label='Number of days'
                      control={control}
                      type={'number'}
                      defaultValue=""
                      variant="outlined"

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <FormControl fullWidth variant="outlined" className="form-control">
                      <InputLabel htmlFor="Conference Name">Conference Name *</InputLabel>
                      <Controller
                        name="conference_name"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Conference Name"
                            required

                          >
                            <MenuItem value="">
                              Select
                            </MenuItem>
                            {conference?.map((item: any) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid container display="flex" justifyContent="center" className='addimagebtn'>
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <Grid item xs={12}>
                <Typography variant="h6" className="form-title Workshop_paddig">
                  Workshop List
                </Typography>
              </Grid>
              <WorkshopDataGrid rows={gridData} /><br /><br />
            </Grid>
          </Grid>
        </Grid></Grid></Grid>
  );
};

export default WorkshopForm;