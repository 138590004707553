/*
  Home Screen Terms and Condition
  
  Developer: Athul P P
*/

import { Grid, Typography } from "@mui/material";
import React from "react";

const Terms_and_Condition = () => {
  return (
    <Grid className="home_container2">
      <Grid xs={12} md={12} sm={12} className="center_align">
        <Typography className="font_style_header">
          TERMS & CONDITIONS
        </Typography>
        <Grid container xs={12} md={12} sm={12} className="home_workshop_ct">
          <Grid item xs={12} md={12} sm={12} className="align_fontStart">
            <Typography className="font_style">
              ➤ Registration is mandatory for all the types of Participation
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} sm={12} className="align_fontStart">
            <Typography className="font_style">
              ➤ Conference registration is mandatory to attend workshop{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} sm={12} className="align_fontStart">
            <Typography className="font_style">
              ➤ Accompanying person will not be allowed to attend the conference
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={12} sm={12} className="align_fontStart">
            <Typography className="font_style">
              ➤ Registered candiadate can attend only one workshop
            </Typography>
          </Grid> */}
          {/* <Grid item xs={12} md={12} sm={12} className="align_fontStart">
            <Typography className="font_style">
              ➤ Postgraduate students are required to furnish a bonafide
              certificate or letter from the Head of Department (HOD)
            </Typography>
          </Grid> */}
          <Grid item xs={12} md={12} sm={12} className="align_fontStart">
            <Typography className="font_style">
              ➤ The registration for the conference and workshop is
              non-transferable
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} sm={12} className="align_fontStart">
            <Typography className="font_style">
              ➤ No cancellation is allowed
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Terms_and_Condition;
