import React from "react";
import {
  Grid,
  Typography,
  Container,
  Checkbox,
  FormControlLabel,
  Button,
  MenuItem,
  Link
} from "@mui/material";

import { useState } from 'react';
import FormTextField from "./Formtextfield";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonIcon from '@mui/icons-material/Person';
import FormField from "./FormField";
import { useForm, Controller, FieldValues, Control } from 'react-hook-form';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import WorkIcon from '@mui/icons-material/Work';
interface OtherDetailsProps {
  control: Control<FieldValues>;
  RoleSelected: (role: any) => void;
}
const OtherDetails: React.FC<OtherDetailsProps> = ({ control, RoleSelected }) => {
  const [selectedPosition, setSelectedPosition] = useState();

  const handleCheckboxChange = (position: any) => {
    setSelectedPosition(position);
  };

  //const positionOptions = ['Chairperson', 'Moderator', 'Panelist', 'Speaker', 'Presenter', 'Delegate'];
  const employmentOptions = [
    { value: 'Government', label: 'Government' },
    { value: 'Corporate', label: 'Corporate' },
    { value: 'Freelancer', label: 'Freelancer' },
    { value: 'Student', label: 'Student' },
  ];

  const performanceOptions = [
    { value: 'Instrumental', label: 'Instrumental' },
    { value: 'Vocal', label: 'Vocal' },
    { value: 'Stage Performance', label: 'Stage Performance' },

  ];
  const dietOptions = [
    { value: 'Veg', label: 'Veg' },
    { value: 'Non Veg', label: 'Non Veg' },
  ];
  const presentoption = [
    { name: 'poster', label: 'E-Poster' },
    { name: 'abstract', label: 'Abstract' },
  ];
  const [selectedRole, setSelectedRole] = useState<string | null>(null);

  const roles = [
  
    { name: "Organiser", key: "organiser" },
    { name: "Faculty", key: "faculty" },
    { name: "Sponser", key: "sponser" },
    { name: "Delegate", key: "delegate" },
  ];

  const sizes = [
    { "name": "Extra Small", "abbreviation": "XS" },
    { "name": "Small", "abbreviation": "S" },
    { "name": "Medium", "abbreviation": "M" },
    { "name": "Large", "abbreviation": "L" },
    { "name": "Extra Large", "abbreviation": "XL" },
    { "name": "Double Extra Large", "abbreviation": "2XL" },
    { "name": "Triple Extra Large", "abbreviation": "3XL" }
    // Add more sizes as needed
  ]

  const handleRoleSelection = (role: any) => {
    setSelectedRole(role);
    RoleSelected(role);
  };

  return (


    <Grid >
      <Grid item xs={12}>
        <h1 className="h4">C. Other Information</h1>
      </Grid>
      <Grid container spacing={3} className="paper">
        {/* Type of Practice */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">Type of Practice </Typography>
          <FormField
            name="typeofpractice"
            type="select"
            control={control}
            icon={<WorkIcon />}
            defaultValue=""
            label=""
            id="practice"
            rules={""}
          >
            {/* <MenuItem key={""} value={""}>
      select
    </MenuItem> */}
            {employmentOptions.map((option) => (
              <option key={option.value} value={option.label}>
                {option.label}
              </option>
            ))}
          </FormField>
        </Grid>

        {/* Type of Position */}
        <Grid item xs={12} sm={6} md={8}>
          <Typography variant="body1" className="h2">Type of Position </Typography>
          {/* Checkboxes */}
          <table>
            <tbody>
              {roles.map((role, index) => (
                index % 2 === 0 ? (
                  <tr key={role.key}>
                    <td>
                      <Controller
                        name={role.key}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            type="checkbox"
                            {...field}
                            checked={selectedRole === role.key}
                            onChange={() => handleRoleSelection(role.key)}
                          />
                        )}
                      />
                      {role.name}
                    </td>
                    {index + 1 < roles.length && (
                      <td>
                        <Controller
                          name={roles[index + 1].key}
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <input
                              type="checkbox"
                              {...field}
                              checked={selectedRole === roles[index + 1].key}
                              onChange={() => handleRoleSelection(roles[index + 1].key)}
                            />
                          )}
                        />
                        {roles[index + 1].name}
                      </td>
                    )}
                  </tr>
                ) : null
              ))}
            </tbody>
          </table>
        </Grid>


        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">I  would like to present </Typography></Grid>
        {/* Checkboxes */}
        <Grid item xs={12} sm={6} md={8}>
          <table>
            <tbody>
              {presentoption.map((option, index) => (
                <React.Fragment key={option.label}>
                  {index % 2 === 0 ? (
                    <tr>
                      <td>
                        <Controller
                          name={`selectedOptions[${index}]`}
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <input
                              type="checkbox"
                              {...field}
                            />
                          )}
                        />
                        {option.label}
                      </td>
                      {index + 1 < presentoption.length && (
                        <td>
                          <Controller
                            name={`selectedOptions[${index + 1}]`}
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                              <input
                                type="checkbox"
                                {...field}
                              />
                            )}
                          />
                          {presentoption[index + 1].label}
                        </td>
                      )}
                    </tr>
                  ) : null}
                </React.Fragment>
              ))}
              <tr >
                <td colSpan={2} style={{ border: '5px' }}>
                  <Link href="/sampleabstract" target="_blank" rel="noopener noreferrer">
                   GUIDELINES
                  </Link></td>
              </tr>
            </tbody>



          </table>

        </Grid>


        {/* Diet Preference */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">Diet Preference *</Typography>
          <FormField
            name="dietPreference"
            type="select"
            control={control}
            icon={<FastfoodIcon />}
            defaultValue=""
            label=""
            id="Diet Preference"
            rules={{ required: 'Please select  your diet preference' }}
          >

            {dietOptions.map((option) => (
              <option key={option.value} value={option.label}>
                {option.label}
              </option>
            ))}
          </FormField>
        </Grid>

        {/* Cultural Activity Participation */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">Cultural Activity Participation </Typography>
          <FormField
            name="performanceoption"
            type="select"
            control={control}
            icon={<AccountBalanceIcon />}
            defaultValue=""
            label=""
            id="Cultural Activity Participation"
            rules={""}
          >
            {/* <MenuItem key={""} value={""}>
      select
    </MenuItem> */}
            {performanceOptions.map((option) => (
              <option key={option.value} value={option.label}>
                {option.label}
              </option>
            ))}
          </FormField>
        </Grid>


        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">T-shirt size * </Typography>
          <FormField
            name="tshirt_size"
            type="select"
            control={control}
            icon={<PersonIcon />}
            defaultValue=""
            label=""
            id="T-shirt size"
            rules={{ required: 'Please select  your T-Shirt Size' }}
          >
            {sizes.map((option) => (
              <option key={option.name} value={option.abbreviation}>
                {option.name}
              </option>
            ))}
          </FormField>
        </Grid>
      </Grid>

    </Grid>

  );
};

export default OtherDetails;
