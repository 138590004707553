
import { Grid, Typography, Card, CardMedia, CardContent, ImageListItemBar, ImageListItem, Divider, Button,
  Modal, Backdrop, Fade, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import config from '../../../config.json';
import newsListAPIService from "../../../core/service/NewsListUIApiService";
import { CalendarIcon } from "@mui/x-date-pickers";
import moment from "moment";
import YouTube from 'react-youtube';
import nonews_image from '../../../Assets/images/no_news.png'
import DOMPurify from 'dompurify';
import { Close } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import { motion, useAnimation } from "framer-motion";

//let newsData: any;
let newsList: any;
let newsListData: any;
let embeddedCode:any

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const NewsViewPage: React.FC = () => {

  const baseURL = config.prodapi.url;
  const location = useLocation();
  const navigate = useNavigate()

  const [newstagList, setNewstagList] = useState<any>([])
   const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [newsData, setnewsData] = useState<any>([])
  const [sanitizedDescription, setsanitizedDescription] = useState<any>()


  const extractVideoId = (url: any) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    return match && match[2] ? match[2] : null;
  };

  useEffect(() => {
  
      fetchNewsList("");
 
  }, []);

  const fetchNewsList = async (news:any) => {
    try{const resp = await newsListAPIService.NewsListAPI();

    if (resp.data) {

       if(news){
        
        setnewsData(news)
        setsanitizedDescription(DOMPurify.sanitize(news.description.replace(/\n/g, '<br>')))
          newsListData = resp.data.filter((newsItem: any) => newsItem.id !== news.id);
          fetchtagNewsList(news);
       }else{
     
        setnewsData(resp.data[0]);
        setsanitizedDescription(DOMPurify.sanitize(resp.data[0].description.replace(/\n/g, '<br>')))
        newsListData = resp.data.filter((newsItem: any) => newsItem.id !== resp.data[0].id);
        fetchtagNewsList(resp.data[0]);
       }
    }}
    catch(error){
        console.error("Error fetching news",error)
    }
}


  const fetchtagNewsList = async (newsData: any) => {
    try {
      const resp = await newsListAPIService.NewsListTagAPI(newsData);

      if (resp.data) {
        const newstagListData = resp.data.filter((newsItem: any) => newsItem.id !== newsData.id);
        setNewstagList(newstagListData)
      }
    }
    catch (error) {
      console.error("Error fetching news", error)
    }
  }


  const handleClick = (news: any) => {
    fetchNewsList(news);
   
    
      const targetElement = document.getElementById("newsitem");
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
  

  };



  const handlebackbutton = (news: any) => {
    navigate("/");
  };

  const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    // Close dialog handler
    const handleClose = () => {
      setOpen(false);
    };

    const handleNewsImageClick = (index: number) => {
      setSelectedImage(index);
      setOpen(true)
  };
  return (
<div>
  {!newsData &&
  <Grid className="no-news-container">
    <img  src={nonews_image} alt="Your Alt Text" />
    <Typography variant="body1" className="nonews_text"> No news available now</Typography>
    <Button onClick={handlebackbutton} id="backhomebutton" className="back-home-button">
    BACK HOME
    </Button>
  </Grid>
  }
  {newsListData && newsData &&
    <Grid container className="margin-align">
      <div className="newsitem" id="newsitem">
      <Grid container item xs={12}>
        <Grid item xs={12} md={8}>
          <Grid container>
            <Grid item xs={12}>
            <Typography className="newsitem_header">
              {newsData.title}
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography  className="newsitem_subtitle" >
              {newsData.subtitle}
            </Typography>
            </Grid>
            <Grid className="newsimg-container" onClick={() => handleNewsImageClick(newsData.image)}>
            <img
              src={baseURL + 'admin/download/' + newsData.image}
              alt="Your Image"
              className="mainimagestyle"
            />
            </Grid>
          
          {/* Display enlarged image in a dialog */}
          {selectedImage !== null && (
                    <BootstrapDialog
                   onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                  >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                      
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <Close />
                    </IconButton>
                    <DialogContent  >
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                     >
                    <img
                     src={baseURL + 'admin/download/' + selectedImage}
                     alt={`Image ${selectedImage}`}
                     style={{ maxWidth: "100%" }}
                    />
       
                    </motion.div>
                    </DialogContent>
                   
                  </BootstrapDialog>
                )}
            <Grid item xs={12}>
              <Typography className="date_style">
                {`Posted on ${moment(newsData.date).format("DD MMM YYYY")}`}
              </Typography>
              <Divider />
            </Grid>
            <Grid className="youtubeframe-container">

            {newsData.frame && (
                      <YouTube
                        videoId={extractVideoId(newsData.frame)}
                        opts={{
                          height: '150',
                          width: '280',
                          playerVars: {
                            autoplay: 0,
                          },
                        }}
                      />
                    )}
              </Grid>
         
             <div dangerouslySetInnerHTML={{ __html:sanitizedDescription}} />

            {newstagList && newstagList.length > 0 &&
              <Grid item xs={12} className="margin-align-tagnews">

                <Typography className="font_style_header-news">
                  YOU MAY LIKE
                </Typography>

                <Grid  className="tag-news-maincontainer">
                  {newstagList.map((newsData: any) => (
                    <Grid className="tag-news-container">
                      <ImageListItem key={newsData._id} className="tag-imagelistitem" onClick={() => handleClick(newsData)}>
                        <img
                          src={`${baseURL + 'admin/download/' + newsData.image}?w=388&fit=crop&auto=format`}
                          srcSet={`${baseURL + 'admin/download/' + newsData.image}?w=388&fit=crop&auto=format&dpr=2 2x`}
                          alt={newsData.name}
                          loading="lazy"
                        />

                        <ImageListItemBar
                          title={newsData.title}
                        />
                      </ImageListItem>

                    </Grid>
                  ))}
                </Grid>
              </Grid>

            }

          </Grid>
        </Grid>
        {newsListData && newsListData.length > 0 &&
          <Grid item xs={12} md={4}>
            <Grid className="margin-align-popularnews">
              <Typography className="font_style_header-news">
                POPULAR NEWS
              </Typography>
              <Grid>
                <Grid style={{ overflowY: 'auto', height: 600 }}>
                  {newsListData.map((newsData: any) => (
                    <Card className="newscard" onClick={() => handleClick(newsData)}>
                      <CardMedia
                        component="img"
                        className="imgstyle"
                        image={baseURL + 'admin/download/' + newsData.image}
                      />
                      <CardContent className="cardcontent">
                        <Typography className="textstyle">
                          {((newsData.title).length > 40) ?
                            ((((newsData.title)).substring(0, 40 - 3)) + '...') :
                            (newsData.title)}
                        </Typography>
                        <Grid xs={12} display={"flex"}>
                          <CalendarIcon />
                          <Typography className="datestyle">{moment(newsData.date).format("DD MMM YYYY")}</Typography>
                        </Grid>
                      </CardContent>

                    </Card>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>
      </div>
    </Grid>
  }
</div>
  );
};

export default NewsViewPage;
