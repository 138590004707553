import React, { useState, useEffect } from 'react';
import { Grid, Typography, LinearProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import '../../Admin/Homescreen/dashboard.scss';
import GroupIcon from '@mui/icons-material/Group';
import UpcomingDataGrid from './Upcominevent';
import CircularProgressBar from '../Common/Progressbar';
import Header from '../../Admin/Common/Header';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link } from 'react-router-dom';
import SidebarToggle from '../Common/SidebarToggle';
import dashboardApiService from '../../../core/service/AdminDashboardApiService';
import conferencesPrevious from '../Common/previousevent';
import FormField from '../../Registration/FormField';
import { Controller, useForm } from 'react-hook-form';
import PersonIcon from '@mui/icons-material/Person';
const Dashboard: React.FC = () => {
  const [conferenceData, setconferenceData] = useState<any[]>([]);
  const [dashboardData, setDashboardData] = useState<any>('');
  const [selectedConference, setSelectedConference] = useState<any>('2');
  const { control, handleSubmit } = useForm();
  const normalizeValue = (value:any, maxValue:any) => {
    if (value === 0) {
      return 0;
    }
    return Math.min((value / maxValue) * 100, 100);
  };
  const fetchDashboardData = async (selectedConference:any) => {

    // Call the data from api service
    const resp = await dashboardApiService.DashboardListApi(selectedConference);



    if (resp?.data) {
      setconferenceData(resp?.data?.conference)
      setDashboardData(resp?.data)
    };


  }
  const valueprogress = dashboardData?.person;
  const limit = 600;

  const progress = (valueprogress / limit) * 100;
  useEffect(() => {

    fetchDashboardData(selectedConference);
    // You can perform any asynchronous operations here, such as data fetching
  }, []);
  const handleConferenceChange=(selectedValue:any)=>{
    setSelectedConference(selectedValue);
    fetchDashboardData(selectedValue);
  }
  const handleSignout = () => {
    // Clear the authentication information in sessionStorage
    sessionStorage.removeItem('isAuthenticated');
    // Redirect to the login page or perform any other necessary action
    window.location.href = '/isaadmin';
  };


  return (
    <Grid className="dashboard-container">
      <Grid container justifyContent="center">

        <Grid item xs={1} className='sidebar' >
          <SidebarToggle />
        </Grid>

        {/* Dashboard Body */}
        <Grid item xs={11} className='content-container'>

          <Grid className='textcontainer'>
            <Grid container className='header-with-logout-container'>
              <Header />
              <div>
                <Typography className='dash_title'>
                  Hello ISA, Welcome back 👋🏻
                </Typography>
                <Typography className='dashbrd_description'>
                  Your project status is appearing here.
                </Typography>
              </div>
              <Link to="/isaadmin" className='linkbutton' onClick={handleSignout}>
                <div className='logoutContainer'>
                  {/* Logout icon */}
                  <ExitToAppIcon className='logout-icon' />
                  {/* Logout text */}
                  <Typography className='logout-text'>
                    Logout
                  </Typography>
                </div></Link>
            </Grid></Grid>
          <Grid container xs={12} className='conferenceSummaryContainer1'>
            <Grid container xs={12} sm={8} className='gridContainer'>
              <Grid container item xs={12} className='gridItem'>
                <Grid item xs={6} className='textcontainer1'>
                  <Typography className='dash_title1'>Conference Summary</Typography>
                  <Typography className='dash_description'>
                    An overview of your event.
                  </Typography>
                </Grid>
                <Grid item xs={6} className='selectcontainer'>
                <FormControl fullWidth variant="outlined" className="form-control" style={{ width: '80%' }}>
            <InputLabel htmlFor="Conference Name">Conference Name</InputLabel>
            <Controller
              name="conference_name"
              control={control}
              defaultValue="2"
              render={({ field }) => (
                <Select {...field} label="Conference Name" 
                style={{height:50}}
                onChange={(e) => {handleConferenceChange(e.target.value);field.onChange(e)}}
                value={selectedConference}>
                  <MenuItem value="Select">
                    Select
                  </MenuItem>
                  {dashboardData?.conference?.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>))}
                </Select>
              )}
            />
          </FormControl>
                </Grid>
                <br />
                <Grid item xs={12} className='greyLine'></Grid>

                <Grid container xs={12}  className='circularContainer' >
                  <Grid item xs={3} >
                    <CircularProgressBar value={dashboardData?.conference_count ?? 0} amount={dashboardData?.conference_count ?? 0} label="Total Conference" classname={'customCircularProgress1'} />
                  </Grid>
                  <Grid item xs={3} >
                    <CircularProgressBar value={dashboardData?.workshop_count ?? 0} amount={dashboardData?.workshop_count ?? 0} label="Workshop Count" classname={'customCircularProgress2'} />
                  </Grid>
                  <Grid item xs={3}  >
                    <CircularProgressBar value={normalizeValue(dashboardData?.payment_total,2300000) ?? 0} amount={dashboardData?.payment_total ?? 0} label="Payment Received" classname={'customCircularProgress2'} />
                  </Grid>
                  <Grid item xs={3} >
                    <CircularProgressBar value={normalizeValue(dashboardData?.pending_total,2300000) ?? 0} amount={dashboardData?.pending_total ?? 0} label="Pending Payment" classname={'customCircularProgress2'} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} sm={3} container className='mr_lft'>

              <Grid container className="activeUsersContainer">
                <Grid className='textcontainer1'>
                  <Typography className="dash_title1">
                    Active Users
                  </Typography>
                  <Typography variant="body2" className="dash_description">
                    A small summary of your users base
                  </Typography></Grid>
                <Grid item xs={12} className='greyLine'></Grid>
                <div className="progressContainer1 textcontainer1">
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    className='customLinearProgress'
                  />
                </div>
              </Grid>


              <Grid container className='totalUsersContainer'>
                <Grid className='textcontainer1'>
                  <Typography variant="h6" className='dash_title1'>
                    Total User Count
                  </Typography>
                  <Typography variant="body2" className='dash_description'>
                    An overview of all your users on your platform.
                  </Typography></Grid>
                <Grid item xs={12} className='greyLine'></Grid>

                <Grid container className='itemcenter'>
                  <GroupIcon className='groupiconstyle' />
                  <Typography variant='h4' className='mgleft'>
                    {dashboardData?.person}
                  </Typography>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
          <Grid xs={12} className='textcontainer'>


            <Grid xs={12} className='up_grid'>
              <Typography className='dash_title2'>
                Upcoming Events
              </Typography>
            </Grid>
            <UpcomingDataGrid rows={conferenceData} />
          </Grid>
          <Grid xs={12} className='textcontainer'>


            <Grid xs={12} className='up_grid'>
              <Typography className='dash_title2'>
                Previous Events
              </Typography>
            </Grid>
            <UpcomingDataGrid rows={conferencesPrevious} />
          </Grid>




        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
