/*
  Workshop fee table to display all workshop fee,time,date,and venue
  selection using radio button
  Disabling the radio button on member type
  
*/
import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, Grid, Typography,
  TableHead, TableRow, Paper, Radio, RadioGroup, FormControlLabel
} from '@mui/material';
import '../Registration/registrationstyle.scss';
import workShopListAPIService from '../../core/service/WorkShopListApiService';
import { json } from 'stream/consumers';


const WorkshopTable = ({
  isaMember,
  onAmountWorkshopChange
}: {
  isaMember: any; 
  onAmountWorkshopChange: (amount: number, workshopSelection: any, category: any) => void;
}) => {


  
  useEffect(() => {
   fetchWorkshopList();
  }, []);

  useEffect(() => {
    setSelectedOption('');
    onAmountWorkshopChange(0, "no", "");
    setWorkshopSelection("")
  }, [isaMember]);

  const data = {
    categories: [
      {
        "workshopName": "Simulation based learning",
        "time": "2pm to 8pm",
        "seats": 30,
        "venue": "Govt medical college Thrissur",
        amounts: {
          "ISA Member": 750,
          "NON ISA Member": 1250,

        },
      },
      {
        "workshopName": "Usg guided RA",
        "time": "8am to 4pm",
        "seats": 40,
        "venue": "Jubilee mission",
        amounts: {
          "ISA Member": 2000,
          "NON ISA Member": 2500,

        },
      },
      {
        "workshopName": "Critical care in a nutshell",
        "time": "9am to 4pm",
        "seats": 40,
        "venue": "Amala medical college",
        amounts: {
          "ISA Member": 1500,
          "NON ISA Member": 2000,

        },
      },
      {
        "workshopName": "Ventilation",
        "time": "2pm to 6 pm",
        "seats": 25,
        "venue": "Daya hospital",
        amounts: {
          "ISA Member": 1000,
          "NON ISA Member": 1500,

        },
      },
    ],
  };

  const [selectedOption, setSelectedOption] = useState<any>('');

  const [selectedOptions, setSelectedOptions] = React.useState<any>({});
  const [workshopSelection, setWorkshopSelection] = useState('');
  const [workshopList,setWorkshopList] =useState<any>([])
  const handleRadioChange = (category: any, month: any, amount: any, workshopselection: any) => {
    setSelectedOption(`${category}-${month}`);
    onAmountWorkshopChange(amount, "yes", category);
    setWorkshopSelection("yes")
  };


  const handleWorkshopSelectionChange = (event: any) => {
    setWorkshopSelection(event.target.value);
    if (event.target.value == "no") {
      setSelectedOption('');
      onAmountWorkshopChange(0, "no", "");
    } else {
      onAmountWorkshopChange(0, "yes", "");
    }

  };

  const fetchWorkshopList = async () => {

    const resp = await workShopListAPIService.WorkShopListAPI();
  
    if (resp) {
      
      const transformedData = {
        Workshop: resp.data.map((workshop:any) => {
          const { amounts, ...rest } = workshop;
          return {
            ...rest,
            amounts: [
              { type: "mem_amount", value: workshop.mem_amount },
              { type: "non_mem_amount", value: workshop.non_mem_amount },
            ],
          };
        }),
      };
      setWorkshopList(transformedData)
     
    }
  }
 
  return (
    <Grid container className="category-container">
      {/* Workshop Title Row */}
      <Grid item xs={12} >
        <h1 className="h9">    E. Workshop selection</h1></Grid>

      {/* "Do you want to attend workshop?" Row */}
      <Grid item xs={12}>
        <Typography variant="body1">Do you want to attend workshop?</Typography>
        <RadioGroup
          row
          aria-label="workshopSelection"
          name="workshopSelection"
          value={workshopSelection}
          onChange={handleWorkshopSelectionChange}
         
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes"  disabled />
          <FormControlLabel value="no" control={<Radio />} label="No"  disabled/>
        </RadioGroup>
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper} className="category-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>WORKSHOP NAME</TableCell>
                <TableCell>TIME</TableCell>
                <TableCell>VENUE</TableCell>
                <TableCell>SEATS</TableCell>
                <TableCell>AVAILABLE SEATS</TableCell>
                <TableCell>ISA MEMBER</TableCell>
                <TableCell>NON ISA MEMBER</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
             
              {workshopList?.Workshop && workshopList.Workshop.map((workshop: any) => (
                <TableRow key={workshop.name}>
                  <TableCell>{workshop.name}</TableCell>
                  <TableCell>{workshop.time}</TableCell>
                  <TableCell>{workshop.venue}</TableCell>
                  <TableCell>{workshop.max_no_of_attendee}</TableCell>
                  <TableCell>{workshop.available_seat?workshop.available_seat:0}</TableCell>
                  
                  {workshop.amounts.map((amount: any, index: any) => (
                  <TableCell key={amount.type}>
                    <RadioGroup
                      aria-label={`radio-group-${workshop.name}-${amount.type}`}
                      value={selectedOption}
                      onChange={() => handleRadioChange(workshop.name, amount.type, amount.value, workshopSelection)}
                    >
                     <FormControlLabel
                      value={`${workshop.name}-${amount.type}`}
                      control={<Radio />}
                      label={`Rs. ${amount.value.toString()}`}
                      disabled={workshopSelection === 'no' ||(workshop.available_seat === 0) ||(!workshop.available_seat)||(isaMember && index === 1) || (!isaMember && index === 0)||(index==0)||(index==1)}
                      />
                    </RadioGroup>
                 </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default WorkshopTable;
