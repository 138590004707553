import React from 'react';
import { Grid,FormHelperText,FormControl, } from '@mui/material';
import { Controller } from 'react-hook-form';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

interface LayoutProps {
  control: any;
  defaultValue: any;
  name: string;

  rules:any; // Accept icon as a prop
  id:any;
}

const NewsDate = ({ control, name, defaultValue,rules,id }: LayoutProps) => {
   // Helper function to format date to YYYY/MM/DD
  const formatToDate = (dateString: string) => {
   
    return dayjs(dateString, 'MM/DD/YYYY').format('YYYY-MM-DD');
  };
  

  // Helper function to format date to MM/DD/YYYY
  const formatToMMDDYYYY = (date: Date | null) => {

  return date ? dayjs(date).format('YYYY-MM-DD') : null;
};

  return (
    <Controller 
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field ,fieldState}) => (
        <Grid>
        <Grid >
      
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid id={name}>
          <FormControl fullWidth>
                  <DatePicker
                    
                    format='DD-MM-YYYY'
                    // value={field.value}
                    //  defaultValue={dayjs('2022-04-12')} // Convert MM/DD/YYYY to YYYY/MM/DD
                    defaultValue={dayjs(defaultValue)}
                  onChange={(date:any) => {
                    field.onChange(formatToMMDDYYYY(date))}} // Handle date change
                  className="customSelectdate dte"
                  autoFocus={fieldState.error?true:false}
               
              />
              </FormControl>
            </Grid>
            </LocalizationProvider>
        </Grid>
        <FormHelperText   error={!!fieldState.error} >
        {fieldState.error?.message}
      </FormHelperText>
      </Grid>
      )}
    />
  );
};

export default NewsDate;
