import { Grid, Typography } from '@mui/material'
import React from 'react'
import './TermsandCondition.scss'
function TermsandCondition() {
  return (
    <Grid >
      <Grid container item xs={12}>
        <Grid className='cs-grid'>
          <Grid item xs={12} className='main-grid-privacy'>
            <img src='https://www.isathrissur.com/img.jpg' className='image-privacy'></img>
            <h1 className='heading-privacy'>Terms and Conditions</h1>
          </Grid>


        </Grid>
        <Grid className='container-privacy'>
          <Grid container  >
            <Grid item xs={12} md={12} > <br /><br />
              <Typography variant='h6' className='privacy-h6'>Terms and Conditions</Typography>

              <Typography variant='body1' className='paragraph-privacy'>These terms and conditions outline the rules and regulations for the use of isathrissur.'s Website, located at https://www.isathrissur.com..</Typography>
              <Typography variant='body1' className='paragraph-privacy'>
                By accessing this website we assume you accept these terms and conditions. Do not continue to use https://www.isathrissur.com/ if you do not agree to take all of the terms and conditions stated on this page.</Typography>

              <Typography variant='body1' className='paragraph-privacy'>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of in. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</Typography>



              <Typography variant='h5' className='privacy-h5'>Cookies</Typography>

              <Typography variant='body1' className='paragraph-privacy'>We employ the use of cookies. By accessing https://www.isathrissur.com/, you agreed to use cookies in agreement with the isathrissur.'s Privacy Policy.</Typography>
              <Typography variant='body1' className='paragraph-privacy'>Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
              </Typography>

              <Typography variant='h5' className='privacy-h5'>License</Typography>
              <Typography variant='body1' className='paragraph-privacy'>Unless otherwise stated, isathrissur. and/or its licensors own the intellectual property rights for all material on https://www.isathrissur.com/. All intellectual property rights are reserved. You may access this from https://www.isathrissur.com/ for your own personal use subjected to restrictions set in these terms and conditions.

              </Typography>
              <Typography variant='body1' className='paragraph-privacy'>  You must not: </Typography>

              <Typography variant='body1' className='paragraph-privacy'> Republish material from https://www.isathrissur.com/</Typography>
              <Typography variant='body1' className='paragraph-privacy'>Sell, rent or sub-license material from https://www.isathrissur.com/

              </Typography>
              <Typography variant='body1' className='paragraph-privacy'>Reproduce, duplicate or copy material from https://www.isathrissur.com/  </Typography>

              <Typography variant='body1' className='paragraph-privacy'>Redistribute content from https://www.isathrissur.com/</Typography>
              <Typography variant='body1' className='paragraph-privacy'>This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Free Terms and Conditions Generator.</Typography>
              <Typography variant='body1' className='paragraph-privacy'>isathrissur. reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</Typography>
              <br />
              <Typography variant='body1' className='paragraph-privacy'>You warrant and represent that:</Typography>
              <ul className='paragraph-privacy'>
                <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
                <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party;</li>
                <li>The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material which is an invasion of privacy;</li>
                <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
              </ul>
              <Typography variant='body1' className='paragraph-privacy'>You hereby grant isathrissur. a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</Typography>

              <Typography variant='h6' className='privacy-h6'> Hyperlinking to our Content</Typography>
              <Typography variant='body1' className='paragraph-privacy'>The following organizations may link to our Website without prior written approval:</Typography>
              <Typography variant='body1' className='paragraph-privacy'>Government agencies;</Typography>
              <Typography variant='body1' className='paragraph-privacy'> Search engines; </Typography>
              <Typography variant='body1' className='paragraph-privacy'>News organizations;</Typography>
              <Typography variant='body1' className='paragraph-privacy'>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</Typography>
              <Typography variant='body1' className='paragraph-privacy'> These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.   </Typography>
              <Typography variant='body1' className='paragraph-privacy'>We may consider and approve other link requests from the following types of organizations:</Typography>
              <Typography variant='body1' className='paragraph-privacy'>commonly-known consumer and/or business information sources;</Typography>
              <Typography variant='body1' className='paragraph-privacy'>dot.com community sites;</Typography>
              <Typography variant='body1' className='paragraph-privacy'> associations or other groups representing charities; </Typography>
              <Typography variant='body1' className='paragraph-privacy'>online directory distributors;</Typography>
              <Typography variant='body1' className='paragraph-privacy'>internet portals;</Typography>
              <Typography variant='body1' className='paragraph-privacy'>  accounting, law and consulting firms; and</Typography>
              <Typography variant='body1' className='paragraph-privacy'>educational institutions and trade associations.</Typography>
              <Typography variant='body1' className='paragraph-privacy'>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of isathrissur.; and (d) the link is in the context of general resource information.</Typography>
              <Typography variant='body1' className='paragraph-privacy'>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.</Typography>
              <Typography variant='body1' className='paragraph-privacy'>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to isathrissur.. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</Typography>
              <Typography variant='body1' className='paragraph-privacy'> Approved organizations may hyperlink to our Website as follows:</Typography> <br />
              <Typography variant='body1' className='paragraph-privacy'>  By use of our corporate name; or </Typography>
              <Typography variant='body1' className='paragraph-privacy'>By use of the uniform resource locator being linked to; or</Typography>
              <Typography variant='body1' className='paragraph-privacy'>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.</Typography>
              <Typography variant='body1' className='paragraph-privacy'>No use of isathrissur.'s logo or other artwork will be allowed for linking absent a trademark license agreement.</Typography>
              <Typography variant='h6' className='privacy-h6'>iFrames</Typography>
              <Typography variant='body1' className='paragraph-privacy'>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</Typography>
              <Typography variant='h6' className='privacy-h6'>Content Liability</Typography>
              <Typography variant='body1' className='paragraph-privacy'>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</Typography>
              <Typography variant='h6' className='privacy-h6'>Reservation of Rights</Typography>
              <Typography variant='body1' className='paragraph-privacy'>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</Typography>
              <Typography variant='h6' className='privacy-h6'>Removal of links from our website</Typography>
              <Typography variant='body1' className='paragraph-privacy'> If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly. </Typography>
              <Typography variant='body1' className='paragraph-privacy'>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</Typography>
              <Typography variant='h6' className='privacy-h6'>Disclaimer</Typography>
              <Typography variant='body1' className='paragraph-privacy'>   To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will: </Typography>
              <Typography variant='body1' className='paragraph-privacy'> limit or exclude our or your liability for death or personal injury;</Typography>
              <Typography variant='body1' className='paragraph-privacy'>     limit or exclude our or your liability for fraud or fraudulent misrepresentation;        </Typography>
              <Typography variant='body1' className='paragraph-privacy'> limit any of our or your liabilities in any way that is not permitted under applicable law; or</Typography>
              <Typography variant='body1' className='paragraph-privacy'>exclude any of our or your liabilities that may not be excluded under applicable law.</Typography>
              <Typography variant='body1' className='paragraph-privacy'>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</Typography>
              <Typography variant='body1' className='paragraph-privacy'>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature</Typography>
              <Typography variant='h6' className='privacy-h6'>  Shipping & Delivery Policy </Typography>
              <Typography variant='body1' className='paragraph-privacy'>  We'd love to hear from you. Reach out using the information below. </Typography> <br />
              <Typography variant='body1' className='paragraph-privacy-gray'>  Shipping is not applicable for business. </Typography>
              <Typography variant='h6' className='privacy-h6'>Return Policy</Typography>
              <Typography variant='body1' className='paragraph-privacy'>Refunds </Typography>
              <Typography variant='body1' className='paragraph-privacy-gray'>All sales are final and no refund will be issued.</Typography>
              <Typography variant='h4' className='privacy-h5'> Acceptance Details</Typography><br />
              <Grid item xs={6}>
                <table>

                  <tbody className='table-terms'>
                    <tr>
                      <td className='table-terms-td'>Owner Id</td>
                      <td>N30PyzK97eqHxU</td>
                    </tr>
                    <tr>
                      <td className='table-terms-td'>Business Name</td>
                      <td>ISA THRISSUR CITY BRANCH</td>
                    </tr>
                    <tr>
                      <td className='table-terms-td'>IP Address</td>
                      <td>10.26.240.123</td>
                    </tr>
                    <tr>
                      <td className='table-terms-td'>Date Of Acceptance</td>
                      <td>2023-11-21 11:15:59 IST</td>
                    </tr>
                    <tr>
                      <td className='table-terms-td'>Signatory Name</td>
                      <td>SAJAN KANNAMPULLY SEBASTIAN</td>
                    </tr>
                    <tr>
                      <td className='table-terms-td'>Contact Number</td>
                      <td>+919188008001</td>
                    </tr>
                    <tr>
                      <td className='table-terms-td '>Email</td>
                      <td className='link-privacy'>isathrissurcitybranch@gmail.com</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <br />

              <Typography variant='h4' className='privacy-h5'> Registration Fee</Typography> <br />
              <Grid item xs={6}>
                <table>

                  <tbody className='table-terms'>
                    <tr>
                      <td className='table-terms-td'>Member Type</td>
                      <td>Fee(Rupees/INR)</td>
                    </tr>
                    <tr>
                      <td className='table-terms-td'>ISA Member</td>
                      <td>
                        4000/-</td>
                    </tr>
                    <tr>
                      <td className='table-terms-td'>PG Member</td>
                      <td>3500/-</td>
                    </tr>
                    <tr>
                      <td className='table-terms-td'>Non ISA Member</td>
                      <td>
                        8000/-</td>
                    </tr>
                    <tr>
                      <td className='table-terms-td'>Non ISA Member PG</td>
                      <td>7000/-</td>
                    </tr>

                  </tbody>
                </table>
              </Grid><br /><br />






            </Grid>
          </Grid>  </Grid></Grid></Grid>
  );
}



export default TermsandCondition;