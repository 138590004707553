import { Grid, MenuItem, Select as MuiSelect, Typography, Button, FormControl, FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import './EditMember.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import EditMemberTextField from './EditMemberTextField';
import SidebarToggle from '../Common/SidebarToggle';
import HeaderWithLogout from '../Common/Headerdesktop';
import DashboardIcon from '@mui/icons-material/Dashboard';
import editMemberAPIService from '../../../core/service/EditMemberApiService';
import Formdate from './Date'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FieldValues, Control } from "react-hook-form";


import Select from "react-select";
import apiClient from '../../../API/API-client';
let rowData: any;
const EditMemberKATCON = () => {
    const navigate = useNavigate();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^\d{10}$/;
    const pincodeRegex = /^\d{6}$/;
    const [token, setToken] = useState("");
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const [town, setTown] = useState([]);
    const [rowData, setRowData] = useState<any | null>(null);

    //
    const [imageSrc, setImageSrc] = useState('');
    const [isDocumentId,setIsDocumentId] = useState(false)

  
    useEffect(() => {
        // Retrieve data from session storage
        const rowDataString = sessionStorage.getItem('editrowData');
        console.log("<<< dataa > ",rowDataString);
        if (rowDataString) {
            const rowData = JSON.parse(rowDataString);

            setRowData(rowData);
            // Use the rowData as needed in your component
          
            //call method to fetch image 
            const document_id=rowData.attendees[0].document_id;
            if (document_id) {
                fetchImage(document_id);
                setIsDocumentId(true)
            }  

            sessionStorage.removeItem('editrowData'); 
        }
    }, [rowData]);


    //method to fetch image url

    const fetchImage = async (docId:any) => {
        try {
            const response = await apiClient.get(`admin/download/${docId}`, {
                responseType: 'blob' // Ensure the response is treated as a Blob
            });
    
            // Create a URL from the Blob
            const imageUrl = URL.createObjectURL(response.data);
            setImageSrc(imageUrl);
        } catch (error) {
            console.error("Error fetching image:", error);
        }
    };

    const getAuthToken = async () => {
       
        const apiUrl = "https://www.universal-tutorial.com/api/getaccesstoken";
        const apiToken =
            "gZ6eNDKLsqCqS9BB6zYKq0oQBYL_t5fvzprVlBZL716FQRcqn36LdeoNHi-lgda6esk";
        // const apiToken = 'AuXnFjES43NqbdODZoc1anLtpO9op_9HsA7hqU56HJoxlbbNrMsUAzmsp6cqoZ0HhWQ';
        const userEmail = "athul@nivid.co";
        // const userEmail = 'abc@gmail.com';

        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "api-token": apiToken,
                "user-email": userEmail,
            },
        };

        try {
            const response = await fetch(apiUrl, requestOptions);
            const data = await response.json();

           
            setToken(data.auth_token);
            await getCountries(data.auth_token);
            return data.auth_token;
        } catch (error) {
            console.error("Error fetching auth token:", error);
           // getAuthToken();
        }
    };



    const getCountries = async (token: any) => {
      
        const apiUrl = "https://www.universal-tutorial.com/api/countries/";

        const apiToken = "Bearer" + " " + token;

        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                Accept: "application/json",
                authorization: apiToken,
            },
        };

        try {
            const response = await fetch(apiUrl, requestOptions);
            const data = await response.json();
            setCountry(data);
         
            await getStates(rowData.country, token);
        } catch (error) {
            console.error("Error fetching auth token:", error);
            throw error;
        }
    };

    const getStates = async (country: any, token1?: any) => {
        
        const apiUrl = `https://www.universal-tutorial.com/api/states/${country}`;
        let apiToken;
        if (token) {

            apiToken = "Bearer" + " " + token;
        } else {

            apiToken = "Bearer" + " " + token1;
        }

        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                Accept: "application/json",
                authorization: apiToken,
            },
        };
       
        try {
            const response = await fetch(apiUrl, requestOptions);
            const data = await response.json();
            
            setState(data);
            if (token1) {
              await  getTown(rowData.state, token1);
            }
        } catch (error) {
            console.error("Error fetching auth token:", error);
            throw error;
        }
    };

    const getTown = async (town: any, token1?: any) => {
        const apiUrl = `https://www.universal-tutorial.com/api/cities/${town}`;

        // const apiToken = "Bearer" + " " + token;
        let apiToken;
        if (token) {

            apiToken = "Bearer" + " " + token;
        } else {

            apiToken = "Bearer" + " " + token1;
        }
        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                Accept: "application/json",
                authorization: apiToken,
            },
        };

        try {
            const response = await fetch(apiUrl, requestOptions);
            const data = await response.json();

            setTown(data);
        } catch (error) {
            console.error("Error fetching auth token:", error);
            throw error;
        }
    };


    const onSubmit = async (data: any) => {
    
       
        try {

            
            const confirmed = window.confirm("Data edited Successfully");
            if (confirmed) {

                const response = await editMemberAPIService.EditMemberList(data, rowData.attendees[0].id, rowData.company_id,rowData.city);
                navigate("/isaadmin/memberlist")
            }
            else {
               // window.location.reload();
               navigate("/isaadmin/memberlist")
            }
        } catch (error) {

        }
    };

    // const location = useLocation()
    // if (location && location.state) {
    //     rowData = location.state;
    //     console.log("logggg", rowData);

    // }
  
    
    const { control, handleSubmit,setValue } = useForm();



    return (
    <Grid container className='container-center'>
        <Grid container spacing={0}>


            <Grid item xs={1} className='sidebar'>
                <SidebarToggle />
            </Grid>



            <Grid item xs={12} sm={11} ><br />
                <HeaderWithLogout title="Dashboard/Edit" icon={<DashboardIcon
                    className='dashboard-icon' />} />
                <Typography variant='h4'> EDIT REGISTRATION FORM</Typography>
                <br></br>
                <form >
                    {rowData &&
                        <>
                            <Grid container item xs={12} spacing={0} >
                                <Grid  item md={12} sm={12} >

                                    <Grid className='memberview-container'>
                                        <h2 className='h4 '>A.Personal Information</h2>
                                        <br /><br />

                                        <Grid container item spacing={0} className='edit1'>

                                            <Grid item  xs={12} sm={3}  className='memberview-input responsive-input'>
                                                <Typography variant='body1' className='label-memberview'>First Name*</Typography>
                                                <EditMemberTextField
                                                    name="firstname"
                                                    control={control}
                                                    defaultValue={rowData.firstname}

                                                    rules={{ required: "This field is required" }}
                                                />

                                            </Grid>
                                            <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                <Typography variant='body1' className='label-memberview'>Middle Name</Typography>
                                                <EditMemberTextField
                                                    name="middlename"
                                                    control={control}
                                                    defaultValue={rowData.middlename}

                                                    rules={{}}
                                                />

                                            </Grid>
                                            <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                <Typography variant='body1' className='label-memberview'>Last Name</Typography>
                                                <EditMemberTextField
                                                    name="lastname"
                                                    control={control}
                                                    defaultValue={rowData.lastname}

                                                    rules={{ }}
                                                />
                                            </Grid>
                             
                                            <Grid item xs={12} sm={3} className='memberview-input responsive-input' >
                                                <Typography variant='body1' className='label-memberview'>Institute</Typography>
                                                <EditMemberTextField
                                                    name="institute"
                                                    control={control}
                                                    defaultValue={rowData.institute}

                                                    rules={{}}
                                                />
                                            </Grid>
              
                                            <Grid item xs={12} sm={6} md={4} lg={3} className='memberview-input responsive-input'>
                                                <Typography variant='body1' className='label-memberview'>Date of Birth*</Typography>
                                                <Formdate
                                                    id={""}
                                                    name="dob"
                                                    control={control}
                                                    defaultValue={rowData.dob}
                                                    rules={{ required: "This field is required" }}
                                                />
                                            </Grid>
                   
                                            <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                <Typography variant='body1' className='label-memberview'>Gender*</Typography>

                                                <Controller
                                                    name="gender"
                                                    control={control}
                                                    defaultValue={rowData.gender}

                                                    render={({ field }) => (
                                                        <MuiSelect id="gender" {...field} required className='input-select-edit'>

                                                            <MenuItem value='Male'>Male</MenuItem>
                                                            <MenuItem value='Female'>Female</MenuItem>

                                                        </MuiSelect>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3} className='memberview-input'>
                                                        <Typography variant='body1' className='label-memberview'>Email*</Typography>
                                                        <EditMemberTextField
                                                            name="email"
                                                            control={control}
                                                            defaultValue={rowData.email}

                                                            rules={{
                                                                required: "This field is required",
                                                                validate: {
                                                                    validEmail: (value: any) =>
                                                                        emailRegex.test(value) || "Invalid email address",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>Whatsapp No*</Typography>
                                                        <EditMemberTextField
                                                            name="phone"
                                                            control={control}
                                                            defaultValue={rowData.phone}

                                                            rules={{
                                                                required: "This field is required",
                                                                validate: {
                                                                    validMobile: (value: any) =>
                                                                        mobileRegex.test(value) || "Invalid mobile number",
                                                                },
                                                            }}

                                                        />
                                                    </Grid>
                                        </Grid>

                                       


                                    </Grid><br />

                             

                                    <Grid container spacing={1}>
                                        <Grid item sm={12} xs={12}>
                                            <Grid className='memberview-container'>
                                                <h2 className='h4 '>D.Amount Details</h2>
                                                <br /><br />
                                                <Grid container spacing={1} className='edit1'>
                                                    <Grid item xs={12} sm={3} className='memberview-input responsive-input' >
                                                        <Typography variant='body1' className='label-memberview'>Amount in INR</Typography>

                                                        <EditMemberTextField
                                                            name="amount"
                                                            control={control}
                                                            defaultValue={rowData.attendees[0].amount}
                                                            rules={{ required: "This field is required" }} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>Payment Status*</Typography>
                                                        <Controller
                                                            name="payment_status"
                                                            control={control}
                                                            defaultValue={rowData.attendees[0].payment_status}
                                                            render={({ field }) => (
                                                                <MuiSelect id="payment_status" {...field} required className='input-select-edit'>
                                                                    <MenuItem value='PAID'>PAID</MenuItem>
                                                                    <MenuItem value="PENDING">PENDING</MenuItem>

                                                                </MuiSelect>
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} className='memberview-input' >
                                                        <Typography variant='body1' className='label-memberview'>Category*</Typography>

                                                        <Controller
                                                            name="category"
                                                            control={control}
                                                            defaultValue={rowData.category}
                                                            render={({ field }) => (
                                                                <MuiSelect id="category" {...field} required className='input-select-edit'>
                                                                    <MenuItem value="Working">Working</MenuItem>
                                                                    <MenuItem value="Student">Student</MenuItem>
                                                                </MuiSelect>
                                                            )}
                                                        />
                                                    </Grid>
                                                    


                                                   <br /><br />
                                                </Grid><br /><br />


                                            </Grid>

                                        </Grid>
                                    </Grid><br />
                                    { isDocumentId && <Grid container spacing={1}>
                                        <Grid item sm={12} xs={12}>
                                        <Grid item xs={12} sm={12} className="memberview-container">
                                        <h2 className='h4 '>Uploaded Document</h2><br /><br />
                                        <img className="id-document-display" src={imageSrc} alt="Document" /><br />
                                        </Grid>
                                        </Grid>
                                    </Grid>}
                                </Grid>
                            </Grid><br /><br />
                            <Grid item xs={12}>
                                <Button onClick={handleSubmit(onSubmit)} className='big-vertical-button pulse-button'>Submit</Button>
                            </Grid><br />
                        </>}
                </form ></Grid></Grid ></Grid>);
}

export default EditMemberKATCON;
