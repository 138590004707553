import { Grid,FormHelperText,FormControl, } from '@mui/material';
import { Controller } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

interface LayoutProps {
  control: any;
  defaultValue: any;
  name: string;
  rules:any; 
  id:any;
  label:any;
}

const Formdatepicker = ({ control, name, defaultValue,rules,label }: LayoutProps) => {
   // Helper function to format date to YYYY/MM/DD
   const formatToDate = (dateString: string | null) => {
    return dayjs(dateString, 'MM/DD/YYYY').format('YYYY-MM-DD');
  };

  // Helper function to format date to MM/DD/YYYY
const formatToMMDDYYYY = (date: Date | null) => {
  return date ? dayjs(date).format('YYYY-MM-DD') : null;
};

  return (
    <Controller 
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field ,fieldState}) => (
        <Grid>
        <Grid>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid id={name}>
          <FormControl fullWidth>
              <DatePicker
              label={label}
               value={formatToDate(field.value)} // Convert MM/DD/YYYY to YYYY/MM/DD
                  onChange={(date:any) => {
                    field.onChange(formatToMMDDYYYY(date))}} // Handle date change
                  autoFocus={fieldState.error?true:false}
              />
              </FormControl>
            </Grid>
            </LocalizationProvider>
        </Grid>
        <FormHelperText error={!!fieldState.error}>
        {fieldState.error?.message}
      </FormHelperText>
      </Grid>
      )}
    />
  );
};

export default Formdatepicker;
