import {
    Grid, Typography, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Paper,
    Button,
    TextField
} from "@mui/material";
import React, { useEffect } from "react";
import RegHeader from "./registration_header";
import { useLocation } from 'react-router-dom';
import Footer from "../Common/Footer";
import { useNavigate } from "react-router-dom";

import registerApiService from "../../core/service/RegistrationAPIService";
import RazorpayService from "../../core/service/RazorpayService";
import { EmailOutlined, PhoneAndroidOutlined, SearchOffOutlined, SearchOutlined, SearchRounded } from "@mui/icons-material";
import FormField from "./FormField";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import adminApiService from "../../core/service/AdminApiService";
import { color } from "framer-motion";

let amountdata: any
const RetryPayment: React.FC = () => {
    const [loading, setLoading] = React.useState(false);
    const { control, handleSubmit, setValue } = useForm();
    const [amountData, setAmountData] = React.useState<any>(null);
    const [paymentfailure, setPaymentfailure] = React.useState(false);
    const [showGrid, setShowGrid] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const location = useLocation();


    const navigate = useNavigate()
    const handlePaymentFailure = async(details:any,errorCode:any) => {
        console.log('Payment failure! Navigating to failure page...');
       
        setPaymentfailure(true);
        const response = await registerApiService.updatePaymentError(details,errorCode);
        
        // Navigate to the failure page
        // navigate('/paymentfailure', { state: amountdata.totalfee });
    };
    useEffect(() => {

    }, [amountData]);
    const backToHome = () => {
        navigate('/');
    }
    const handleCheckoutClick = async () => {
        setLoading(true);
        
        
        const responseFromRazorpay = await RazorpayService.retryPaymentRazorpay(amountData, amountData.totalfee, handlePaymentFailure); //totalfee
        // Navigate to another page upon button click
        if (responseFromRazorpay.razorpay_payment_id) {
            setLoading(false);
            const response = await registerApiService.updatePaymentRegistration(amountData, amountData.totalfee,responseFromRazorpay.razorpay_payment_id);
           
            if (response.status == "success") {
                navigate('/done', { state: {
                    totalFee: amountData.totalfee,
                    razorpayDetails: {
                      paymentId: responseFromRazorpay.razorpay_payment_id
                     
                    },
                  }, });
            } else {
                navigate('/paymentfailure', { state: amountData.totalfee });
                alert("Your Payment is not completed..Please contact admin@isathrissur.com")
            }
        }
        else {
            navigate('/paymentfailure', { state: amountData.totalfee });
            alert("Your Payment is not completed..Please contact admin@isathrissur.com")
        }
    };
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 10,
        page: 0,
    });
    const onSubmit: SubmitHandler<any> = async (searchdata: any) => {
        try {
        
            setLoading(true);
            const { phone, memberemail } = searchdata;

            // Check if both phone and memberemail are empty
            if (!phone && !memberemail) {
                setShowGrid(false);
                setShowError(false);
                alert('Please enter either phone or email.'); // Adjust this message as needed
                return;
            }

            //setValue('email', data.email);
            // Call the API with your request data
            const resp = await adminApiService.memberList(searchdata, paginationModel);
           
            if (resp.status == "success") {
                setShowGrid(true);
                setShowError(false);
                if (resp.data.length > 0) {
                    setAmountData({
                        attendee_id:resp.data[0].attendees[0].id,
                        name: resp.data[0].name,
                        registration_no: "KATCON" + resp.data[0].id,
                        isa_no: resp.data[0].registration_no,
                        email:resp.data[0].email,
                        phone:resp.data[0].phone,
                        CategoryName: resp.data[0].category,
                        selectedAmount: resp.data[0].attendees.amount,
                        WorkshopName: resp.data[0].attendees[0].workshop_id,
                        selectedType: resp.data[0].attendees[0].type,
                        paymentStatus: resp.data[0].attendees[0].payment_status,
                        totalfee: resp.data[0].attendees[0].amount,
                    });
                }
                else {
                    setShowGrid(false);
                    setShowError(true);
                }
            }
            else {
                setShowGrid(false);
            }
        } catch (error) {
            setShowGrid(false);
            console.error("Error during data fetch:", error);
        }
    }
    return (
        <Grid>
            {/* <RegHeader /> */}
            <Grid className="containercheckout">
                <Grid >
                    <h2 className="heading-retry">PAYMENTS</h2>
                </Grid>
                <Grid>
                    <Grid spacing={3} className="amountcontainer"  >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}  >
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={10} sm={3}>
                                        <Controller
                                            name="phone"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                pattern: {
                                                  value: /^[0-9]{10}$/, // Adjust the regex pattern based on your phone number format
                                                  message: 'Invalid phone number',
                                                },
                                              }}
                                            render={({ field, fieldState }) => (
                                                <TextField
                                                    {...field}
                                                    label="Search using phone"
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                                    InputProps={{
                                                        startAdornment: <PhoneAndroidOutlined />,
                                                    }}
                                                    style={{ height: '47px' }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={10} sm={3}>
                                        <Controller
                                            name="memberemail"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                // required: 'This field is required',
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: 'Invalid email address',
                                                },
                                            }}
                                            render={({ field, fieldState }) => (
                                                <TextField
                                                    {...field}
                                                    label="Search using email id"
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                                    InputProps={{
                                                        startAdornment: <EmailOutlined />,
                                                    }}
                                                    style={{ height: '47px' }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className="search-button"
                                            fullWidth
                                        >
                                            <SearchOutlined />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                        <br />  <br />
                        {showError && (
                            <Grid container xs={12} justifyContent="center" >
                           <Grid item></Grid>
                            <Grid item xs={12} sm={6} md={4}  style={{ backgroundColor: '#ffcccc', padding: '5px', borderRadius: '5px', marginTop: '10px' }}>
                                <h4 style={{ color: 'red' }}> * Please Enter Correct Details</h4>
                            </Grid>
                            </Grid>
                        )}
                        {showGrid && (
                            <Grid item xs={12} sm={6} md={4} justifyContent="center" >
                                
                                <Grid item xs={10}>
                                    <TableContainer component={Paper} className="retry-table">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Particulars</TableCell>
                                                    <TableCell >Details</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {amountData?.name ?
                                                    <TableRow>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>{amountData?.name}</TableCell>
                                                    </TableRow>
                                                    : <div></div>}
                                                {amountData?.registration_no ?
                                                    <TableRow>
                                                        <TableCell>Registration No</TableCell>
                                                        <TableCell>{amountData?.registration_no}</TableCell>
                                                    </TableRow>
                                                    : <div></div>}
                                                {amountData?.CategoryName ?
                                                    <TableRow>
                                                        <TableCell>Category</TableCell>
                                                        <TableCell>{amountData?.CategoryName}</TableCell>
                                                    </TableRow>
                                                    : <div></div>}
                                                {amountData?.isa_no ?
                                                    <TableRow>
                                                        <TableCell>ISA No</TableCell>
                                                        <TableCell>{amountData?.isa_no}</TableCell>
                                                    </TableRow>
                                                    : <div></div>}

                                                {amountData?.WorkshopName ?
                                                    <TableRow>
                                                        <TableCell>Workshop </TableCell>
                                                        <TableCell>{amountData?.WorkshopName}</TableCell>
                                                    </TableRow>
                                                    : <div></div>}
                                                {amountData?.paymentStatus ?
                                                    <TableRow>
                                                        <TableCell>Payment Status </TableCell>
                                                        <TableCell>{amountData?.paymentStatus}</TableCell>
                                                    </TableRow>
                                                    : <div></div>}
                                                {amountData?.totalfee ?
                                                    <TableRow>
                                                        <TableCell>Total Amount</TableCell>
                                                        <TableCell>Rs. {amountData?.totalfee}</TableCell>
                                                    </TableRow>
                                                    : <div>
                                                    </div>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {/* <Grid>
                    <br /><br />
                    <h1 className="h1">Bank Account Details</h1>
                    <Grid spacing={3} className="bankdetailscontainer">
                        <Grid item xs={12} sm={6} md={4}>

                            <Typography className="textstyle">Account Name: ISA Thrissur City Branch</Typography>
                            <Typography className="textstyle">IFSC:HDFC0004164</Typography>
                            <Typography className="textstyle">Account No:50200043742992</Typography>

                        </Grid>
                    </Grid>
                </Grid> */}
                <br /><br />
                {showGrid &&  amountData?.paymentStatus!='PAID' &&  (

                     <Grid  xs={12} className="payment_button_container"  >
                     <Grid item xs={10} sm={6} md={4} spacing={1} padding={1} >
                         <Button onClick={handleCheckoutClick} className="big-checkout-button" id="checkoutbutton">
                             {paymentfailure == false ? "Complete Payment" : "Try Again..."}
                         </Button>
                     </Grid>
                     {paymentfailure && (
                         <Grid item xs={10} sm={6} md={4} spacing={1} padding={1}>
                             <Button onClick={backToHome} className="big-failure-button" id="checkoutbutton">
                                 Back to Home
                             </Button>
                         </Grid>
                     )}

                 </Grid>
                   
                )}
            </Grid>
            <br /><br />
        </Grid>

    )
}
export default RetryPayment