import apiClient from '../../API/API-client';

class NewsListApiService {
    private static instance: NewsListApiService;

    private constructor() { }

    static getInstance(): NewsListApiService {
        if (!NewsListApiService.instance) {
            NewsListApiService.instance = new NewsListApiService();
        }
        return NewsListApiService.instance;
    }
    async NewsListDataList() {
        
        try {
            let newslistData = {
                "company_id": 1,
            }
             
            const response = await apiClient.post('admin/listnews', newslistData);
            return response.data;
        } catch (error) {
            console.error('Error contacting server:', error);
            throw error;
        }
    }

    async newsDelete(News_id:any) {
     
        try {
          const newsdeleteData = {
            "id": News_id,
          };
          const response = await apiClient.put('admin/deletenews/' +News_id, newsdeleteData);
          return response.data;
        } catch (error) {
          console.error('Error deleting record:',error);
          throw error;
        }
      }
      
      async newsUpdate(data:any,rowData:any,selectedImageid:any) {
      
        try {
          const newsupdateData = {
            "id": rowData.id,
             "title": data.title,
             "subtitle": data.SubHeading,
             "description": data.description,
             "image":selectedImageid,
             "tag1" : data.tag1,
             "tag2" :data.tag2,
             "frame":data.frame,
             "date" : data.date
          };
          const response = await apiClient.put('admin/updatenews', newsupdateData);
          return response.data;
        } catch (error) {
          console.error('Error deleting record:',error);
          throw error;
        }
      }
      
      
}

const newsListApiService = NewsListApiService.getInstance();

export default newsListApiService;
