import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from '../../Assets/images/logo_white.png'
import '../Homescreen/components/home.scss'
import { Grid, Paper } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import logoImg from '../../Assets/images/logo_white.png'
import { useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import CollectionsIcon from '@mui/icons-material/Collections';
import GroupsIcon from '@mui/icons-material/Groups';
import ContactsIcon from '@mui/icons-material/Contacts';
import '../Admin/Common/common.scss'
import { NewspaperOutlined, PaymentsOutlined, PlaylistAddCheckCircleRounded, YouTube } from '@mui/icons-material';
import newsListAPIService from '../../core/service/NewsListUIApiService';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windows?: () => Window;
}


const drawerWidth = 240;
const navItems1 = ['Home', 'Gallery', 'Our Team','News','Payment','Academic Videos', 'Contact Us'];
export default function DrawerAppBar(props: Props) {

 const navigate = useNavigate()
  const { windows } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClick = (item: any) => {
   
    if (item === "Gallery") {
      navigate("/#gallery");
    }

    if (item === "Our Team") {
      navigate("/#ourteam");
    }

    if (item == "Home") {
      navigate("/#home");
    }
    if (item == "News") {
    
     navigate("/newsitem");
    }
    if(item ==="Contact Us")
    {
      navigate("/contactus")
    }
    if(item ==="Payment")
    {
      navigate("/payment") 
    }
    if(item ==="Academic Videos"){
      window.open('https://www.youtube.com/@isathrissur4056/videos', '_blank');
    }
    if (item === "Brochure") {
      window.open('/Assets/ISAMIDKON.pdf', '_blank');
      // Download brochure PDF file
      handleDownload();
    }

  };

  const location = useLocation();

  const isLinkActive = (path: string) => {
    return location.pathname === path;
  };


  const navigateToNewsItem = () => {
    navigate("/newsitem");
  };
  const navigateToAcademic = () => {
    window.open('https://www.youtube.com/@isathrissur4056/videos', '_blank');
  };
  const handleDownload = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = '/Assets/ISAMIDKON.pdf';
    downloadLink.download = "ISAMIDKON.pdf";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'left' }}>

     
      <Paper className='sidebar_home'>
      <Grid container direction="column" spacing={2} >
      <Grid item className='conferenceSummaryContainer'>
          <img src={logoImg} alt="Logo" className='headerlogo' />
        </Grid>
        <Divider className="divider_style" />
        <Grid item>
        <Link to="/#home" className={`linkbutton ${isLinkActive('/#home') ? 'active-link' : ''}`}>
        <Grid className={`sidebar_home_content ${isLinkActive('/#home') ? 'active-sidebar' : ''}`}>
            <Grid container className='itemcenter' spacing={2}>
              <Grid item>
                <HomeIcon className={`${isLinkActive('/#home') ? 'active-icon' : 'sidebariconsstyle '}`} />
              </Grid>
              <Grid item><Typography className={` ${isLinkActive('/#home') ? 'active-text' : 'sidebartitlestyle'}`}>Home</Typography></Grid>
            </Grid>
          </Grid>
          </Link>
        </Grid>
        <Grid item>
        <Link to="/#gallery" className={`linkbutton ${isLinkActive('/#gallery') ? 'active-link' : ''}`}>
        <Grid className={`sidebar_home_content ${isLinkActive('/#gallery') ? 'active-sidebar' : ''}`}>
            <Grid container className='itemcenter' spacing={2}>
              <Grid item>
                <CollectionsIcon className={`${isLinkActive('/#gallery') ? 'active-icon' : 'sidebariconsstyle '}`} />
              </Grid>
              <Grid item><Typography className={` ${isLinkActive('/#gallery') ? 'active-text' : 'sidebartitlestyle'}`}>Gallery</Typography></Grid>
            </Grid>
          </Grid>
          </Link>
        </Grid>
        <Grid item>
        <Link to="/#ourteam" className={`linkbutton ${isLinkActive('/#ourteam') ? 'active-link' : ''}`}>
          <Grid className='sidebar_home_content'>
            <Grid container className='itemcenter' spacing={2}>
              <Grid item>
                <GroupsIcon className={`sidebariconsstyle ${isLinkActive('/#ourteam') ? 'active-icon' : ''}`}/>
              </Grid>
              <Grid item><Typography className={`sidebartitlestyle ${isLinkActive('/#ourteam') ? 'active-text' : ''}`}>Our Team</Typography></Grid>
            </Grid>
          </Grid>
          </Link>
        </Grid>
        
        <Grid item onClick={navigateToNewsItem} >
        <Link to="/newsitem" className={`linkbutton ${isLinkActive('/newsitem') ? 'active-link' : ''}`}>
          <Grid className='sidebar_home_content'>
            <Grid container className='itemcenter' spacing={2}>
              <Grid item >
                <NewspaperOutlined className={`sidebariconsstyle ${isLinkActive('/newsitem') ? 'active-icon' : ''}`}/>
              </Grid>
              <Grid item><Typography className={`sidebartitlestyle ${isLinkActive('/newsitem') ? 'active-text' : ''}`}>News</Typography></Grid>
            </Grid>
          </Grid>
          </Link>
        </Grid>

        <Grid item onClick={navigateToAcademic}>
        <Link to="/#home" className={`linkbutton ${isLinkActive('/#home') ? 'active-link' : ''}`}>
          <Grid className='sidebar_home_content'>
            <Grid container className='itemcenter' spacing={2}>
              <Grid item>
                <YouTube className={`sidebariconsstyle ${isLinkActive('/#home') ? 'active-icon' : ''}`}/>
              </Grid>
              <Grid item><Typography className={`sidebartitlestyle ${isLinkActive('/#home') ? 'active-text' : ''}`}>Academic Videos</Typography></Grid>
            </Grid>
          </Grid>
          </Link>
        </Grid> 
        <Grid item>
        <Link to="/payment" className={`linkbutton ${isLinkActive('/payment') ? 'active-link' : ''}`}>
          <Grid className='sidebar_home_content'>
            <Grid container className='itemcenter' spacing={2}>
              <Grid item>
                <PaymentsOutlined className={`sidebariconsstyle ${isLinkActive('/payment') ? 'active-icon' : ''}`}/>
              </Grid>
              <Grid item><Typography className={`sidebartitlestyle ${isLinkActive('/payment') ? 'active-text' : ''}`}>Payment</Typography></Grid>
            </Grid>
          </Grid>
          </Link>
        </Grid>
        <Grid item>
        <Link to="/contactus" className={`linkbutton ${isLinkActive('/contactus') ? 'active-link' : ''}`}>
          <Grid className='sidebar_home_content'>
            <Grid container className='itemcenter' spacing={2}>
              <Grid item>
                <ContactsIcon className={`sidebariconsstyle ${isLinkActive('/contactus') ? 'active-icon' : ''}`}/>
              </Grid>
              <Grid item><Typography className={`sidebartitlestyle ${isLinkActive('/contactus') ? 'active-text' : ''}`}>Contact Us</Typography></Grid>
            </Grid>
          </Grid>
          </Link>
        </Grid> 
        <Grid item onClick={handleDownload}>
        <Link to="/#home" className={`linkbutton ${isLinkActive('/#home') ? 'active-link' : ''}`}>
          <Grid className='sidebar_home_content'>
            <Grid container className='itemcenter' spacing={2}>
              <Grid item>
                <YouTube className={`sidebariconsstyle ${isLinkActive('/#home') ? 'active-icon' : ''}`}/>
              </Grid>
              {/* <Grid item><Typography className={`sidebartitlestyle ${isLinkActive('/#home') ? 'active-text' : ''}`}>Brochure</Typography></Grid> */}
            </Grid>
          </Grid>
          </Link>
        </Grid> 
      </Grid>
    </Paper>
    </Box>
  );

  const container = windows !== undefined ? () => windows().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar sx={{ backgroundColor: '#0a136a', position: 'fixed' }}>
        <Toolbar>
         
            <Grid style={{display:'flex', justifyContent:'space-between'}}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>

                <img src={logo} alt="Logo" className="header_logo_appbar" />
             <Box sx={{ display: { xs: 'none', sm: 'block', display: "flex", justifyContent: "center", alignItems: 'center', alignSelf: 'center' } }}>

            {  navItems1.map((item, index) => (
              <Button key={item} sx={{ color: '#fff', marginLeft: index > 0 ? 2 : 0, textTransform: 'capitalize' }} onClick={() => handleClick(item)}>
                <Typography variant='h6'> {item}</Typography>
              </Button>
                ))}
              </Box>
          </Grid>
</Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>

    </Box>
  );
}