import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Grid,Button } from "@mui/material";
import "../../Admin/Registration/addstyles.scss";
import "../../Admin/Common/common.scss";
import "../../Admin/Homescreen/dashboard.scss";
import SidebarToggle from "../Common/SidebarToggle";
import HeaderWithLogout from "../Common/Headerdesktop";
import Dashboard from "@mui/icons-material/Dashboard";
import newsListApiService from "../../../core/service/NewsListApiService";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { BsPlus } from 'react-icons/bs';
import DOMPurify from 'dompurify';

// Define the component
const NewsList: React.FC = () => {
  const navigate = useNavigate();

  const [news, setNewsData] = useState<any>([]);
  const [editedRowId, setEditedRowId] = useState<any | null>(null);
  const [editedStatus, setEditedStatus] = useState<any>('');


  const handleButtonClick = () => {
    navigate('/isaadmin/addnews');
  };

  const columns: GridColDef[] = [
    {
      field: "serialnumber",
      headerName: "ID",
      flex:1,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "title",
      headerName: "Title",
      width: 200,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "subtitle",
      headerName: "Subtitle",
      width: 250,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
   
    {
      field: "frame",
      headerName: "Youtube Frame",
      width: 300,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: 'edit',
      headerName: 'Edit/Delete',
      width: 230, headerClassName: 'headergridcell_blue1',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params: any) => {
        const handleEdit = (rowData: any) => {
          navigate('/isaadmin/updatenews', { state: rowData })
        }
        const handleDelete = async () => {
          let News_id = params.row.id;
         
          const confirmed = window.confirm(`Do you want to delete the news with the title ${params.row.title} ?`);
          if (confirmed) {
            try {
              const response = await newsListApiService.newsDelete(News_id);
              if (response.status === "success") {
                window.location.reload();
              }
            } catch (error) {
              console.error('Error deleting record:', error);
              // Handle error scenario, if needed
            }
          }
        };
        
        return (
          <Grid container spacing={1} alignContent={'center'} style={{ padding: '50px', marginLeft: '8px' }}>
            <Grid item>
              <button className='button_style' onClick={() => handleEdit(params.row)}>
                <EditIcon className='viewiconstyle' />
              </button>
            </Grid>
            <Grid item>
              <button className='button_style1' onClick={() => handleDelete()}>
                <DeleteIcon className='viewiconstyle' />
              </button>
            </Grid>
          </Grid>
        );
        }
      }        
  ];
  const newsList = async () => {
    try {
      
      const apiData = await newsListApiService.NewsListDataList();
      
      if (apiData.data) {
        const dataWithSerialNumbers = apiData?.data?.map((row: any, index: any) => ({
          ...row,
          serialnumber: index + 1,
        }));
  
        setNewsData(dataWithSerialNumbers);
      }
    

      // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error during data fetch:", error);
      
    }
    
  };
  const handleEdit = (rowId: any, initialStatus: any) => {
    setEditedStatus(initialStatus);
    setEditedRowId(rowId);
  };
 
  useEffect(() => {
    newsList();
  }, []);
  const getRowId = (row: any) => {
    if (row.id) {
      return row.id;
    }
    // Return a fallback value if the property is not available
    return '';
  };
  const getRowStyleParams = (params: any) =>
    params.indexRelativeToCurrentPage % 2 === 0 ? 'bg_grey_TableCell ' : 'bg_white_TableCell';
  return (
    <Grid className="dashboard-container">
      <Grid container justifyContent="center">
        <Grid item xs={1} className="sidebar">
          <SidebarToggle />
        </Grid>
        <Grid item xs={11} className="content-container">
          <Grid className="conferenceSummaryContainer">
            <HeaderWithLogout
              title="Dashboard/News Listing"
              icon={<Dashboard className="dashboard-icon" />}
            />
          </Grid><br />
          <Grid container spacing={14}>
            <Grid item xs={12} sm={6} md={3}>
              <Button className='button_add_volunteer' onClick={handleButtonClick}><BsPlus /> News Add</Button></Grid></Grid>
          <Grid className="dashmainContent1 searchformpad">
              <DataGrid
                rows={news ? news : []}
                columns={columns}
                getRowId={getRowId}
                autoHeight
                getRowClassName={getRowStyleParams}
                localeText={{ noRowsLabel: "No data available" }}
                disableColumnMenu={true} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewsList;
