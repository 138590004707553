import React,{useState,useEffect} from 'react';
import { useForm,Controller } from 'react-hook-form';
import { Grid, Button, Typography, Container,TextareaAutosize ,IconButton, Select, FormControl, InputLabel, OutlinedInput, MenuItem} from '@mui/material';
import "../Registration/addstyles.scss";
import '../../Admin/Registration/addstyles.scss';
import '../../Admin/Common/common.scss';
import '../../Admin/Homescreen/dashboard.scss';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HeaderWithLogout from '../Common/Headerdesktop';
import SidebarToggle from '../Common/SidebarToggle';
import "../Registration/addstyles.scss";
import CustomTextField from '../Common/CustomTextField';
import AddvolunteerDataGrid from './AddvolunteerDatagrid';
import volunteerAPIService from '../../../core/service/VolunteerAddApiService';


const Addvolunteer: React.FC = () => {
  const [gridData, setGridData] = useState<any[]>([]);
  const [isSidebarOpen, setSidebarOpen] = useState(false);  // State to track sidebar visibility

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
const { handleSubmit, control } = useForm();
const onSubmit = async (data: any) => {
  try {
    const response = await volunteerAPIService.volunteerDataList(data);
    //console.log("Response from API:", response);
    // Handle success - Update state, show success message, etc.
  } catch (error) {
    //console.error("Error while saving data:", error);
    // Handle error - Show error message, update state accordingly, etc.
  }
};

  return (
    <Grid className="dashboard-container">
    <Grid container justifyContent="center">
    <Grid item xs={1}  className='sidebar'>
      <SidebarToggle/>
    </Grid>

    <Grid item xs={11} className='content-container'>
    <Grid className='conferenceSummaryContainer'>
    <HeaderWithLogout title="Dashboard/Add Volunteer" icon={<DashboardIcon className='dashboard-icon' />} /></Grid>
      <Grid xs={12}  className='dash_paper1'>
      <Grid item xs={12}  sm={12}  >
    <Grid>
  <IconButton onClick={toggleSidebar} className='menu-icon'> {/* Add IconButton with onClick handler */}
                {/* <MenuIcon className='menuiconstyle'/> */}
              </IconButton></Grid>
              <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" className="form-title">
             Volunteer Details Form
            </Typography>
          </Grid>
            <Grid item xs={12} sm={4}>
            <CustomTextField
                name="name"
                label='Volunteer Name'
                control={control}
                type={'text'}
                defaultValue=""
                variant="outlined"
            
              />
            </Grid>
            <Grid item xs={12} sm={4}>
            <CustomTextField
                name="email"
                label='Email'
                control={control}
                type={'email'}
                defaultValue=""
                variant="outlined"
            
              />
            </Grid>
            <Grid item xs={12} sm={4}>
            <CustomTextField
                name="phone"
                label='Phone'
                control={control}
                type={'phone'}
                defaultValue=""
                variant="outlined"
            
              />
            </Grid>
            <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="volunteertype">Volunteer Type</InputLabel>
        <Controller
          name="volunteertype"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Select
              label="Volunteer Type"
              {...field}
              input={<OutlinedInput label="Volunteer Type" />}
            >
              <MenuItem value="entryGate">Entry gate</MenuItem>
              <MenuItem value="foodGate">Food gate</MenuItem>
            </Select>
          )}
        />
      </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
            <CustomTextField
                name="password"
                label='Password'
                control={control}
                type={'text'}
                defaultValue=""
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid>
            <Typography variant="h6" className="form-title Workshop_paddig">
             Volunteer List
            </Typography>
        
      <AddvolunteerDataGrid />  </Grid>
       </Grid>
      </Grid>
     </Grid></Grid></Grid>
  );
};

export default Addvolunteer;