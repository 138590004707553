// Header.js

import React,{useState} from 'react';
import { Grid, Typography, IconButton,Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './common.scss'; // Import styling for the component
import SidebarDesktop from './Sidebar'; // Import the SidebarDesktop component




const Header = () => {
  // State to track the visibility of the sidebar
  const [isSidebarOpen, setSidebarOpen] = useState(false);  // State to track sidebar visibility
// Function to toggle the sidebar visibility
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
   // Render the component
  return (
    <>
   
    <Grid item xs={12} className='menuicongrid'>
       {/* IconButton for the menu icon, onClick triggers toggleSidebar */}
      <IconButton onClick={toggleSidebar} className='menu-icon'>
        <MenuIcon className='menuiconstyle'/>
      </IconButton>
    
    </Grid> 
     {/* Drawer component for the sidebar */}
     <Drawer
        anchor="left"
        open={isSidebarOpen}
        onClose={() => setSidebarOpen(false)}
        variant="temporary"
      >
         {/* Render the SidebarDesktop component within the Drawer */}
        <SidebarDesktop />
      </Drawer></>
    
  );
};

export default Header;
