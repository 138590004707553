import venue1 from "../../../Assets/images/govt2.png";
import venue2 from "../../../Assets/images/govt3.png";
import venue3 from "../../../Assets/images/govt4.png";
import venue4 from "../../../Assets/images/govt.png";
export const countries = [
    {
      image: venue3,
     
    },
    {
      image:venue2,
   
    },
    {
      image: venue1,
   
    },
    {
      image: venue4,
   
    }
  ];