import React,{useState} from 'react';
import { Grid, Paper, Typography ,Drawer} from '@mui/material';
import { AddAPhotoOutlined } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import '../Common/common.scss';
import { Link ,useLocation} from 'react-router-dom';
import logoImg from '../../../Assets/images/logoside.png'
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import SidebarDesktop from './Sidebar';
import FeedIcon from '@mui/icons-material/Feed';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ListAltIcon from '@mui/icons-material/ListAlt';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Tooltip from '@mui/material/Tooltip';


const SidebarToggle: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);  // State to track sidebar visibility

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const location = useLocation();

  const isLinkActive = (path: string) => {
    return location.pathname === path;
  };
  const handleSignout = () => {
    // Clear the authentication information in sessionStorage
    sessionStorage.removeItem('isAuthenticated');
    // Redirect to the login page or perform any other necessary action
    window.location.href = '/isaadmin';
  };
  return (
  
    <Grid className='sidebar_paper'>
      <Grid container direction="column" spacing={2} >
      <Grid item className='conferenceSummaryContainer'>
          <img src={logoImg} alt="Logo" className='headerlogo1' />
        </Grid>
        <Grid item>
        <Link to="/isaadmin/dashboard1" className={`linkbutton ${isLinkActive('/isaadmin/dashboard1') ? 'active-link' : ''}`}>
            <Grid className={`sidebar_paper1 ${isLinkActive('/isaadmin/dashboard1') ? 'active-sidebar' : ''}`}>
              
            <Tooltip title="Dashboard" arrow>
                  <DashboardIcon className={`${isLinkActive('/isaadmin/dashboard1') ? 'active-icon' : 'sidebariconsstyle1 '}`} />
            </Tooltip>
                
            
            </Grid>
          </Link>
        </Grid>
        <Grid item>
        <Link to="/isaadmin/memberlist" className={`linkbutton ${isLinkActive('/isaadmin/memberlist') ? 'active-link' : ''}`}>
        <Grid className={`sidebar_paper1 ${isLinkActive('/isaadmin/memberlist') ? 'active-sidebar' : ''}`}>
        <Tooltip title="MemberList" arrow>
              
                <GroupIcon className={`${isLinkActive('/isaadmin/memberlist') ? 'active-icon' : 'sidebariconsstyle1 '}`} />
            
           </Tooltip>  
        
          </Grid>
          </Link>
        </Grid>
        {/* <Grid item>
        <Link to="/isaadmin/adminregistration" className={`linkbutton ${isLinkActive('/isaadmin/adminregistration') ? 'active-link' : ''}`}>
        <Grid className={`sidebar_paper1 ${isLinkActive('/isaadmin/adminregistration') ? 'active-sidebar' : ''}`}>
        <Tooltip title="Spot Registration" arrow>
                <HowToRegIcon className={`${isLinkActive('/isaadmin/adminregistration') ? 'active-icon' : 'sidebariconsstyle1 '}`} />
          </Tooltip>
          </Grid>
          </Link>
        </Grid> */}
        <Grid item>
        <Link to="/isaadmin/attendeeslist" className={`linkbutton ${isLinkActive('/isaadmin/attendeeslist') ? 'active-link' : ''}`}>
        <Grid className={`sidebar_paper1 ${isLinkActive('/isaadmin/attendeeslist') ? 'active-sidebar' : ''}`}>
            
        <Tooltip title="Attendees List" arrow>
                <SwitchAccountIcon className={`${isLinkActive('/isaadmin/attendeeslist') ? 'active-icon' : 'sidebariconsstyle1 '}`} />
          </Tooltip>  
             
        
          </Grid>
          </Link>
        </Grid>
        <Grid item>
        <Link to="/isaadmin/workshoplist" className={`linkbutton ${isLinkActive('/isaadmin/workshoplist') ? 'active-link' : ''}`}>
        <Grid className={`sidebar_paper1 ${isLinkActive('/isaadmin/workshoplist') ? 'active-sidebar' : ''}`}>
            
        <Tooltip title="Workshop List" arrow>
                <ListAltIcon className={`${isLinkActive('/isaadmin/workshoplist') ? 'active-icon' : 'sidebariconsstyle1 '}`} />
            </Tooltip>

          </Grid>
          </Link>
        </Grid>
        {/* <Grid item>
        <Link to="/isaadmin/conferenceadd" className={`linkbutton ${isLinkActive('/isaadmin/conferenceadd') ? 'active-link' : ''}`}>
          <Grid className='sidebar_paper1'>
              <Tooltip title="Conference Add" arrow>
                <LibraryAddIcon className={`sidebariconsstyle1 ${isLinkActive('/isaadmin/conferenceadd') ? 'active-icon' : ''}`}/>
        </Tooltip>
          </Grid>
          </Link>
        </Grid> */}
        <Grid item>
        <Link to="/isaadmin/newslist" className='linkbutton'>
          <Grid className='sidebar_paper1'>
          <Tooltip title="News List" arrow>
                <FeedIcon className='sidebariconsstyle1'/> 
        </Tooltip>
          </Grid>
          </Link>
        </Grid>
        <Grid item>
        <Link to="/isaadmin/addvolunteer" className='linkbutton'>
          <Grid className='sidebar_paper1'>
          <Tooltip title="Add Volunteer" arrow>
                <PersonAddAlt1Icon className='sidebariconsstyle1'/>
            </Tooltip>  
        
          </Grid>
          </Link>
        </Grid>
        

        <Grid item>
        <Link to="/isaadmin/addgallery" className='linkbutton'>
          <Grid className='sidebar_paper1' >
          <Tooltip title="Add Gallery" arrow>
                <AddAPhotoOutlined  className='sidebariconsstyle1'/>
          </Tooltip>
            
          </Grid>
          </Link>
        </Grid>
        
        <Grid item>
        <Link to="/isaadmin" className='linkbutton' onClick={handleSignout}>
          <Grid className='sidebar_paper1' >
          <Tooltip title="Logout" arrow>
              <LogoutIcon className='sidebariconsstyle1' />
        </Tooltip>
            
            </Grid>
          
          </Link>
          <ArrowCircleRightIcon onClick={toggleSidebar} className='arrow-icon' />
        </Grid>
       
      </Grid>
      <Drawer 
    anchor="left"
    open={isSidebarOpen}
    onClose={() => setSidebarOpen(false)}
    variant="temporary"
  >
    <SidebarDesktop />
  </Drawer>
    </Grid>
   
  );
};

export default SidebarToggle;
