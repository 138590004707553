import { Download } from '@mui/icons-material';
import apiClient from '../../API/API-client';

class WorkShopListAPIService{
    private static instance: WorkShopListAPIService;

    private constructor() {}
  
    static getInstance(): WorkShopListAPIService {
      if (!WorkShopListAPIService.instance) {
        WorkShopListAPIService.instance = new WorkShopListAPIService();
      }
      return WorkShopListAPIService.instance;
    }

    
      async WorkShopListAPI() {

        try{
            let dataRequest=   {
      
                "company_id": 1  
               
                
            }
            
            const response = await apiClient.post('admin/workshoplist', dataRequest);
            return response.data;
          } catch (error) {
            console.error('Error listing workshop:', error);
            throw error;
          }
        }
      
     
    
  }
  
    const workShopListAPIService = WorkShopListAPIService.getInstance();
    
    export default workShopListAPIService;