import React from 'react';
import { Grid, Typography, Paper, ListItemIcon, Container, Box, IconButton } from '@mui/material';
import '../Registration/registrationstyle.scss'
import logo from '../../Assets/images/logo_white.png'
import registercover from '../../Assets/images/register_cover.png'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';


const RegHeader = () => {
  return (
    <Grid className="grid-container">
      <Grid item xs={12} className="background-image">
    
        <div className='text-overlay'>
        <Grid className='wrap_header'>
            <img src={logo} alt="Logo" className='logo' />
            <Typography className='heading' >
              KATCON 2024
            </Typography>
            <Typography className='societytext' >
              Indian Society of
              Anaesthesiologists
            </Typography>
            <Typography className='chaptertext'>
            Thrissur City Branch
            </Typography>
            <div className="date-container">
              <CalendarTodayOutlinedIcon className='custom-icon' />
              <Typography variant="body1" className="datetext">
                Aug 25
              </Typography>
            </div>
            <div className="place-container">
              <LocationOnOutlinedIcon className='custom-icon' />
              <Typography variant="body1" className="placetext">
               Govt. Medical College, Thrissur
              </Typography>

            </div>
            </Grid> 
        
        </div>
       
      </Grid>
    </Grid>
  );


};

export default RegHeader;
