import apiClient from "../../API/API-client";

class WorkshopEditApiService {
    private static instance: WorkshopEditApiService;

    private constructor() { }

    static getInstance(): WorkshopEditApiService {
        if (!WorkshopEditApiService.instance) {
            WorkshopEditApiService.instance = new WorkshopEditApiService();
        }
        return WorkshopEditApiService.instance;
    }
    async WorkshopEditApiService(updatedData: any,workshop_id:any) {
        try {
            let workshopUpdateData = {
                "id":workshop_id,
                "name": updatedData.Workshop_name,
                "company_id": 1,
                "venue": updatedData.workshop_venue,
                "topic": updatedData.Workshop_name,
                "start_date": updatedData.start_date,
                "end_date": updatedData.end_date,
                "max_no_of_attendee": updatedData.max_number_of_attendees,
                "status": "ACTIVE",
                "no_of_days": updatedData.number_of_days,
                "time": updatedData.time,
                "mem_amount": updatedData.member_rate,
                "non_mem_amount": updatedData.non_member_rate,
                "available_seat": updatedData.available_seats,
                "conference_id" : updatedData.conference_id,
                "last_date_of_registration":updatedData.last_date_of_registration
            }

            const response= await apiClient.put('admin/workshopupdate/'+workshop_id,workshopUpdateData);
            return response.data;
        } catch (error){
            console.error('Error contacting server:',error);
            throw error;
        }
        }
    }


const workshopEditAPIService = WorkshopEditApiService.getInstance();

export default workshopEditAPIService;