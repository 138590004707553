import apiClient from "../../API/API-client";

class WorkshopAddApiService {
    private static instance: WorkshopAddApiService;

    private constructor() { }

    static getInstance(): WorkshopAddApiService {
        if (!WorkshopAddApiService.instance) {
            WorkshopAddApiService.instance = new WorkshopAddApiService();
        }
        return WorkshopAddApiService.instance;
    }
    async workshopAddApiService(updatedData: any) {
        try {
            let workshopAddData = {
                "name": updatedData.Workshop_name,
                "company_id": 1,
                "venue": updatedData.workshop_venue,
                "date": "2023-12-30",
                "topic": updatedData.Workshop_name,
                "description": "description if any",
                "start_date": updatedData.start_date,
                "end_date": updatedData.end_date,
                "max_no_of_attendee": updatedData.number_of_attendees,
                "status": "ACTIVE",
                "no_of_days": updatedData.number_of_days,
                "last_date_of_registration": updatedData.last_date_of_registration,
                "time": updatedData.time,
                "mem_amount": updatedData.member_rate,
                "non_mem_amount": updatedData.non_member_rate,
                "available_seat": updatedData.number_of_attendees,
                "conference_id" : updatedData.conference_name
            }

            const response= await apiClient.post('admin/workshopadd',workshopAddData);
            return response.data;
        } catch (error){
            console.error('Error contacting server:',error);
            throw error;
        }
        }
    }


const workshopAddAPIService = WorkshopAddApiService.getInstance();

export default workshopAddAPIService;