import React from 'react'
import './SuccessPage.css'
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

let totalamount:any
function SuccessPage() {
    const navigate=useNavigate()
    const location = useLocation();
    if (location && location.state) {
        totalamount = location.state
    }
    const handleBackButtonClick = () => {
      // Navigate to another page upon button click
      navigate('/'); // Replace '/your-target-route' with the actual route you want to navigate to
    };
    return (
        <Grid>
        <div className='container'>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <div className='card' >
                <img src='https://cdn2.iconfinder.com/data/icons/greenline/512/check-512.png' className='icon' />
                <h5>Thank you for completing the registration process!</h5>
                <h4>To proceed further,Please follow these instructions: <br/>
                1. Payment has to send below bank account:</h4>
                <div className='card2'>
                    <Grid container spacing={0}>
                            {totalamount&&<Grid item xs={3} className='grid-item'>
                                <h4 className='label'>Amount in INR:</h4>
                            </Grid>}
                            {totalamount&&<Grid item xs={9} className='grid-items-right'>
                                <h4 className='value'>{`Rs. ${totalamount}`}</h4>
                            </Grid>}
                        <Grid item xs={3} className='grid-item'>
                            <h4 className='label'>Name:</h4>
                        </Grid>
                        <Grid item xs={9} className='grid-items-right'>
                            <h4 className='value'>ISA Thrissur City Branch</h4>
                        </Grid>

                        <Grid item xs={3} className='grid-item'>
                            <h4 className='label'>IFSC:</h4>
                        </Grid>
                        <Grid item xs={9} className='grid-items-right'>
                            <h4 className='value'>HDFC0004164</h4>
                        </Grid>
                        <Grid item xs={3} className='grid-item'>
                            <h4 className='label'>Account No:</h4>
                        </Grid>
                        <Grid item xs={9} className='grid-items-right'>
                            <h4 className='value'>50200043742992</h4>
                        </Grid>
                    </Grid>
                </div>
                <Grid className='infocontainer'>
                <h3>2.After the payment, send the confirmation screenshot to admin@isathrissur.com, 
                 including your contact phone number, registered email id, and registration no</h3>
                </Grid>
               
                <h6 className='email'>We will send a confirmation to your email, including a QR code. Please check your spam folder as well. <br/>
                If you dont receive a confirmation mail .Please contact us on 9188008001</h6>
                <button className='btn' onClick={handleBackButtonClick} id='backtohomebutton'>Back to home</button>
            </div>
        </Grid>
        </Grid> </div>
        <br/><br/>
        </Grid>
    )
}

export default SuccessPage