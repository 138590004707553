import React, { useEffect } from 'react';
import { Navigate, Route } from 'react-router-dom';

interface AuthRouteProps {
  element: React.ReactNode; // Adjust the type accordingly
}

const AuthRoute: React.FC<AuthRouteProps> = ({ element }) => {
    const isAuthenticated = sessionStorage.getItem('isAuthenticated');

    useEffect(() => {
        // Redirect to the login page if the user is not authenticated
        if (!isAuthenticated) {
            // You can customize the login route based on your application
            window.location.href = '/isaadmin';
        }
    }, [isAuthenticated]);

    // Render the route if the user is authenticated, otherwise, redirect to login
    return isAuthenticated ? <>{element}</> : <Navigate to="/isaadmin" />;
};

export default AuthRoute;
