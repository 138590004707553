import React, { useState } from "react";
import {
  Grid, FormControlLabel, Radio, Button, IconButton
} from '@mui/material';
import CategoryTable from "./../Registration/conference_reg_fee";
import WorkshopTable from "./../Registration/conference_workshop_fee";
import { useForm } from 'react-hook-form';
import FormField from "./../Registration/FormField";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonalDetails from "../Registration/PersonalDetails";
import ContactDetails from "../Registration/ContactDetails";
import OtherDetails from "../Registration/OtherDetails";
import '../Registration/registrationstyle.scss'
import registerApiService from "../../core/service/RegistrationAPIService";
import { useNavigate } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import HeaderWithLogout from './Common/Headerdesktop';
import SidebarToggle from './Common/SidebarToggle';

/** Conference Registration Page  */
const AdminRegistration: React.FC = () => {
  const [isISAMember, setIsISAMember] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);  // State to track sidebar visibility

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const [message, setMessage] = useState("");
  const [totalamount, settotalAmount] = useState<any>();
  const navigate = useNavigate();

  const handleMembershipChange = (event: React.ChangeEvent<any>) => {
    setIsISAMember(event.target.value === 'yes');
  };

  const [selectedWorkshopAmount, setSelectedWorksopAmount] = useState<any>();
  const [workshopSelection, setworkshopSelection] = useState<any>(null);
  const [WorkshopName, setWorkshopName] = useState<any>(null);
  const [CategoryName, setCategory] = useState<any>(null);
  const [role, setRole] = useState<any>(null);
  const [selectionMessage, setSelectionMessage] = useState<any>(null);

  const [selectedAmount, setSelectedAmount] = useState<any>();
  const [loading, setLoading] = React.useState(false);

  const handleConferenceAmountChange = (amount: number, category: any) => {
    // Handle the selected amount as needed in the parent component
    setSelectedAmount(amount);
    setCategory(category)
  };

  const handleWorkshopAmountChange = (amount: number, workshopSelection: any, workshopname: any) => {

    setSelectedWorksopAmount(amount);
    setworkshopSelection(workshopSelection)
    setWorkshopName(workshopname)

  };
  const handleRoleSelected = (role: any) => {
    // Handle the selected role in the parent component
    setRole(role)
  };
  const calculateTotalFee = () => {
    let total: number;
    if (workshopSelection === "no" || workshopSelection === null) {
      total = parseFloat(selectedAmount) || 0;
    } else {
      total = (parseFloat(selectedAmount) || 0) + (parseFloat(selectedWorkshopAmount) || 0);
    }
    settotalAmount(total);
  };

  React.useEffect(() => {
    calculateTotalFee();
  }, [selectedAmount, selectedWorkshopAmount]);
  const { control, handleSubmit } = useForm();

  /**
   * Handling form action here to save registration data to database
   * @param data 
   */
  const onSubmit = (data: any) => {

    let totalfee: number;
    let isaNumber: any;
    let type: number

    if (loading == false) {
      if (isISAMember) {
        isaNumber = data.isanumber;

      }
      if (workshopSelection === "no" || workshopSelection === null) {
        totalfee = parseFloat(selectedAmount) || 0;
        type = 0;
      } else {
        totalfee = (parseFloat(selectedAmount) || 0) + (parseFloat(selectedWorkshopAmount) || 0);
        type = 1;
      }

      if (!CategoryName) {
        setMessage("Please Select your category");
        const element = document.getElementById("categoryselection")
        element?.scrollIntoView()
      } else if ((workshopSelection === "yes") && (selectedWorkshopAmount == 0)) {
        setSelectionMessage("Please select your workshop category")
        const element = document.getElementById("workshopselection")
        element?.scrollIntoView()
      } else {
        setLoading(true);
        CreateMember(data, isaNumber, totalfee, WorkshopName, CategoryName, type, role)

      }
    }
  };
  /**
   * call registration api 
   * @param memberData 
   * @param isaNumber 
   * @param totalfee 
   * @param WorkshopName 
   * @param CategoryName 
   * @param type 
   * @param role 
   */
  const CreateMember = async (memberData: any, isaNumber: any, totalfee: any, WorkshopName: any, CategoryName: any, type: any, role: any) => {

    // Call the registerMember method and pass the member data
    const resp = await registerApiService.registerMember(memberData, isISAMember, totalfee, WorkshopName, CategoryName, type, role);

    //Payment
    // if(resp.status=="success"){
    // await RazorpayService.displayRazorpay(resp,totalfee);
    // }
    if (resp.status == "success") {
      // const response=  await registerApiService.updatePaymentRegistration(resp.data,totalfee);

      setLoading(false);
      navigate('/adminamountdetails', {
        state: {
          resp,
          totalfee,
          WorkshopName,
          selectedWorkshopAmount,
          CategoryName,
          selectedAmount,
          type,
        },
      });
    }
    else {
      setLoading(false);
      alert(resp.message);
    }

  }
  return (
    <Grid>
      <Grid container  xs={12}>
        <Grid item xs={1} className='sidebar'>
          <SidebarToggle />
        </Grid>
        {/* <RegHeader /> */}
        <Grid item xs={11} className="paper3 Admin_reg_margine">
          <Grid className='conferenceSummaryContainer'>
            <HeaderWithLogout title="Dashboard/Registration Form" icon={<DashboardIcon className='dashboard-icon' />} /></Grid>
          <Grid item xs={12} sm={12}  >
            <Grid>
              <IconButton onClick={toggleSidebar} className='menu-icon'>
              </IconButton></Grid>
            <h1>CONFERENCE REGISTRATION FORM</h1>
            <Grid xs={12} className="admin_reg">
              <Grid item xs={12} sm={6} md={4} className="h5">
                <h1 className="h5">Is an ISA member?</h1>
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Yes"
                  name="yes"
                  value="yes"
                  checked={isISAMember}
                  onChange={handleMembershipChange}
                  id="yes"

                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="No"
                  name="no"
                  value="no"
                  checked={!isISAMember}
                  onChange={handleMembershipChange}
                  id="no"
                />
                {isISAMember && (
                  <FormField
                    icon={<AccountBalanceIcon />}
                    control={control}
                    name="isanumber"
                    defaultValue=""
                    id="isanumber"
                    rules={{ required: 'This field is required' }}
                    label="Enter your ISA Number"
                  />
                )}

              </Grid>
              </Grid>
            <Grid xs={12}>
              <Grid >
              <PersonalDetails control={control} isStudent={!isISAMember} />
              </Grid>
              <br /><br />
              <Grid>
                <Grid>
                  <ContactDetails control={control} />
                </Grid></Grid>
              <br /><br /><br />
              <Grid >
                <Grid>
                  <OtherDetails control={control} RoleSelected={handleRoleSelected} />
                </Grid>

              </Grid>
              <div className="categoryselection" id="categoryselection"></div>
              <Grid>
                {message ? <div className="categoryselectionmessage">
                  {message}
                </div> : <></>}
                <CategoryTable isaMember={isISAMember} onAmountChange={handleConferenceAmountChange} /><br /><br />
                {selectionMessage ? <div className="categoryselectionmessage">
                  {selectionMessage}
                </div> : <></>}
                <div className="workshopselection" id="workshopselection"></div>
                <WorkshopTable isaMember={isISAMember} onAmountWorkshopChange={handleWorkshopAmountChange} />
              </Grid><br /><br />
              <Grid container justifyContent="center">
                <Grid item>
                  <Button onClick={handleSubmit(onSubmit)} id="registerbutton" className="big-vertical-button pulse-button">
                    {loading ? 'Processing...' : 'Submit'}
                  </Button>
                </Grid>
              </Grid><br /><br />
            </Grid>
          </Grid>
        </Grid></Grid></Grid>
  );
}

export default AdminRegistration;