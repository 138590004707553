import React from 'react';
import { DataGrid, GridColDef} from '@mui/x-data-grid';
interface WorkshopDataGridProps {
  rows: any[]; // replace 'any[]' with the actual type for your data
}

const columns: GridColDef[] = [
  { field: 'Workshop_name', headerName: 'Workshop Name', width:140, headerClassName: 'headergridcell_blue1', cellClassName: "gridcolumn-cells", headerAlign: "center", align: "center", sortable: false },
  { field: 'workshop_venue', headerName: 'Venue', width:120, headerClassName: 'headergridcell_blue1', cellClassName: "gridcolumn-cells", headerAlign: "center", align: "center", sortable: false },
  { field: 'member_rate', headerName: 'Member Rate', width:130, headerClassName: 'headergridcell_blue1', cellClassName: "gridcolumn-cells", headerAlign: "center", align: "center", sortable: false, },
  { field: 'non_member_rate', headerName: 'Non-Member Rate', width:150, headerClassName: 'headergridcell_blue1', cellClassName: "gridcolumn-cells", headerAlign: "center", align: "center", sortable: false,},
   {
    field: 'start_date',
    headerName: 'Start Date',
    width:100,
    headerClassName: 'headergridcell_blue1',
    cellClassName: "gridcolumn-cells",
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    width:100,
    headerClassName: 'headergridcell_blue1',
    cellClassName: "gridcolumn-cells",
    headerAlign: "center",
    align: "center",
    sortable: false,
  
  },
   

  {
    field: 'last_date_of_registration',
    headerName: 'Last Date Of Registration',
    width:180,
    headerClassName: 'headergridcell_blue1',
    cellClassName: "gridcolumn-cells",
    headerAlign: "center",
    align: "center",
    sortable: false,
  
  },

  {
    field: 'number_of_attendees',
    headerName: 'No. of Attendees',
    width:130,
    headerClassName: 'headergridcell_blue1',
    cellClassName: "gridcolumn-cells",
    headerAlign: "center",
    align: "center",
    sortable: false,},
    {
      field: 'number_of_days',
      headerName: 'No. of Days',
      width:130,
      headerClassName: 'headergridcell_blue1',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,},
  {
    field: "time",
    headerName: 'Time',
    width:150,
    headerClassName: 'headergridcell_blue1',
    cellClassName: "gridcolumn-cells",
    headerAlign: "center",
    align: "center",
    sortable: false,
  },
];

const WorkshopDataGrid: React.FC<WorkshopDataGridProps> = ({ rows }) => {
  const getRowId = (row: any) => row.Workshop_name;
  const getRowHeight = () => 50; // Set the desired row height here

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      getRowId={getRowId}
      autoHeight
      getRowHeight={getRowHeight}
      localeText={{ noRowsLabel: "No data available" }}
      hideFooter
      hideFooterSelectedRowCount
      disableColumnMenu={true}
      className='Workshop_margin'
    />
  );
};

export default WorkshopDataGrid;
