import React from "react";
import { Grid, Typography, Container, Button, MenuItem } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import FormField from "./FormField";
import Formdate from "./Datepicker";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { useForm, Controller, FieldValues, Control } from 'react-hook-form';
import MailIcon from "@mui/icons-material/Mail";

interface PersonalDetailsProps {
  control: Control<FieldValues>;
  isStudent: boolean;

}
const PersonalDetails: React.FC<PersonalDetailsProps> = ({ control ,isStudent}) => {
  const prefixOptions = ["Dr", "Prof", "Mr", "Mrs", "Ms"];
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const mobileRegex = /^\d{10}$/;
  const genderOptions = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
  ];

  return (
    // <Container component="main" maxWidth="md">
    <Grid>
      <Grid item xs={12} >
        <h1 className="h4">A. Personal Information</h1>
      </Grid>
      <Grid container spacing={3} className="paper">
        {/* <Grid item xs={12}>
          <Typography variant="body1" className="h2">Prefix *</Typography>

          <FormField
            name="prefix"
            type="select"
            control={control}
            icon={<PersonIcon />}
            defaultValue="Dr"
            label=""
            id="prefix"
            // setFocus={setFocus}
            rules={{ required: 'Please select a prefix' }}
          >
            {prefixOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </FormField>
        </Grid> */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">First Name *</Typography>
          <FormField
            icon={<PersonIcon />}
            control={control}
            name="firstName"
            defaultValue=""
            label=""
            id="firstname"
            rules={{ required: 'This field is required' }}

          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">Middle Name </Typography>
          <FormField
            icon={<PersonIcon />}
            control={control}
            name="middleName"
            defaultValue=""
            label=""
            id="middlename"
            rules={""}

          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">Last Name </Typography>
          <Grid item xs={12}>
            <FormField
              icon={<PersonIcon />}
              control={control}
              name="lastName"
              defaultValue=""
              label=""
              id="lastname"
              rules={{ }}

            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">
           WhatsApp No *
          </Typography>
          <FormField
          label=""
            icon={<PhoneIphoneIcon />}
            control={control}
            name="mobile"
            defaultValue=""
            id="mobile"
            // label=""
            rules={{
              required: "This field is required",
              validate: {
                validMobile: (value: any) =>
                  mobileRegex.test(value) || "Invalid mobile number",
              },
            }}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">Designation *</Typography>
          <FormField
            icon={<PersonIcon />}
            control={control}
            name="designation"
            defaultValue=""
            label=""
            id="designation"
            rules={{ required: 'This field is required' }}
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">Name As to Appear on Badge *</Typography>

          <FormField
            icon={<CardMembershipIcon />}
            control={control}
            name="badge"
            defaultValue=""
            label=""
            id="badge"
            rules={{ required: 'This field is required' }}
          />
        </Grid> */}
        {/* Other form fields go here, adjust Grid item props as needed */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">Gender *</Typography>
          <FormField
            name="gender"
            type="select"
            control={control}
            icon={<PersonIcon />}
            defaultValue=""
            label=""
            id="gender"
            rules={{ required: 'Please select a gender' }}
          >
            {genderOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </FormField>

        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">
            Email *
          </Typography>
          <FormField
            label=""
            icon={<MailIcon />}
            control={control}
            name="email"
            defaultValue=""
            id="Email"
            // label=""
            rules={{
              required: "This field is required",
              validate: {
                validEmail: (value: any) =>
                  emailRegex.test(value) || "Invalid email address",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">Institute {isStudent && '*'} </Typography>
          <FormField
            icon={<AccountBalanceIcon />}
            control={control}
            name="institute"
            defaultValue=""
            label=""
            id="institute"
            rules={{ 
              required: isStudent ? 'This field is required' : {}
            }}
            //rules={{ required: 'This field is required' }}
          />
        </Grid>
       
        {/* <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">State / MCI Registration Number </Typography>

          <FormField
            icon={<AccountBalanceIcon />}
            control={control}
            name="regnumber"
            defaultValue=""
            label=""
            id="registration number"
            rules={{}}
            //rules={{ required: 'This field is required' }}
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1" className="h2">Aadhar Number *</Typography>
                <FormField
                  icon={<AccountBalanceIcon />}
                  control={control}
                  name="aadharNumber"
                  defaultValue=""
                  label=""
                  rules={{ required: 'This field is required',
                  validate: {
                    validEmail: (value :any)=> aadharRegex.test(value) || 'Invalid aadhar number',
                  },  }}
                />
              </Grid> */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">Date of Birth *</Typography>
          <Formdate
            icon={<CalendarTodayIcon />}
            control={control}
            name="dob"
            defaultValue=""
            id="dob"
            rules={{ required: 'This field is required' }}

          />
        </Grid>
       
        
        </Grid>

      {/* <Grid container justifyContent="center">
                <Grid item>
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </Grid>
            </Grid> */}

    </Grid>
  );
}

export default PersonalDetails;
