import { Grid, MenuItem, Select as MuiSelect, Typography, Button, FormControl, FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import './EditMember.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import EditMemberTextField from './EditMemberTextField';
import SidebarToggle from '../Common/SidebarToggle';
import HeaderWithLogout from '../Common/Headerdesktop';
import DashboardIcon from '@mui/icons-material/Dashboard';
import editMemberAPIService from '../../../core/service/EditMemberApiService';
import Formdate from '../EditMember/Date'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FieldValues, Control } from "react-hook-form";

import Select from "react-select";
let rowData: any;
const EditMember = () => {
    const navigate = useNavigate();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^\d{10}$/;
    const pincodeRegex = /^\d{6}$/;
    const [token, setToken] = useState("");
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const [town, setTown] = useState([]);
    const [rowData, setRowData] = useState<any | null>(null);

  
    useEffect(() => {
        // Retrieve data from session storage
        const rowDataString = sessionStorage.getItem('editrowData');
        
        if (rowDataString) {
            const rowData = JSON.parse(rowDataString);
            // Use the rowData as needed in your component
       
            
            setRowData(rowData);
          
            
            sessionStorage.removeItem('editrowData'); 
        }
     
        getAuthToken();
    }, [rowData]);
   

    const getAuthToken = async () => {
       
        const apiUrl = "https://www.universal-tutorial.com/api/getaccesstoken";
        const apiToken =
            "gZ6eNDKLsqCqS9BB6zYKq0oQBYL_t5fvzprVlBZL716FQRcqn36LdeoNHi-lgda6esk";
        // const apiToken = 'AuXnFjES43NqbdODZoc1anLtpO9op_9HsA7hqU56HJoxlbbNrMsUAzmsp6cqoZ0HhWQ';
        const userEmail = "athul@nivid.co";
        // const userEmail = 'abc@gmail.com';

        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "api-token": apiToken,
                "user-email": userEmail,
            },
        };

        try {
            const response = await fetch(apiUrl, requestOptions);
            const data = await response.json();

           
            setToken(data.auth_token);
            await getCountries(data.auth_token);
            return data.auth_token;
        } catch (error) {
            console.error("Error fetching auth token:", error);
           // getAuthToken();
        }
    };



    const getCountries = async (token: any) => {
      
        const apiUrl = "https://www.universal-tutorial.com/api/countries/";

        const apiToken = "Bearer" + " " + token;

        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                Accept: "application/json",
                authorization: apiToken,
            },
        };

        try {
            const response = await fetch(apiUrl, requestOptions);
            const data = await response.json();
            setCountry(data);
         
            await getStates(rowData.country, token);
        } catch (error) {
            console.error("Error fetching auth token:", error);
            throw error;
        }
    };

    const getStates = async (country: any, token1?: any) => {
        
        const apiUrl = `https://www.universal-tutorial.com/api/states/${country}`;
        let apiToken;
        if (token) {

            apiToken = "Bearer" + " " + token;
        } else {

            apiToken = "Bearer" + " " + token1;
        }

        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                Accept: "application/json",
                authorization: apiToken,
            },
        };
       
        try {
            const response = await fetch(apiUrl, requestOptions);
            const data = await response.json();
            
            setState(data);
            if (token1) {
              await  getTown(rowData.state, token1);
            }
        } catch (error) {
            console.error("Error fetching auth token:", error);
            throw error;
        }
    };

    const getTown = async (town: any, token1?: any) => {
        const apiUrl = `https://www.universal-tutorial.com/api/cities/${town}`;

        // const apiToken = "Bearer" + " " + token;
        let apiToken;
        if (token) {

            apiToken = "Bearer" + " " + token;
        } else {

            apiToken = "Bearer" + " " + token1;
        }
        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                Accept: "application/json",
                authorization: apiToken,
            },
        };

        try {
            const response = await fetch(apiUrl, requestOptions);
            const data = await response.json();

            setTown(data);
        } catch (error) {
            console.error("Error fetching auth token:", error);
            throw error;
        }
    };


    const onSubmit = async (data: any) => {
    
       
        try {

            
            const confirmed = window.confirm("Data edited Successfully");
            if (confirmed) {
               
          
                const response = await editMemberAPIService.EditMemberList(data, rowData.attendees[0].id, rowData.company_id,rowData.city);
                navigate("/isaadmin/memberlist")
            }
            else {
                window.location.reload();
}
        } catch (error) {

        }
    };

    // const location = useLocation()
    // if (location && location.state) {
    //     rowData = location.state;
    //     console.log("logggg", rowData);

    // }
  
    
    const { control, handleSubmit,setValue } = useForm();



    return (<Grid container className='container-center'>
        <Grid container spacing={0}>


            <Grid item xs={1} className='sidebar'>
                <SidebarToggle />
            </Grid>



            <Grid item xs={12} sm={11} ><br />
                <HeaderWithLogout title="Dashboard/Edit" icon={<DashboardIcon
                    className='dashboard-icon' />} />
                <Typography variant='h4'> EDIT REGISTRATION FORM</Typography>
                <br></br>
                <form >
                    {rowData &&
                        <>
                            <Grid container item xs={12} >



                                <Grid item md={12} sm={12} >

                                    <Grid className='memberview-container'>
                                        <h2 className='h4 '>A.Personal Information</h2>
                                        <br /><br />

                                        <Grid container spacing={12} className='edit1'>

                                            <Grid item xs={12} sm={3} className='memberview-input'>
                                                <Typography variant='body1' className='label-memberview'>Prefix*</Typography>
                                                <Controller
                                                    name="prefix"
                                                    control={control}
                                                    defaultValue={
                                                        rowData.prefix
                                                    }
                                                    render={({ field }) => (
                                                        <MuiSelect id="prefix" {...field} required className='input-select-edit'>

                                                            <MenuItem value='Dr'>Dr</MenuItem>
                                                            <MenuItem value="Prof">Prof</MenuItem>
                                                            <MenuItem value="Mr">Mr</MenuItem>
                                                            <MenuItem value="Mrs">Mrs</MenuItem>
                                                            <MenuItem value="Ms">Ms</MenuItem>
                                                        </MuiSelect>
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                <Typography variant='body1' className='label-memberview'>First Name*</Typography>
                                                <EditMemberTextField
                                                    name="firstname"
                                                    control={control}
                                                    defaultValue={rowData.firstname}

                                                    rules={{ required: "This field is required" }}
                                                />

                                            </Grid>
                                            <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                <Typography variant='body1' className='label-memberview'>Middle Name</Typography>
                                                <EditMemberTextField
                                                    name="middlename"
                                                    control={control}
                                                    defaultValue={rowData.middlename}

                                                    rules={{}}
                                                />

                                            </Grid>
                                            <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                <Typography variant='body1' className='label-memberview'>Last Name*</Typography>
                                                <EditMemberTextField
                                                    name="lastname"
                                                    control={control}
                                                    defaultValue={rowData.lastname}

                                                    rules={{ required: "This field is required" }}
                                                />


                                            </Grid>
                                            <Grid item xs={12} sm={3} className='memberview-inputs responsive-input '>
                                                <Typography variant='body1' className='label-memberview'>Designation*</Typography>
                                                <EditMemberTextField
                                                    name="designation"
                                                    control={control}
                                                    defaultValue={rowData.designation}

                                                    rules={{ required: "This field is required" }}
                                                />


                                            </Grid>
                                            <Grid item xs={12} sm={3} className='memberview-inputs responsive-input'>
                                                <Typography variant='body1' className='label-memberview'>Institute*</Typography>
                                                <EditMemberTextField
                                                    name="institute"
                                                    control={control}
                                                    defaultValue={rowData.institute}

                                                    rules={{ required: "This field is required" }}
                                                />


                                            </Grid>
                                            <Grid item xs={12} sm={3} className='memberview-inputs responsive-input'>
                                                <Typography variant='body1' className='label-memberview'>State/MCI Registration*</Typography>

                                                <EditMemberTextField
                                                    name="mcino"
                                                    control={control}
                                                    defaultValue={rowData.mcino}

                                                    rules={{ required: "This field is required" }}
                                                /></Grid>




                                            <Grid item xs={12} sm={6} md={4} lg={3} className='memberview-inputs responsive-input'>
                                                <Typography variant='body1' className='label-memberview'>Date of Birth*</Typography>
                                                <Formdate
                                                    id={""}
                                                    name="dob"
                                                    control={control}
                                                    defaultValue={rowData.dob}
                                                    rules={{ required: "This field is required" }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3} className='memberview-inputs responsive-input'>
                                                <Typography variant='body1' className='label-memberview'>Name on Badge*</Typography>
                                                <EditMemberTextField
                                                    name="name"
                                                    control={control}
                                                    defaultValue={rowData.name}

                                                    rules={{ required: "This field is required" }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3} className='memberview-inputs responsive-input'>
                                                <Typography variant='body1' className='label-memberview'>Gender*</Typography>

                                                <Controller
                                                    name="gender"
                                                    control={control}
                                                    defaultValue={rowData.gender}

                                                    render={({ field }) => (
                                                        <MuiSelect id="gender" {...field} required className='input-select-edit'>

                                                            <MenuItem value='Male'>Male</MenuItem>
                                                            <MenuItem value='Female'>Female</MenuItem>

                                                        </MuiSelect>
                                                    )}
                                                />
                                            </Grid>

                                        </Grid>

                                        <br /><br />


                                    </Grid><br />

                                    <Grid container spacing={10}>
                                        <Grid item sm={12} xs={12}>
                                            <Grid className='memberview-container'>
                                                <h2 className='h4 '>B.Contact Information</h2>
                                                <br /><br />
                                                <Grid container spacing={12} className='edit1'>

                                                    <Grid item xs={12} sm={3} className='memberview-input  '>
                                                        <Typography variant='body1' className='label-memberview'>Gmail*</Typography>
                                                        <EditMemberTextField
                                                            name="email"
                                                            control={control}
                                                            defaultValue={rowData.email}

                                                            rules={{
                                                                required: "This field is required",
                                                                validate: {
                                                                    validEmail: (value: any) =>
                                                                        emailRegex.test(value) || "Invalid email address",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} className='memberview-input  responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>Mobile*</Typography>
                                                        <EditMemberTextField
                                                            name="phone"
                                                            control={control}
                                                            defaultValue={rowData.phone}

                                                            rules={{
                                                                required: "This field is required",
                                                                validate: {
                                                                    validMobile: (value: any) =>
                                                                        mobileRegex.test(value) || "Invalid mobile number",
                                                                },
                                                            }}

                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>Address*</Typography>
                                                        <EditMemberTextField
                                                            name="address"
                                                            control={control}
                                                            defaultValue={rowData.address}

                                                            rules={{ required: "This field is required" }}
                                                        /></Grid>
                                                    <Grid item xs={12} sm={3} className='memberview-input responsive-input' >
                                                        <Typography variant="body1" className="label-memberview">
                                                            Country *
                                                        </Typography>

                                                        <Controller
                                                            name="country"
                                                            control={control}
                                                            defaultValue={rowData.country}
                                                            render={({ field }) => (
                                                                <Grid>
                                                                    <Grid >

                                                                        <FormControl fullWidth>
                                                                            <select
                                                                                {...field}
                                                                                className='state-edit'
                                                                                id="country"
                                                                                required
                                                                                onChange={(e) => {
                                                                                    const selectedCountry = e.target.value;
                                                                                    getStates(selectedCountry);
                                                                                    field.onChange(e);
                                                                                }}
                                                                                value={field.value || "India"} // Set the default value here
                                                                            >
                                                                                {country.map((item: any, index) => (
                                                                                    <option key={index} value={item.country_name}>
                                                                                        {item.country_name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} className='memberview-inputs responsive-input'>
                                                        <Typography variant="body1" className="label-memberview">
                                                            State *
                                                        </Typography>
                                                        <Controller
                                                            name="state"
                                                            control={control}
                                                            defaultValue={rowData.state}
                                                            render={({ field }) => (
                                                                <div>
                                                                    <Grid >


                                                                        <FormControl fullWidth>
                                                                            <select
                                                                           defaultValue={rowData.state}
                                                                            className='state-edit'
                                                                                {...field}
                                                                                value={field.value || "Kerala"} 
                                                                                id="state"
                                                                                required
                                                                                onChange={(e) => {
                                                                                    const selectedState = e.target.value;

                                                                                    getTown(selectedState);
                                                                                    field.onChange(e); // Make sure to propagate the change to the Controller
                                                                                }}
                                                                            >
                                                                                {state?.map((item: any, index) => (
                                                                                    <option key={index} value={item.state_name}>
                                                                                        {item.state_name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </div>
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} className='memberview-inputs responsive-input'  >
                                                        <Typography variant="body1" className="label-memberview">
                                                            City *
                                                        </Typography>
                                                        <Controller
                                                            name="city"
                                                            control={control}
                                                            defaultValue={rowData.city}
                                                            rules={{ required: 'This field is required' }}

                                                            render={({ field,fieldState }) => {

                                                                return <div>
                                                                    <Grid >

                                                                        <FormControl fullWidth>
                                                                            <Select
                                                                                defaultValue={rowData.city}
                                                                                defaultInputValue={rowData.city}
                                                                                required
                                                                                className='city-edit'
                                                                                id="city"
                                                                                {...field}
                                                                                {...control}
                                                                                options={town.map((item: any) => ({
                                                                                    value: item.city_name,
                                                                                    label: item.city_name,
                                                                                })
                                                                                )
                                                                                }
                                                                                isSearchable
                                                                                isClearable={false}
                                                                                filterOption={({ label }, inputValue: any) =>
                                                                                    label?.toLowerCase().startsWith(inputValue.toLowerCase())
                                                                                }
                                                                                styles={{
                                                                                    control: (provided: any) => ({
                                                                                        ...provided,
                                                                                        textAlign: 'left',
                                                                                        padding: '3.5px',
                                                                                        fontSize: '13px',
                                                                                        fontWeight: '500',
                                                                                        color: 'black',
                                                                                        fontFamily: 'sans-serif',

                                                                                        zIndex: 2,
                                                                                        position: 'relative',
                                                                                    }),
                                                                                    menu: (provided: any) => ({
                                                                                        ...provided,
                                                                                        textAlign: 'left', // Align the menu to the left
                                                                                        zIndex: 3, // Set a higher zIndex for the dropdown menu
                                                                                        position: 'absolute',
                                                                                    }),
                                                                                    option: (provided: any) => ({
                                                                                        ...provided,
                                                                                        textAlign: 'left',
                                                                                        marginLeft: '30px', // Align each option to the left
                                                                                        padding: '5px', // Adjust padding as needed
                                                                                        fontSize: '16px',
                                                                                        fontWeight: '500',
                                                                                        color: 'black',
                                                                                        fontFamily: 'sans-serif',
                                                                                        zIndex: 1
                                                                                    }),
                                                                                }}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <FormHelperText error={!!fieldState.error} className='error-msg1'>
                {fieldState.error?.message}
              </FormHelperText>

                                                                </div>
                                                            }}
                                                        />

                                                    </Grid>                                      {/* <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>Country*</Typography>
                                                        <EditMemberTextField
                                                            name="country"
                                                            control={control}
                                                            defaultValue={rowData.country}

                                                            rules={{ required: "This field is required" }}
                                                        /></Grid> */}
                                                    {/* <Grid item xs={12} sm={3} className='memberview-inputs  responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>State*</Typography>
                                                        <EditMemberTextField
                                                            name="state"
                                                            control={control}
                                                            defaultValue={rowData.state}

                                                            rules={{ required: "This field is required" }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} className='memberview-inputs responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>City</Typography>
                                                        <EditMemberTextField
                                                            name="city"
                                                            control={control}
                                                            defaultValue={rowData.city}

                                                            rules={{ required: "This field is required" }}
                                                        />
                                                    </Grid> */}


                                                    <Grid item xs={12} sm={3} className='memberview-inputs responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>Pin Code*</Typography>
                                                        <EditMemberTextField
                                                            name="pincode"
                                                            control={control}
                                                            defaultValue={rowData.pincode}

                                                            rules={{
                                                                required: "This field is required",
                                                                validate: {
                                                                    validPincode: (value: any) =>
                                                                        pincodeRegex.test(value) || "Invalid pincode",
                                                                },
                                                            }} />

                                                    </Grid>  </Grid> <br /><br /></Grid> </Grid> </Grid><br />
                                    <Grid container spacing={12}>
                                        <Grid item sm={12} xs={12}>
                                            <Grid className='memberview-container'>
                                                <h2 className='h4 '>C.Other Information</h2>
                                                <br /><br />
                                                <Grid container spacing={12} className='edit1 '>
                                                    <Grid item xs={12} sm={3} className='memberview-input otherdt'>
                                                        <Typography variant='body1' className='label-memberview'>Type of Practice</Typography>

                                                        <Controller
                                                            name="type_of_practice"
                                                            control={control}
                                                            defaultValue={
                                                                rowData.attendees[0].type_of_practice !== ""
                                                                    ? rowData.attendees[0].type_of_practice
                                                                    : "NO"
                                                            }
                                                            render={({ field }) => (
                                                                <MuiSelect id="typeofpractice" {...field} required className='input-select-edit'>
                                                                    <MenuItem value="" >Select </MenuItem>
                                                                    <MenuItem value='Government'>Government</MenuItem>
                                                                    <MenuItem value="Corporate">Corporate</MenuItem>
                                                                    <MenuItem value="Freelancer">Freelancer</MenuItem>
                                                                    <MenuItem value="Student">Student</MenuItem>
                                                                </MuiSelect>
                                                            )}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>Diet Preferences</Typography>

                                                        <Controller
                                                            name="food_type"
                                                            control={control}
                                                            defaultValue={rowData.attendees[0].food_type}
                                                            render={({ field }) => (
                                                                <MuiSelect id="dietPreferences" {...field} required className='input-select-edit'>
                                                                    <MenuItem value='Veg'>Veg</MenuItem>
                                                                    <MenuItem value="Non Veg">Non-Veg</MenuItem>
                                                                </MuiSelect>
                                                            )}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'> Cultural Activity Participation
                                                        </Typography>

                                                        <Controller
                                                            name="activity"
                                                            control={control}
                                                            defaultValue={rowData.attendees[0].activity != null ? rowData.attendees[0].activity : "NO"}
                                                            render={({ field }) => (
                                                                <MuiSelect id="culturalactivityparticipation" {...field} required className='input-select-edit'>
                                                                    <MenuItem value="" >Select </MenuItem>
                                                                    <MenuItem value='Instrumental'>Instrumental</MenuItem>
                                                                    <MenuItem value="Vocal">Vocal</MenuItem>
                                                                    <MenuItem value="Stage Performance">Stage Performance</MenuItem>
                                                                </MuiSelect>
                                                            )}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>Type of Position</Typography>

                                                        <Controller
                                                            name="interest"
                                                            control={control}
                                                            defaultValue={
                                                                rowData.attendees[0].interest !== null
                                                                    ? rowData.attendees[0].interest
                                                                    : "NO"
                                                            }
                                                            render={({ field }) => (
                                                              
                                                                <MuiSelect id="Type of Position" {...field} required className='input-select-edit'>
                                                                    <MenuItem value="" >Select </MenuItem>
                                                                    <MenuItem value='organiser'>Organiser</MenuItem>
                                                                    <MenuItem value="faculty">Faculty</MenuItem>
                                                                    <MenuItem value="sponser">Sponser</MenuItem>
                                                                    <MenuItem value="delegate">Delegate</MenuItem>
                                                                </MuiSelect>
                                                            )}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} className='memberview-inputs responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>Poster</Typography>

                                                        <Controller
                                                            name="poster"
                                                            control={control}
                                                            defaultValue={rowData.attendees[0].poster != null ? rowData.attendees[0].poster : "NO"}
                                                            render={({ field }) => (
                                                                <MuiSelect id="Poster" {...field} required className='input-select-edit margin-bottom'>
                                                                    <MenuItem value="" >Select </MenuItem>
                                                                    <MenuItem value='yes'>Yes</MenuItem>
                                                                    <MenuItem value="no">No</MenuItem>

                                                                </MuiSelect>
                                                            )}
                                                        />
                                                    </Grid>



                                                    <Grid item xs={12} sm={3} className='memberview-inputs responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>Abstract</Typography>

                                                        <Controller
                                                            name="abstract"
                                                            control={control}
                                                            defaultValue={rowData.attendees[0].abstract != null ? rowData.attendees[0].abstract : "NO"}
                                                            render={({ field }) => (
                                                                <MuiSelect id="Abstract" {...field} required className='input-select-edit'>
                                                                    <MenuItem value="" >Select </MenuItem>
                                                                    <MenuItem value='yes'>Yes</MenuItem>
                                                                    <MenuItem value="no">No</MenuItem>

                                                                </MuiSelect>
                                                            )}
                                                        />
                                                    </Grid>



                                                    <Grid item xs={12} sm={3} className='memberview-inputs responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>T-shirt Size</Typography>

                                                        <Controller
                                                            name="tshirt_size"
                                                            control={control}
                                                            defaultValue={rowData.tshirt_size}
                                                            render={({ field }) => (
                                                                <MuiSelect id="T-shirt Size" {...field} required className='input-select-edit'>
                                                                    <MenuItem value='XS'>Extra Small</MenuItem>
                                                                    <MenuItem value="S">Small</MenuItem>
                                                                    <MenuItem value="M">Medium</MenuItem>
                                                                    <MenuItem value="L">Large</MenuItem>
                                                                    <MenuItem value="XL"> Extra Large</MenuItem>
                                                                    <MenuItem value="2XL">Double Extra Large</MenuItem>
                                                                    <MenuItem value="3XL">Triple Extra Large</MenuItem>
                                                                </MuiSelect>
                                                            )}
                                                        />
                                                    </Grid>







                                                </Grid><br /><br /> </Grid></Grid></Grid><br />


                                    <Grid container spacing={12}>
                                        <Grid item sm={12} xs={12}>
                                            <Grid className='memberview-container'>
                                                <h2 className='h4 '>D.Amount Details</h2>
                                                <br /><br />
                                                <Grid container spacing={12} className='edit1'>


                                                    <Grid item xs={12} sm={3} className='memberview-input' >
                                                        <Typography variant='body1' className='label-memberview'>Category*</Typography>

                                                        <Controller
                                                            name="category"
                                                            control={control}
                                                            defaultValue={rowData.category}
                                                            render={({ field }) => (
                                                                <MuiSelect id="category" {...field} required className='input-select-edit'>
                                                                    <MenuItem value="ISA Member">ISA Member</MenuItem>
                                                                    <MenuItem value="PG Member">PG Member</MenuItem>
                                                                    <MenuItem value="Non-ISA Member">Non-ISA Member</MenuItem>
                                                                    <MenuItem value="Non-ISA PG Member">Non-ISA PG Member</MenuItem>
                                                                </MuiSelect>
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>Type*</Typography>

                                                        <Controller
                                                            name="type"
                                                            control={control}
                                                            defaultValue={rowData.attendees[0].type}
                                                            render={({ field }) => (
                                                                <MuiSelect id="category" {...field} required className='input-select-edit'>

                                                                    <MenuItem value="conference">conference</MenuItem>
                                                                    <MenuItem value="conference+workshop ">conference+workshop</MenuItem>

                                                                </MuiSelect>
                                                            )}
                                                        />
                                                    </Grid>
                                                    {/* {rowData.registration_no &&
                                                        <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                            <Typography variant='body1' className='label-memberview'>Registration No</Typography>
                                                            <EditMemberTextField
                                                                name="registration_no"
                                                                control={control}
                                                                defaultValue={rowData.registration_no}

                                                                rules={{ required: "This field is required" }}
                                                            /></Grid>} */}

                                                    {/*                  
                                                <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                <Typography variant='body1' className='label-memberview'>workshop_id</Typography>
                                                        <EditMemberTextField
                                                            name="workshop_id"
                                                            control={control}
                                                            defaultValue={rowData.attendees[0].workshop_id ? rowData.attendees[0].workshop_id : "NO"}
                                                        
                                                        rules={{ required: "This field is required" }}/>

                                                    </Grid> */}
                                                    <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>Workshop Type</Typography>

                                                        <Controller
                                                            name="workshop_id"
                                                            control={control}
                                                            defaultValue={rowData.attendees[0].workshop_id ? rowData.attendees[0].workshop_id : "NO"}
                                                            render={({ field }) => (
                                                                <MuiSelect id="workshop_id" {...field} required className='input-select-edit'>
                                                                    <MenuItem value="" >Select </MenuItem>
                                                                    <MenuItem value="Simulation based learning">Simulation based learning</MenuItem>
                                                                    <MenuItem value="Usg guided RA">Usg guided RA</MenuItem>
                                                                    <MenuItem value="Critical care in a nutshell">Critical care in a nutshell</MenuItem>
                                                                    <MenuItem value="Ventilation">Ventilation</MenuItem>


                                                                </MuiSelect>
                                                            )}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} className='memberview-input responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>Amount in INR</Typography>

                                                        <EditMemberTextField
                                                            name="amount"
                                                            control={control}
                                                            defaultValue={rowData.attendees[0].amount}

                                                            rules={{ required: "This field is required" }} />

                                                    </Grid>
                                                    <Grid item xs={12} sm={3} className='memberview-inputs responsive-input'>
                                                        <Typography variant='body1' className='label-memberview'>Payment Status*</Typography>
                                                        <Controller
                                                            name="payment_status"
                                                            control={control}
                                                            defaultValue={rowData.attendees[0].payment_status}
                                                            render={({ field }) => (
                                                                <MuiSelect id="payment_status" {...field} required className='input-select-edit'>
                                                                    <MenuItem value='PAID'>PAID</MenuItem>
                                                                    <MenuItem value="PENDING">PENDING</MenuItem>

                                                                </MuiSelect>
                                                            )}
                                                        />
                                                    </Grid>

                                                    <br /><br />
                                                </Grid><br /><br />

                                            </Grid>

                                        </Grid>


                                    </Grid>
                                </Grid>
                            </Grid><br /><br />
                            <Grid item xs={12}>
                                <Button onClick={handleSubmit(onSubmit)} className='big-vertical-button pulse-button'>Submit</Button>
                            </Grid><br />
                        </>}
                </form ></Grid></Grid ></Grid>);
}

export default EditMember;
