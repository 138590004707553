// RazorpayService.tsx
/**
 * @author Neethu 
 * Razor pay integration service
 */
import axios from 'axios';
import logo from '../../Assets/images/isa1.jpg';
import config from '../../config.json';
import apiClient from '../../API/API-client';
import razorpaymentService from "../../core/service/PaymentApiService";
/**
 * Interface to pass optional values to razor pay api
 */
export interface RazorpayOptions {
  key: string;
  amount: number;
  currency: string;
  name: string;
  description: string;
  order_id?: string;
  image: string;
  modal?: {
    ondismiss: () => void;
  };
  handler: (response: any) => void;
  prefill: {
    name: string;
    email?: string;
    phone_number?: string;
  };
  notes: {
    address: string;
  };
  theme: {
    color: string;
  };
}

const createOrder = async (amount: number): Promise<any> => {
  try {
    console.log("create order");
    const data = {
      "amount": amount * 100,
      "currency": "INR",
      "receipt": 'Order_' + Date.now(),
      "partial_payment": false,
      "first_payment_min_amount": 1
    };

    // Encode credentials using btoa for basic authentication
    const credentials = btoa(`${config.razorpay.live_key}:${config.razorpay.secret_key}`);

    // const response = await apiClient.post('admin/memberlistall', memberData);
    // return response.data;
    // Perform the API request using axios or any other HTTP client
    const result = await axios.post(config.prodapi.url + 'orders', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${credentials}`
      }
    });

    // Return the order data from the API response
    return result.data.data;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};



const RazorpayService = {
  isInitialized: false,

  initialize: (): Promise<boolean> => {
    try {

      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;

        script.onload = () => {
          RazorpayService.isInitialized = true;
          resolve(true);
        };

        script.onerror = () => {
          reject(new Error('Failed to load Razorpay SDK.'));
        };

        document.body.appendChild(script);
      });
    } catch (error) {
      console.error('Error initializing Razorpay:', error);
      return Promise.resolve(false);
    }
  },


  createRazorpayObject: (options: RazorpayOptions): any => {
    if (!RazorpayService.isInitialized) {
      throw new Error('Razorpay SDK is not initialized.');
    }

    return new (window as any).Razorpay(options);
  },

/**
 * Razor pay api calling function
 */
  displayRazorpay: async (amountData: any, amount: number, onPaymentFailure: (details: any, errors: any) => void,): Promise<any> => {
    return new Promise(async (resolve, reject) => {
     
      try {
        if (!RazorpayService.isInitialized) {
          await RazorpayService.initialize();
        }
        const orderData = await createOrder(amount);
        //const orderData=JSON.stringify(result);

        const options: RazorpayOptions = {
          key: config.razorpay.live_key, // Replace with your actual Razorpay key rzp_live_4WGQyOJLpbuVM1 //rzp_test_efFD3W6nS1nDIh
          amount: amount * 100,
          currency: "INR",
          name: 'ISA Thrissur',
          description: 'Transaction: ISA Thrissur conference registration',
          image: logo,
          order_id: orderData.id,
          handler: async function (response: any) {

            if (response.error) {
              // Payment failed
              console.error('Payment failed:', response.error);
              // Handle payment failure here, e.g., redirect to a failure page
              // reject(new Error('Payment failed'));
              onPaymentFailure(amountData, response.error.code);
            } else {

              const data = {
                amountData: amountData,
                orderCreationId: orderData.id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
              };

              const result = await razorpaymentService.verifyPaymentSignature(data);
              console.log(result);
              // Resolve the promise with the Razorpay response
              resolve(response);
            }
          },
          modal: {
            ondismiss: function () {
              // Handle the Razorpay modal dismissal here
              console.log("Razorpay modal closed");

              // reject(new Error('Razorpay modal closed'));
              onPaymentFailure(amountData, "Razorpay modal closed");
              // navigate('/paymentfailure', { state: amount });
              // You can reject the promise or perform any other actions as needed

            }
          },
          prefill: {
            name: amountData.resp.data.name,
            email: amountData.resp.data.email,
            phone_number: amountData.resp.data.phone,
          },
          notes: {
            address: 'Thrissur',
          },
          theme: {
            color: '#0457DC',
          },
        };

        const razorpayObject = RazorpayService.createRazorpayObject(options);
        razorpayObject.open();
      } catch (error) {
        if (error instanceof DOMException && error.name === 'NetworkError') {
          // Handle network error, e.g., show a user-friendly message
          console.error('Network error. Please check your internet connection.');
          // You may also want to trigger a specific action or UI update for network errors
        } else {
          console.error('Error displaying Razorpay:', error);
          // Reject the promise with the error
          reject(error);
        }
      }
    });
  },
  /**
   * Razor pay api calling function for retry payment page
   * @param amountData 
   * @param amount 
   * @param onPaymentFailure 
   * @returns 
   */
  retryPaymentRazorpay: async (amountData: any, amount: number, onPaymentFailure: (details: any, errors: any) => void,): Promise<any> => {
    return new Promise(async (resolve, reject) => {
     
      try {
        if (!RazorpayService.isInitialized) {
          await RazorpayService.initialize();
        }
        const orderData = await createOrder(amount);
        //const orderData=JSON.stringify(result);

        const options: RazorpayOptions = {
          key: config.razorpay.live_key, // Replace with your actual Razorpay key rzp_live_4WGQyOJLpbuVM1 //rzp_test_efFD3W6nS1nDIh
          amount: amount * 100,
          currency: "INR",
          name: 'ISA Thrissur',
          description: 'Transaction: ISA Thrissur conference registration',
          image: logo,
          order_id: orderData.id,
          handler: async function (response: any) {

            if (response.error) {
              // Payment failed
              console.error('Payment failed:', response.error);
              // Handle payment failure here, e.g., redirect to a failure page
              // reject(new Error('Payment failed'));
              onPaymentFailure(amountData, response.error.code);
            } else {

              const data = {
                amountData: amountData,
                orderCreationId: orderData.id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
              };

              const result = await razorpaymentService.verifyPaymentSignature(data);
              console.log(result);
              // Resolve the promise with the Razorpay response
              resolve(response);
            }
          },
          modal: {
            ondismiss: function () {
              // Handle the Razorpay modal dismissal here
              console.log("Razorpay modal closed");

              // reject(new Error('Razorpay modal closed'));
              onPaymentFailure(amountData, "Razorpay modal closed");
              // navigate('/paymentfailure', { state: amount });
              // You can reject the promise or perform any other actions as needed

            }
          },
          prefill: {
            name: amountData.name,
            email: amountData.email,
            phone_number: amountData.phone,
          },
          notes: {
            address: 'Thrissur',
          },
          theme: {
            color: '#0457DC',
          },
        };

        const razorpayObject = RazorpayService.createRazorpayObject(options);
        razorpayObject.open();
      } catch (error) {
        if (error instanceof DOMException && error.name === 'NetworkError') {
          // Handle network error, e.g., show a user-friendly message
          console.error('Network error. Please check your internet connection.');
          // You may also want to trigger a specific action or UI update for network errors
        } else {
          console.error('Error displaying Razorpay:', error);
          // Reject the promise with the error
          reject(error);
        }
      }
    });
  },
}



export default RazorpayService;

