import React from 'react';
import './LoginPage.scss';
import { Grid } from '@mui/material';
import Image from './Image.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
/**
 * Admin Login page
 */
function LoginPage() {
    const navigate = useNavigate();
    const { control, handleSubmit, formState, reset } = useForm();
    const [showPassword, setShowPassword] = React.useState(false);
    const { isSubmitting } = formState;
    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClick = () => {
        navigate('/forgotpassword');
    };

    /**
     * @author Neethu
     * This is method is for login to admin dashbaord using email and password
     * @param data 
     */
    const onSubmit = (data: any) => {

        if (data.email == "admin@isathrissur.com" && data.password == "isa#admin@2023#thr") {
            sessionStorage.setItem('isAuthenticated', 'true');
            navigate("/isaadmin/dashboard1");
        }
        else {

            alert("Invalid credentials. Please try again.");
            setTimeout(() => {
                reset();
            }, 0);
        }
    };


    return (
        <Grid container item xs={12}>
            <Grid className='card-login' xs={12}>
                <Grid xs={12}>
                    <img src={Image} className='image-login' alt='image' />
                    <p className='main'>Welcome Back!</p>
                   
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid className="input-container">
                            <label htmlFor='emailInput' className='label-login'>Email Address</label>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className='input-login'
                                        type='email'
                                        id='emailInput'
                                        required
                                        placeholder='Enter your email here...'
                                    />
                                )}
                            />
                        </Grid>
                        <Grid className="input-container">
                            <label htmlFor='passwordInput' className='label-login'>Password </label>
                            <Controller
                                name="password"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            className='input-login'
                                            type={showPassword ? 'text' : 'password'}
                                            id='passwordInput'
                                            required
                                            placeholder='Enter your password here...'
                                        />
                                        <Grid
                                            className='eye-icon'
                                            onClick={handleTogglePassword}
                                        >
                                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                                        </Grid>
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                                {/* <Grid item xs={6} className='start '>
                                    {/* <p className='padding-left paragraph1' onClick={handleClick} >Forgot Password?</p> */}
                                {/* </Grid> */} 
                                <Grid item xs={12} >
                                    <button type="submit" className='button-login'>
                                        {isSubmitting ? 'Signing in...' : 'Sign-in'}
                                    </button>
                                </Grid>
                            </Grid><br />

                            <Grid container spacing={2} alignItems="center">
                                {/* <Grid item xs={6} className='end padding'>
                                    <p >Don't have an account?</p>
                                </Grid>
                                <Grid item xs={6} className='start padding'>
                                    <p className='color-blue'>Create Account</p>
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default LoginPage;
