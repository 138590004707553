import React from 'react'
import './SuccessPage.css'
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';


function RegistrationDone() {
    const navigate=useNavigate()
    const location = useLocation();
    const totalFee = location.state && location.state.totalFee;
    const razorpayDetails = location.state && location.state.razorpayDetails;

    const handleBackButtonClick = () => {
      // Navigate to another page upon button click
      navigate('/'); // Replace '/your-target-route' with the actual route you want to navigate to
    };
    return (<div className='container'><Grid container spacing={0}>
        <Grid item xs={12}>
            <div className='card' >
                <img src='https://cdn2.iconfinder.com/data/icons/greenline/512/check-512.png' className='icon' />
                <h5 className='email-bold'>Thank you for completing the registration process!</h5>
                {/* <h3>To proceed further,Please follow these instructions:</h3> */}
                {/* <h3>1.Payment has to send below bank account:</h3> */}

                {/* <div className='card2'>
                    <Grid container spacing={0}>
                        <Grid item xs={3} className='grid-item'>
                            <h4 className='label'>Name:</h4>
                        </Grid>
                        <Grid item xs={9} className='grid-items-right'>
                            <h4 className='value'>ISA Thrissur City Branch</h4>
                        </Grid>

                        <Grid item xs={3} className='grid-item'>
                            <h4 className='label'>IFSC:</h4>
                        </Grid>
                        <Grid item xs={9} className='grid-items-right'>
                            <h4 className='value'>HDFC0004164</h4>
                        </Grid>

                        <Grid item xs={3} className='grid-item'>
                            <h4 className='label'>Account No:</h4>
                        </Grid>
                        <Grid item xs={9} className='grid-items-right'>
                            <h4 className='value'>50200043742992</h4>
                        </Grid>
                    </Grid>


                </div> */}
                {/* <h3>After the payment send Confirmation Screenshot to:</h3>
                <h3 className='email-bold'>admin@isathrissur.com</h3 > */}
                <div className='paymentcard'>
                <h3>Transaction ID:</h3>
                <h3 className='email-bold'>{razorpayDetails.paymentId}</h3 >
                </div>
                <h5 className='email'>We will send a confirmation to your email, including a QR code.<br/> Please check your spam folder as well.</h5 >
                <h6>If you dont receive a confirmation mail .<br/>Please contact us on 9188008001 or admin@isathrissur.com</h6>
                   
                
                <button className='btn'  onClick={handleBackButtonClick}>Back To Home</button>
            </div>
        </Grid></Grid> </div>
    )
}

export default RegistrationDone