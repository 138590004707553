import '../SuccessPage/SuccessPage.css'
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';


function AdminRegistrationDone() {
    const navigate=useNavigate()

    const handleBackButtonClick = () => {
      navigate('/isaadmin/adminregistration'); 
    };
    return (<div className='container'><Grid container spacing={0}>
        <Grid item xs={12}>
            <div className='card' >
                <img src='https://cdn2.iconfinder.com/data/icons/greenline/512/check-512.png' className='icon' />
                <h5 className='email-bold'>Thank you for completing the registration process!</h5>
                <h5 className='email'>We will send a confirmation to your email, including a QR code.<br/> Please check your spam folder as well.</h5 >
                <h6>If you dont receive a confirmation mail .<br/>Please contact us on 9188008001 or admin@isathrissur.com</h6>
                <button className='btn'  onClick={handleBackButtonClick}>Back To Registration</button>
            </div>
        </Grid></Grid> </div>
    )
}

export default AdminRegistrationDone