import React from "react";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import personimage from "../../../Assets/images/personimage.png";
import "../../Homescreen/components/home.scss";
import team_image1 from "../../../Assets/images/ourteam/image1.jpeg"
import team_image2 from "../../../Assets/images/ourteam/image2.jpg"
import team_image3 from "../../../Assets/images/ourteam/image3.jpeg"
import team_image4 from "../../../Assets/images/ourteam/image4.jpeg"
import team_image6 from "../../../Assets/images/ourteam/image6.jpg"
import team_image5 from "../../../Assets/images/ourteam/image5.jpg"
import team_image7 from "../../../Assets/images/ourteam/image7.png"
import team_image8 from "../../../Assets/images/ourteam/image8.jpg"
const Team = () => {

  // Data for State Office Bearers
  const data = {
    "members": [
      {
        "designation": "President",
        "image": team_image2,
        "name": "Dr. C.R Sen"
      },
      {
        "designation": "Vice -President",
        "image": team_image4,
        "name": "Dr. Binil Isaac Mathew"
      },
      {
        "designation": "Secretary",
        "image": team_image3,
        "name": "Dr. Paul O Raphael"
      },
      {
        "designation": "Treasurer",
        "image": personimage,
        "name": "Dr. Arif Thasleem K"
      },
      {
        "designation": "ISA Kerala Journal Editor",
        "image": personimage,
        "name": "Dr. Suneel P R"
      },
      {
        "designation": "National ISA FBF Secretary",
        "image": personimage,
        "name": "Dr. Sugu Varghese"
      },
     
      {
        "designation": "ISA FBF State Coordinator",
        "image": personimage,
        "name": "Dr. Jithin T N"
      },
      {
        "designation": "Scientific Commitee Coordinator",
        "image": personimage,
        "name": "Dr. Jerry Paul"
      },
     
    ]
  };

   // Data for ISA Thrissur City Branch Office Bearers
  const data2 = {
    "members": [
      {
        "designation": "President",
        "image": team_image5,
        "name": "Dr. Krishnan Pisharady"
      },
      {
        "designation": "Vice President",
        "image": team_image7,
        "name": "Dr. Joseph Thomas"
      },
      {
        "designation": "Secretary",
        "image": team_image6,
        "name": "Dr. Sajan Sebastain"
      },
      {
        "designation": "Treasurer",
        "image": team_image8,
        "name": "Dr Mithun Raju P"
      },
      {
        "designation": "GC Member",
        "image": team_image1,
        "name": "Dr. Arun Krishna"
      }
     
     
    ]
  };
  return (
   
    <Grid className="home_container2" >
      <Grid xs={12} md={12} sm={12} className="center_align">
        <Typography className="font_style_header">OUR TEAM</Typography><br/><br/>
        <div>
          <Typography className="font_style_subheader">ISA THRISSUR CITY BRANCH OFFICE BEARERS</Typography>
          <Grid container spacing={2} className="team-container">
           {data2?.members?.map((member, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card className={`member-card`}>
              <div className="member-image-container">
              <CardMedia
              component="img"
              alt={member.name}
              src={member.image}
              className="member-image"
            />
          </div>
          <CardContent >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="member-name"
            >
              {member.name}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              className="member-designation"
            >
              {member.designation}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>


</div>

        <div>
        <Typography className="font_style_subheader">STATE OFFICE BEARERS</Typography>
  <Grid container spacing={2} className="team-container">
  
    {data?.members?.map((member, index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Card className={`member-card`}>
          <div className="member-image-container">
            <CardMedia
              component="img"
              alt={member.name}
              src={member.image}
              className="member-image"
            />
          </div>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="member-name"
            >
              {member.name}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              className="member-designation"
            >
              {member.designation}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
</div>
      </Grid>
    </Grid>
    
  );
};

export default Team;
