
import {useEffect, useState} from 'react';
// import logo from './logo-blue.png';
import logo from '../../Assets/images/logo-blue.png'
import './ContactUs.scss';
import { Grid } from '@mui/material';
import { useForm, Controller, FieldValues, FieldError } from 'react-hook-form';
import Footer from '../Common/Footer';
import contactusAPIService from '../../core/service/ContactusApiService';
import { useNavigate } from 'react-router-dom';


interface FormData {
    name: string;
    email: string;
    message: string;
}

function ContactUs() {
    const navigate = useNavigate();
    const { handleSubmit, control, formState: { errors } } = useForm<FormData>();
    const [isSubmitted, setIsSubmitted] = useState(false);

    
    const onSubmit = async (data: FormData) => {
      
        sendContactData(data)
    };
    const sendContactData = async (data: any) => {
       
        if(isSubmitted==false){
        setIsSubmitted(true);
        // Call the registerMember method and pass the member data
        const resp = await contactusAPIService.contactDataList(data);
        console.log("response", resp);
        setIsSubmitted(false);
        if (resp.status == "success") {
            alert("Email send successfully");
            window.location.href="/contactus";
            //navigate("/contactus");
        }
        }
    }
    return (
        <Grid>
            <Grid container item xs={12}>
                <Grid className='background-gray' container>
                <Grid item xs={5} ></Grid>
                    <Grid item xs={7} >
                        {/* <img src={logo} className='image' alt='Logo' /> */}
                        <h1 className='heading-Contact'>Contact </h1>
                    </Grid>
                </Grid>

                <Grid container className='alignment'>
                    <Grid item xs={12} md={5} >
                        <Grid className='center-alignment'>

                            <h2 className='h2-contact'>CONTACT US</h2>

                            <Grid container direction="column" className='container-contact'>
                                <Grid item>
                                    <p className='paragraph-contact'>
                                        <strong>Address:</strong>
                                        Secretary ISA Thrissur City Branch,<br />
                                        Dr Sajan K Sebastian, Kannampully,<br />
                                        Skyline Heritage Villa 15, Fr Vadakkan Road,<br />
                                        Mundupalam, Thrissur KERALA 680001
                                    </p>
                                </Grid>
                                <Grid item>
                                    <p className='paragraph-contact'>
                                        <strong>Phone:</strong> 9188008001
                                    </p>
                                </Grid>
                                <Grid item>
                                    <p className='paragraph-contact'>
                                        <strong>Email:</strong> isathrissurcitybranch@gmail.com
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid></Grid>



                    <Grid item xs={12} md={7}>
                        <h1 className='h2-contact1'>Send us a message</h1>
                        <form onSubmit={handleSubmit(onSubmit)} className='form'>
                            <Grid>
                                <label className='label-contact' htmlFor="name">Name:</label>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: 'Name is required' }}
                                    render={({ field }) => (
                                        <>
                                            <input {...field} id="name" className='contact-input' />
                                            {errors.name?.message && (
                                                <p className='error-message'>{(errors.name as FieldError)?.message}</p>
                                            )}
                                        </>
                                    )}
                                />
                            </Grid>

                            <Grid>
                                <label className='label-contact' htmlFor="email">Email:</label>
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^\S+@\S+$/i,
                                            message: 'Invalid email address',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <input {...field} id="email" className='contact-input' />
                                            {errors.email?.message && (
                                                <p className='error-message'>{(errors.email as FieldError)?.message}</p>
                                            )}
                                        </>
                                    )}
                                />
                            </Grid>

                            <Grid>
                                <label className='label-contact' htmlFor="message">Message:</label>
                                <Controller
                                    name="message"
                                    control={control}
                                    rules={{ required: 'Message is required' }}
                                    render={({ field }) => (
                                        <>
                                            <textarea {...field} id="message" />
                                            {errors.message?.message && (
                                                <p className='error-message'>{(errors.message as FieldError)?.message}</p>
                                            )}
                                        </>
                                    )}
                                />
                            </Grid>

                            <Grid className='btn-container'>
                                <button type="submit" className='button-contact'>Submit</button>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>  </Grid>
    );
}

export default ContactUs;