import React,{useState} from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { BarChart, AddAPhotoOutlined } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import '../Common/common.scss';
import { Link ,useLocation} from 'react-router-dom';
import logoImg from '../../../Assets/images/logo_white.png'
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import FeedIcon from '@mui/icons-material/Feed';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ListAltIcon from '@mui/icons-material/ListAlt';
import HowToRegIcon from '@mui/icons-material/HowToReg';

const SidebarDesktop: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);  // State to track sidebar visibility

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const location = useLocation();

  const isLinkActive = (path: string) => {
    return location.pathname === path;
  };
  const handleSignout = () => {
    // Clear the authentication information in sessionStorage
    sessionStorage.removeItem('isAuthenticated');
    // Redirect to the login page or perform any other necessary action
    window.location.href = '/isaadmin';
  };
  return (
    <Paper className='sidebar_paperdesk'>
      <Grid container direction="column" spacing={2} >
      <Grid item className='conferenceSummaryContainer'>
          <img src={logoImg} alt="Logo" className='headerlogo' />
        </Grid>
        <Grid item>
        <Link to="/isaadmin/dashboard1" className={`linkbutton ${isLinkActive('/isaadmin/dashboard1') ? 'active-link' : ''}`}>
            <Grid className={`sidebar_paper1 ${isLinkActive('/isaadmin/dashboard1') ? 'active-sidebar' : ''}`}>
              <Grid container className='itemcenter' spacing={2}>
                <Grid item>
                  <DashboardIcon className={`${isLinkActive('/isaadmin/dashboard1') ? 'active-icon' : 'sidebariconsstyle '}`} />
                </Grid>
                <Grid item>
                  <Typography className={` ${isLinkActive('/isaadmin/dashboard1') ? 'active-text' : 'sidebartitlestyle'}`}>
                    Dashboard
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Link>
        </Grid>
        <Grid item>
        <Link to="/isaadmin/memberlist" className={`linkbutton ${isLinkActive('/isaadmin/memberlist') ? 'active-link' : ''}`}>
        <Grid className={`sidebar_paper1 ${isLinkActive('/isaadmin/memberlist') ? 'active-sidebar' : ''}`}>
            <Grid container className='itemcenter' spacing={2}>
              <Grid item>
                <GroupIcon className={`${isLinkActive('/isaadmin/memberlist') ? 'active-icon' : 'sidebariconsstyle '}`} />
              </Grid>
              <Grid item><Typography className={` ${isLinkActive('/isaadmin/memberlist') ? 'active-text' : 'sidebartitlestyle'}`}>Members</Typography></Grid>
            </Grid>
          </Grid>
          </Link>
        </Grid>
        {/* <Grid item>
        <Link to="/isaadmin/adminregistration" className={`linkbutton ${isLinkActive('/isaadmin/adminregistration') ? 'active-link' : ''}`}>
        <Grid className={`sidebar_paper1 ${isLinkActive('/isaadmin/adminregistration') ? 'active-sidebar' : ''}`}>
            <Grid container className='itemcenter' spacing={2}>
              <Grid item>
                <HowToRegIcon className={`${isLinkActive('/isaadmin/adminregistration') ? 'active-icon' : 'sidebariconsstyle '}`} />
              </Grid>
              <Grid item><Typography className={` ${isLinkActive('/isaadmin/adminregistration') ? 'active-text' : 'sidebartitlestyle'}`}>Registration Form</Typography></Grid>
            </Grid>
          </Grid>
          </Link>
        </Grid> */}
        <Grid item>
        <Link to="/isaadmin/attendeeslist" className={`linkbutton ${isLinkActive('/isaadmin/attendeeslist') ? 'active-link' : ''}`}>
        <Grid className={`sidebar_paper1 ${isLinkActive('/isaadmin/attendeeslist') ? 'active-sidebar' : ''}`}>
            <Grid container className='itemcenter' spacing={2}>
              <Grid item>
                <SwitchAccountIcon className={`${isLinkActive('/isaadmin/attendeeslist') ? 'active-icon' : 'sidebariconsstyle '}`} />
              </Grid>
              <Grid item><Typography className={` ${isLinkActive('/isaadmin/attendeeslist') ? 'active-text' : 'sidebartitlestyle'}`}>Attendees List</Typography></Grid>
            </Grid>
          </Grid>
          </Link>
        </Grid>
        <Grid item>
        <Link to="/isaadmin/workshoplist" className={`linkbutton ${isLinkActive('/isaadmin/workshoplist') ? 'active-link' : ''}`}>
        <Grid className={`sidebar_paper1 ${isLinkActive('/isaadmin/workshoplist') ? 'active-sidebar' : ''}`}>
            <Grid container className='itemcenter' spacing={2}>
              <Grid item>
                <ListAltIcon className={`${isLinkActive('/isaadmin/workshoplist') ? 'active-icon' : 'sidebariconsstyle '}`} />
              </Grid>
              <Grid item><Typography className={` ${isLinkActive('/isaadmin/workshoplist') ? 'active-text' : 'sidebartitlestyle'}`}>Workshop List</Typography></Grid>
            </Grid>
          </Grid>
          </Link>
        </Grid>
        {/* <Grid item>
        <Link to="/isaadmin/conferenceadd" className={`linkbutton ${isLinkActive('/isaadmin/conferenceadd') ? 'active-link' : ''}`}>
          <Grid className={`sidebar_paper1 ${isLinkActive('/isaadmin/conferenceadd') ? 'active-sidebar' : ''}`}>
            <Grid container className='itemcenter' spacing={2}>
              <Grid item>
                <LibraryAddIcon className={`sidebariconsstyle ${isLinkActive('/isaadmin/conferenceadd') ? 'active-icon' : ''}`}/>
              </Grid>
              <Grid item><Typography className={`sidebartitlestyle ${isLinkActive('/isaadmin/conferenceadd') ? 'active-text' : ''}`}>Add Conference</Typography></Grid>
            </Grid>
          </Grid>
          </Link>
        </Grid> */}
        <Grid item>
        <Link to="/isaadmin/newslist" className='linkbutton'>
          <Grid className='sidebar_paper1'>
            <Grid container className='itemcenter' spacing={2}>
              <Grid item>
                <FeedIcon className='sidebariconsstyle'/>
              </Grid>
              <Grid item><Typography className='sidebartitlestyle'>News List</Typography></Grid>
            </Grid>
          </Grid>
          </Link>
        </Grid>
        <Grid item>
        <Link to="/isaadmin/addvolunteer" className='linkbutton'>
          <Grid className='sidebar_paper1'>
            <Grid container className='itemcenter' spacing={2}>
              <Grid item>
                <PersonAddAlt1Icon className='sidebariconsstyle'/>
              </Grid>
              <Grid item><Typography className='sidebartitlestyle'>Add Volunteer</Typography></Grid>
            </Grid>
          </Grid>
          </Link>
        </Grid>


        <Grid item>
        <Link to="/isaadmin/dashboard" className='linkbutton'>
          <Grid className='sidebar_paper1' >
            <Grid container className='itemcenter' spacing={2}>
              <Grid item>
                <AddAPhotoOutlined  className='sidebariconsstyle'/>
              </Grid>
              <Grid item><Typography className='sidebartitlestyle'>Add Gallery</Typography></Grid>
            </Grid>
          </Grid>
          </Link>
        </Grid>

        
        <Grid item>
        <Link to="/isaadmin" className='linkbutton' onClick={handleSignout}>
          <Grid className='sidebar_paper1' >
            <Grid container className='itemcenter' spacing={2}>
              <Grid item>
              <LogoutIcon className='sidebariconsstyle' />
              </Grid>
              <Grid item><Typography className='sidebartitlestyle'>Signout</Typography></Grid>
            </Grid>
          </Grid>
          </Link>
          {/* <ArrowCircleLeftIcon onClick={toggleSidebar} className='arrow-icon1' /> */}
        </Grid>
       
      </Grid>
    </Paper>
  );
};

export default SidebarDesktop;
