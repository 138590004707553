import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
} from '@mui/material';
import '../Registration/registrationstyle.scss'
import serverDateAPIService from '../../core/service/ServerDateApiService';
import moment from 'moment';

let currentServerDate: any;

const CategoryTable = ({ isaMember, onAmountChange }: { isaMember: any; onAmountChange: (amount: number, category: any) => void }) => {
  useEffect(() => {
    getServertime();
  }, []);

  useEffect(() => {
    setSelectedOption('');
    onAmountChange(0, "");
    // Set the initial selected option based on isaMember
    if (isaMember) {
      setSelectedOption('Working-Registration');
      onAmountChange(data.categories[0].amounts["Registration"], 'Working');
    } else {
      setSelectedOption('Student-Registration');
      onAmountChange(data.categories[1].amounts["Registration"], 'Student');
    }
  }, [isaMember]);

  const data: any = {
    categories: [
      {
        category: 'Working',
        amounts: {
          "Registration": 750,
        },
        fromDate: { "fromDate1": "", "fromDate2": "2024-07-10", "fromDate3": "2024-08-07" },
        toDate: { "toDate1": "2024-07-10", "toDate2": "2024-08-07", "toDate3": "2024-08-07" }
      },
      {
        category: 'Student',
        amounts: {
          "Registration": 600,
        },
        fromDate: { "fromDate1": "", "fromDate2": "2024-07-10", "fromDate3": "2024-08-07" },
        toDate: { "toDate1": "2024-07-10", "toDate2": "2024-08-07", "toDate3": "2024-08-07" }
      },
    ],
  };

  const [selectedOption, setSelectedOption] = useState<any>('');
  const [serverTime, setServerTime] = useState<any>('')

  const getServertime = async () => {
    try {
      const resp = await serverDateAPIService.ServerDateAPI();
      if (resp.data) {
        setServerTime(resp.data);
        currentServerDate = moment(resp.data.date).format("YYYY-MM-DD");
      }
    } catch (error) {
      console.error("Error during data fetch:", error);
    }
  }

  const handleRadioChange = (category: any, month: any, amount: any) => {
    setSelectedOption(`${category}-${month}`);
    onAmountChange(amount, category);
  };

  const disabledRadiobutton = (category: any) => {
    if (isaMember && category === 'Student') {
      return true; // Disable Students category if isaMember is true
    } else if (!isaMember && category === 'Working') {
      return true; // Disable Working category if isaMember is false
    }
    return false; // Enable otherwise
  };

  const amount = Object.keys(data.categories[0].amounts);

  return (
    <Grid container className='category-container'>
      <Grid item xs={12} sm={6} md={4} className='registration-fee'>
        <h1 className="h9">B. Select Registration Category</h1>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper} className="category-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>
                {amount.map((amount) => (
                  <TableCell key={amount}>{amount}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.categories.map((category: any) => (
                <TableRow key={category.category}>
                  <TableCell>{category.category}</TableCell>
                  {amount.map((amount) => (
                    <TableCell key={amount}>
                      <RadioGroup
                        aria-label={`radio-group-${category.category}-${amount}`}
                        value={selectedOption}
                        onChange={() => handleRadioChange(category.category, amount, category.amounts[amount])}
                      >
                        <FormControlLabel
                          value={`${category.category}-${amount}`}
                          control={<Radio />}
                          disabled={disabledRadiobutton(category.category)} // Apply the disable logic
                          label={`Rs ${category.amounts[amount].toString()}`}
                        />
                      </RadioGroup>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default CategoryTable;
