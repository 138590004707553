import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  FormControl,
  Container,
  Button,
  FormHelperText
} from "@mui/material";
import Select from "react-select";

import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import FormTextField from "./Formtextfield";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import FormField from "./FormField";
import { countries } from "../Homescreen/components/data";
import items from "razorpay/dist/types/items";

import { useForm, Controller, FieldValues, Control } from "react-hook-form";
interface ContactDetailsProps {
  control: Control<FieldValues>;
}
const ContactDetails: React.FC<ContactDetailsProps> = ({ control }) => {
  // const { control, handleSubmit, setValue } = useForm();
  const [token, setToken] = useState("");
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [town, setTown] = useState([]);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const mobileRegex = /^\d{10}$/;
  const pincodeRegex = /^\d{6}$/;

  useEffect(() => {
    getAuthToken();
  }, []);

  const getAuthToken = async () => {
    const apiUrl = "https://www.universal-tutorial.com/api/getaccesstoken";
    const apiToken =
      "gZ6eNDKLsqCqS9BB6zYKq0oQBYL_t5fvzprVlBZL716FQRcqn36LdeoNHi-lgda6esk";
    // const apiToken = 'AuXnFjES43NqbdODZoc1anLtpO9op_9HsA7hqU56HJoxlbbNrMsUAzmsp6cqoZ0HhWQ';
    const userEmail = "athul@nivid.co";
    // const userEmail = 'abc@gmail.com';

    const requestOptions: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "api-token": apiToken,
        "user-email": userEmail,
      },
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();

      const authToken = data.auth_token;
      setToken(authToken);
      await getCountries(authToken);
      return authToken;
    } catch (error) {
      console.error("Error fetching auth token:", error);
      getAuthToken();
    }
  };

  const onSubmit = (data: any) => {
   
  };

  const getCountries = async (token: any) => {
    const apiUrl = "https://www.universal-tutorial.com/api/countries/";

    const apiToken = "Bearer" + " " + token;

    const requestOptions: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        authorization: apiToken,
      },
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      setCountry(data);
      getStates("India", token);
    } catch (error) {
      console.error("Error fetching auth token:", error);
      throw getCountries(token);
    }
  };

  const getStates = async (country: any, token1?: any) => {
    const apiUrl = `https://www.universal-tutorial.com/api/states/${country}`;
    let apiToken;
    if (token) {
      
      apiToken = "Bearer" + " " + token;
    } else {
      
      apiToken = "Bearer" + " " + token1;
    }

    const requestOptions: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        authorization: apiToken,
      },
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();

      setState(data);
      if (token1) {
        getTown("Kerala", token1);
      }
    } catch (error) {
      console.error("Error fetching auth token:", error);
      throw getStates(token1);
    }
  };

  const getTown = async (town: any, token1?: any) => {
    const apiUrl = `https://www.universal-tutorial.com/api/cities/${town}`;

    // const apiToken = "Bearer" + " " + token;
    let apiToken;
    if (token) {
      
      apiToken = "Bearer" + " " + token;
    } else {
      
      apiToken = "Bearer" + " " + token1;
    }
    const requestOptions: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        authorization: apiToken,
      },
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();

      setTown(data);
    } catch (error) {
      console.error("Error fetching auth token:", error);
      throw getTown("kerala",token1);
    }
  };


  return (
    // <Container component="main" maxWidth="md">

    <Grid>
      <Grid item xs={12} sm={6} md={4}>
        <h1 className="h4">B. Contact Information</h1>
      </Grid>
    
      <Grid container spacing={3} className="paper">
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">
            Email *
          </Typography>
          <FormTextField
            icon={<MailIcon />}
            control={control}
            name="email"
            defaultValue=""
            id="Email"
            // label=""
            rules={{
              required: "This field is required",
              validate: {
                validEmail: (value: any) =>
                  emailRegex.test(value) || "Invalid email address",
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">
           WhatsApp Mobile *
          </Typography>
          <FormTextField
            icon={<PhoneIphoneIcon />}
            control={control}
            name="mobile"
            defaultValue=""
            id="mobile"
            // label=""
            rules={{
              required: "This field is required",
              validate: {
                validMobile: (value: any) =>
                  mobileRegex.test(value) || "Invalid mobile number",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">
            Address *
          </Typography>
          <FormTextField
            icon={<PersonPinCircleIcon />}
            control={control}
            name="address"
            defaultValue=""
            id="address"
            // label=""
            rules={{ required: "This field is required" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">
            Country *
          </Typography>

          <Controller
            name="country"
            control={control}
            defaultValue="India"
            render={({ field }) => (
              <Grid>
                <Grid className="inputContainer">
                  <Grid className="iconWrapper">
                    {" "}
                    <PersonPinCircleIcon />
                  </Grid>

                  <FormControl fullWidth>
                    <select
                      {...field}
                      className="inputWithIcon2Select2"
                      id="country"
                      required
                      onChange={(e) => {
                        const selectedCountry = e.target.value;
                        getStates(selectedCountry);
                        field.onChange(e);
                      }}
                      value={field.value || "India"} // Set the default value here
                    >
                      {country.map((item: any, index) => (
                        <option key={index} value={item.country_name}>
                          {item.country_name}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">
            State *
          </Typography>
          <Controller
            name="state"
            control={control}
            defaultValue="Kerala"
            render={({ field }) => (
              <div>
                <Grid className="inputContainer">
                  <Grid className="iconWrapper">
                    {" "}
                    <PersonPinCircleIcon />
                  </Grid>

                  <FormControl fullWidth>
                    <select
                      {...field}
                      className="inputWithIcon2Select2"
                      id="state"
                      required
                      onChange={(e) => {
                        const selectedState = e.target.value;

                        getTown(selectedState);
                        field.onChange(e); // Make sure to propagate the change to the Controller
                      }}
                    >
                      {state?.map((item: any, index) => (
                        <option key={index} value={item.state_name}>
                          {item.state_name}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                </Grid>
              </div>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="h2">
            City *
          </Typography>
          <Controller
            name="city"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field,fieldState }) => {
             
             return <div>
                <Grid className="inputContainer">
                  <Grid className="iconWrapper">
                    <PersonPinCircleIcon />
                  </Grid>
                  <FormControl fullWidth>
                    <Select
                      required
                      className="inputWithIcon2Select"
                      id="city"
                      {...field}
                      {...control}
                      options={town.map((item: any) => ({
                        value: item.city_name,
                        label: item.city_name,
                      }))}
                      isSearchable
                      filterOption={({ label }, inputValue) =>
                      label.toLowerCase().startsWith(inputValue.toLowerCase())
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        textAlign: 'left',
                        padding:'3.5px',
                        fontSize: '13px', 
                        fontWeight:'500',
                        color:'black',
                        fontFamily:'sans-serif',
                
                        zIndex: 2,
                        position: 'relative', 
                      }),
                      menu: (provided) => ({
                        ...provided,
                        textAlign: 'left', // Align the menu to the left
                        zIndex: 3, // Set a higher zIndex for the dropdown menu
                        position: 'absolute',
                      }),
                      option: (provided) => ({
                        ...provided,
                        textAlign: 'left',
                        marginLeft:'30px', // Align each option to the left
                        padding: '5px', // Adjust padding as needed
                        fontSize: '16px', 
                        fontWeight:'500',
                        color:'black',
                        fontFamily:'sans-serif',
                        zIndex:1
                      }),
                    }}
                    />
                  </FormControl>
                </Grid>
                
                <FormHelperText error={!!fieldState.error}>
                {fieldState.error?.message}
              </FormHelperText>
              </div>
            }}
          />
        
        </Grid>
        <Grid item xs={12} sm={6} md={4} className="h2">
          <Typography variant="body1">Pincode *</Typography>
          <FormTextField
            icon={<PersonPinCircleIcon />}
            control={control}
            name="pincode"
            defaultValue=""
            id="pincode"
            //label=""
            rules={{
              required: "This field is required",
              validate: {
                validPincode: (value: any) =>
                  pincodeRegex.test(value) || "Invalid pincode",
              },
            }}
          />
        </Grid>
      </Grid>
      {/* <Grid item xs={12} sm={6} md={4} className="h2">
        <Typography variant="body1">Pincode</Typography>
        <FormField
          icon=""
          control={control}
          name="pincode"
          defaultValue=""
          label=""
          rules={{ required: "This field is required" }}
        />
      </Grid> */}
    </Grid>

    // </Container>
  );
};

export default ContactDetails;
