import React from 'react';
import { Controller } from 'react-hook-form';
import { Grid, Select,FormHelperText } from '@mui/material';
import './Registrationstyles.scss'; // Import the SCSS file

interface FieldProps {
  control: any;
  defaultValue: any;
  name: string;
  icon?: React.ReactNode; // Make icon optional by adding '?'
  type?: 'text' | 'select'; // Define the type of field ('text' or 'select')
  children?: React.ReactNode; // Children for select field
  selectWidth?: string;
  rules?:any; // Custom width for select field
  id:any;
}

const FormTextField = ({ control, name, defaultValue, icon, type = 'text', children, selectWidth ,rules,id}: FieldProps) => {
 

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState}) => (
        <div>
        <Grid className="inputContainer">
          {icon && <Grid className="iconWrapper">{icon}</Grid>}
          {type === 'text' ? (
            <input
              {...field}
              type="text"
              id={id}
              className="inputWithIcon1"
              required
            />
          ) : (
            <Select {...field} className="customSelect1"  variant="outlined" required>
              <option value="">Select an option</option>
              {children}
            </Select>
          )}
        </Grid>
        <FormHelperText error={!!fieldState.error}>
                {fieldState.error?.message}
              </FormHelperText>
        </div>
      )}
    />
  );
};

export default FormTextField;
