//details of previous conference
const conferencesPrevious = [
    {
      id: 1,
      name: 'ACT2023',
      venue: 'HOTEL ELITE INTERNATIONAL,THIRISSUR',
      topic: 'CRISIS MANAGEMENT IN ANAESTHESIA',
      start_date: '08-07-2023',
      end_date: '09-07-2023',
      no_of_days: 2,
    },
]
export default conferencesPrevious;