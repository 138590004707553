import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Typography, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import adminApiService from '../../../core/service/AdminApiService';


interface SearchFormProps {
  onSubmit: SubmitHandler<FormData>;
  handleDownloadClick: SubmitHandler<FormData>;
  // Define the onSubmit prop type
}
interface FormData {
  membername: string;
  memberemail: string;
  member_regno: any;
  conference_name: any;
  tshirt_size: any;
  type: any;
  interest:string;
  payment_status: any;
  workshop_details: any;
  amount : any;
  phone : any;
}
interface Item {
  id: any;
  name: any;
}
const SearchForm: React.FC<SearchFormProps> = ({ onSubmit, handleDownloadClick }) => {
  const [conference, setConferenceData] = useState<Item[]>([]);
  const [workshop, setWorkshopData] = useState<Item[]>([]);
  const { control, handleSubmit, getValues } = useForm<FormData>();
  const [selectedConference, setSelectedConference] = useState(2);
  const sizes = [
    { "name": "Extra Small", "abbreviation": "XS" },
    { "name": "Small", "abbreviation": "S" },
    { "name": "Medium", "abbreviation": "M" },
    { "name": "Large", "abbreviation": "L" },
    { "name": "Extra Large", "abbreviation": "XL" },
    { "name": "Double Extra Large", "abbreviation": "2XL" },
    { "name": "Triple Extra Large", "abbreviation": "3XL" }
    // Add more sizes as needed
  ];
  const roles = [
    { name: "Organiser", key: "organiser" },
    { name: "Faculty", key: "faculty" },
    { name: "Sponser", key: "sponser" },
    { name: "Delegate", key: "delegate" },
  ];
  const handleDownloadAndNavigate = () => {
    const formData = getValues();
    // Pass form data to handleDownloadClick function
    handleDownloadClick(formData);
    // Navigate to the memberlist page
  };
  const conferenceList = async () => {
    try {
      const apiData = await adminApiService.conferenceList();
      if (apiData) {

        setConferenceData(apiData.data);

      }
    } catch (error) {
      console.error(error, "error is getting here");
    }
  };
  const workshopList = async () => {
    try {
      const apiData = await adminApiService.workshopList();
      if (apiData.data) {

        setWorkshopData(apiData.data);

      }
    } catch (error) {
      console.error(error, "error is getting here");
    }
  };
  const handleConferenceChange = (selectedValue: any) => {
    // Perform actions based on the selected conference value

    // Update the state variable or trigger other logic as needed
    setSelectedConference(selectedValue);
   
    
  };

  useEffect(() => {


    // You can perform any asynchronous operations here, such as data fetching
    conferenceList();
    workshopList();

  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography className='searchformpad'> Search Member details here...</Typography><br />
        <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} md={2} justifyContent="center">
          <Controller
            name="membername"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Name"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Controller
            name="memberemail"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Email"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Controller
            name="phone"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Phone"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        
        <Grid item xs={12} md={2}>
          <FormControl fullWidth variant="outlined" className="form-control" >
            <InputLabel htmlFor="Payment Status">Payment Status</InputLabel>
            <Controller
              name="payment_status"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select {...field} label="Payment Status">
                  <MenuItem value="">Select</MenuItem>
                  <MenuItem value="PAID">Paid</MenuItem>
                  <MenuItem value="PENDING">Pending</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3} className='confnamefield'>
          <FormControl fullWidth variant="outlined" className="form-control">
            <InputLabel htmlFor="Conference Name">Conference Name</InputLabel>
            <Controller
              name="conference_name"
              control={control}
              defaultValue="Select"
              render={({ field }) => (
                <Select {...field} label="Conference Name" 
                onChange={(e) => {handleConferenceChange(e.target.value);field.onChange(e)}}
                value={selectedConference}>
                  <MenuItem value="">
                    Select
                  </MenuItem>
                  {conference?.map((item: any) => (
                    
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1} justifyContent="center">
        {conference?.map((conference: any) => (
          selectedConference === conference.id && (
            <>
              <Grid item xs={12} md={2} justifyContent="center">
                <FormControl fullWidth variant="outlined" className="form-control">
                  <InputLabel htmlFor="conferenceDropdown1">Interest</InputLabel>
                  <Controller
                    name="interest"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select {...field} label="interest">
                        <MenuItem value="">Select</MenuItem>
                        {roles.map((option) => (
                          <MenuItem key={option.name} value={option.key}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>

              </Grid>
              <Grid item xs={12} md={2}>
          <Controller
            name="member_regno"
            control={control}
            defaultValue=""
            render={({ field }) => (
             
              <TextField
                {...field}
                label="Registration No"
                variant="outlined"

                fullWidth
                value={selectedConference==2?`KATCON ${field.value}`:`ISACON ${field.value}`} // Display "ISACON" in the TextField
                onChange={(e) => {
                  //Remove ISACON /KATCON
                  const newValue = e.target.value.replace(/KATCON|ISACON/, '').trim();
                  field.onChange(newValue);
                }}
              />
            )}
          />
        </Grid>
              <Grid item xs={12} md={2}>
          <FormControl fullWidth variant="outlined" className="form-control" >
            <InputLabel htmlFor="Amount">Amount</InputLabel>
            <Controller
              name="amount"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select {...field} label="Amount">
                  <MenuItem value="">Select</MenuItem>
                  <MenuItem value="0">0</MenuItem>
                  <MenuItem value="600">600</MenuItem>
                  <MenuItem value="750">750</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
              <Grid item xs={12} md={2} justifyContent="center" className='Tshirt-size-Filter'>
                <FormControl fullWidth variant="outlined" className="form-control">
                  <InputLabel htmlFor="conferenceDropdown1">Tshirt Size</InputLabel>
                  <Controller
                    name="tshirt_size"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select {...field} label="Tshirt Size">
                        <MenuItem value="">Select</MenuItem>
                        {sizes.map((option) => (
                          <MenuItem key={option.name} value={option.abbreviation}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>

              </Grid>
              <Grid item xs={12} md={2} justifyContent="center">
                <FormControl fullWidth variant="outlined" className="form-control">
                  <InputLabel htmlFor="conferenceDropdown1">Type</InputLabel>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select {...field} label="Type">
                        <MenuItem value=""> Select</MenuItem>
                        <MenuItem value="conference">Conference</MenuItem>
                        <MenuItem value="conference+workshop ">Conference + Workshop</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={2}>
                <FormControl fullWidth variant="outlined" className="form-control">
                  <InputLabel htmlFor="WorkshopDropdown2">Workshop</InputLabel>
                  <Controller
                    name="workshop_details"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select {...field} label="Workshop Name">
                        <MenuItem value="">
                          Select
                        </MenuItem>
                        {workshop?.map((item: any) => (
                          <MenuItem key={item.id} value={item.name}>
                            {item.name}
                          </MenuItem>))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              
              </>)))}

        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Search
          </Button>&nbsp;
          <Button variant="contained" color="primary" onClick={handleDownloadAndNavigate}>
            Download
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SearchForm;
