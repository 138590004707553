// Import necessary libraries
import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Grid, Typography, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import "../Admin/Registration/addstyles.scss";
import "../Admin/Common/common.scss";
import "../Admin/Homescreen/dashboard.scss";
import SidebarToggle from "./Common/SidebarToggle";
import HeaderWithLogout from "./Common/Headerdesktop";
import Dashboard from "@mui/icons-material/Dashboard";
import adminApiService from "../../core/service/AdminApiService";
import EditIcon from '@mui/icons-material/Edit';
import { BsPlus } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";

// Define the component
const WorkshopList: React.FC = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/isaadmin/workshopadd');
  };
  const [workshop, setWorkshopData] = useState<any>([]);
  const [conference, setConferenceData] = useState<any>([]);
  const [editedRowId, setEditedRowId] = useState<any | null>(null);
  const [editedStatus, setEditedStatus] = useState<any>('');
 
  const { control, handleSubmit,reset ,setValue,getValues} = useForm();
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "topic",
      headerName: "Topic",
      width: 200,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "venue",
      headerName: "Venue",
      width: 200,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "date",
      headerName: "Date",
      width: 90,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "no_of_days",
      headerName: "Total Days",
      width: 100,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "max_no_of_attendee",
      headerName: "Max-Attendees",
      width: 120,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "mem_amount",
      headerName: "ISA Amount",
      width: 120,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "non_mem_amount",
      headerName: "Non-ISA Amount",
      width: 120,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "available_seat",
      headerName: "Available Seat",
      width: 120,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 170,
      headerClassName: "headergridcell_blue1",
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const rowData = params.row;
        const rowId = params.row.id;
        const initialStatus = params?.row?.status;

        if (params?.row?.status) {
          // Check if the cell is being edited
          if (editedRowId === rowId) {
            // Render an input field for editing payment status
            return (
              <Grid container xs={12} sm={12} className='header-with-logout-container'>
                <Grid item xs={8}>
                  <select
                    value={editedStatus}
                    className="payment-status-select menuitemtext"
                    onChange={(e) => setEditedStatus(e.target.value)}

                  >
                    <option value="ACTIVE" className="menuitemtext">ACTIVE</option>
                    <option value="CLOSED" className="menuitemtext">CLOSED</option>
                    <option value="COMPLETED" className="menuitemtext">COMPLETED</option>
                  </select>
                </Grid>
                <Grid item xs={3}>
                  <button className='button_stylesave' onClick={() => handleSave(rowData, editedStatus)}>Save</button>
                </Grid>
              </Grid>

            );
          }

          else {
            // Render the payment status text with the edit button
            return (
              <Grid container xs={12} sm={12} className='header-with-logout-container'>
                <Grid item xs={8} >
                  <Typography className={params?.row?.status === 'CLOSED' || params?.row?.status === 'COMPLETED' ? 'orangeTextGrid' : 'greenTextGrid'}> {params?.row?.status}</Typography></Grid>
                <Grid item xs={4}>
                  <button className='button_style' onClick={() => handleEdit(rowId, initialStatus)}><EditIcon className='viewiconstyle' /></button>
                </Grid></Grid>
            );
          }
        }

      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 130, headerClassName: 'headergridcell_blue1',
      cellClassName: "gridcolumn-cells",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params: any) => {
        const handleEdit = (rowData: any) => {
          navigate('/isaadmin/workshopedit', { state: rowData })
        }
    
        return (
          <Grid container spacing={1} alignContent={'flex-start'} style={{ padding: '8px',marginLeft:'8px' }}>
            <Grid item>
              <button className='button_style2' onClick={() => handleEdit(params.row)}><EditIcon className='viewiconstyle' /></button>
            </Grid>
          </Grid>
        )
      }
    },
  ];
  const workshopList = async () => {
    try {
     
      
      const apiData = await adminApiService.workshopList();
      if (apiData.data) {
        setWorkshopData(apiData.data);
        
      }

      // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error during data fetch:", error);
      
    }

  };
  const workshopSearchList = async (data:any) => {
    try {
     
      
      const apiData = await adminApiService.workshopSearchList(data);
      if (apiData.data) {
        setWorkshopData(apiData.data);
        
      }

      // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error during data fetch:", error);
      
    }

  };
  const handleEdit = (rowId: any, initialStatus: any) => {
    setEditedStatus(initialStatus);
    setEditedRowId(rowId);
  };
  const handleSave = async (rowsData: any, editedData: any) => {
    // Create a new Date object
    const moment = require('moment');

    const currentDateTime = moment().format('YYYY-MM-DDTHH:mm:ss');



    const confirmed = window.confirm("Do you want to change the status?");
    if (confirmed) {

      const resp = await adminApiService.EditWorkshopstatus(rowsData, editedData, currentDateTime);
      if (resp.status.status == "success") {
        // After updating, clear the edited state
        setEditedStatus('');
        setEditedRowId(null);
        workshopList();
      }

    }

  };
  useEffect(() => {
    workshopList();
    conferenceList();
  }, []);

  const conferenceList = async () => {
    try {
      const apiData = await adminApiService.conferenceList();
      if (apiData) {

        setConferenceData(apiData.data);
      }
    } catch (error) {
      console.error(error, "error is getting here");
    }
  };
  const getRowId = (row: any) => {
    if (row.id) {
      return row.id;
    }
    // Return a fallback value if the property is not available
    return '';
  };
  const getRowStyleParams = (params: any) =>
    params.indexRelativeToCurrentPage % 2 === 0 ? 'bg_grey_TableCell ' : 'bg_white_TableCell';


    const onSubmit = (data: any) => {
      workshopSearchList(data);
    }
  return (
    <Grid className="dashboard-container">
      <Grid container justifyContent="center">
        <Grid item xs={1} className="sidebar">
          <SidebarToggle />
        </Grid>
        <Grid item xs={11} className="content-container">
          <Grid className="conferenceSummaryContainer">
            <HeaderWithLogout
              title="Dashboard/Workshop Listing"
              icon={<Dashboard className="dashboard-icon" />}
            />
          </Grid><br />



          <Grid container className="search-grid-container">
  <Grid item xs={12} sm={4}>
    <form onSubmit={handleSubmit(onSubmit)}  className="search-form-container">
      <FormControl fullWidth variant="outlined" className="form-control" style={{ marginRight: '8px' }}>
        <InputLabel htmlFor="conference_name">Conference Name</InputLabel>
        <Controller
          name="conference_id"
          control={control}
          defaultValue=""
          rules={{ required: 'Select the conference' }}
          render={({ field, fieldState }) => (
            <>
              <Select {...field} label="Conference Name">
                {conference?.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!fieldState.error}>{fieldState.error?.message}</FormHelperText>
            </>
          )}
        />
      </FormControl>
      <Button type="submit" variant="contained" color="primary" className="formsubmitbtn">
        Search
      </Button>
    </form>
  </Grid>

  <Grid item xs={12} sm={5} md={3}>
    <Button className='button_add_volunteer' onClick={handleButtonClick}>
      <BsPlus /> Workshop Add
    </Button>
  </Grid>
</Grid>


 
          <Grid className="dashmainContent1 searchformpad">
            
              <DataGrid
                rows={workshop ? workshop : []}
                columns={columns}
                getRowId={getRowId}
                getRowClassName={getRowStyleParams}
                localeText={{ noRowsLabel: "No data available" }}
                disableColumnMenu={true}
                autoHeight
                 />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WorkshopList;
